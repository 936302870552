<template>
  <nav class="bg-white shadow-lg">
    <prodige-subnavitem
      v-for="menu in menus"
      :key="`submenu-${menu.id}`"
      :menu="menu"
    >
      {{ $t(menu.label) }}
    </prodige-subnavitem>
  </nav>
</template>

<script>
import ProdigeSubnavitem from './prodige-subnavitem.vue'

export default {
  name: 'ProdigeSubmenu',
  components: {
    ProdigeSubnavitem,
  },
  props: {
    menus: {
      type: Array,
    },
  },
}
</script>

<style scoped></style>

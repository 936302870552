<template>
  <section class="flex relative">
    <!-- input file -->
    <ab-icon
      :icon="icon"
      class="text-turquoise"
      @click.native="selectInput(`fileinput-${uid}`)"
    />
    <input
      v-show="false"
      :ref="`fileinput-${uid}`"
      type="file"
      :accept="acceptedFileTypes | join(', ')"
      :name="name"
      :multiple="multiple"
      :size="maxFileSize"
      @change="selectFile"
    />
  </section>
</template>

<script>
import { AbIcon } from '@ab/basic-components'

export default {
  name: 'ProdigeInputTypeFile',
  components: {
    AbIcon,
  },
  props: {
    name: {
      type: String,
      default: 'filepond',
    },
    icon: {
      type: String,
      default: 'add',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    acceptedFileTypes: Array,
    maxFileSize: [Number],
  },
  data() {
    return {
      file: null,
      fileType: null,
      imagePath: '',
      isDefaultFile: false,
      uid: `${Math.random().toString(36).substr(2, 9)}`,
    }
  },
  computed: {
    disabled() {
      // If file is not null, return true, false else
      return !!this.file
    },
  },
  watch: {
    files() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // If parent component sends file array, then fill imagePath
      if (!this.multiple) {
        this.file = this.files
        this.fileType = this.file ? this.file.mime_type : null
        this.imagePath =
          this.file &&
          typeof this.file !== 'undefined' &&
          Object.keys(this.file).length
            ? `${process.env.VUE_APP_ROOT_API}/upload${this.file.path}/${this.file.unique_name}`
            : ''
      } else if (this.files.length && typeof this.files[0] !== 'undefined') {
        this.file = this.files.map((file) => ({ ...file }))
        this.imagePath = `${process.env.VUE_APP_ROOT_API}/upload${this.files[0].path}/${this.files[0].unique_name}`
      } else {
        this.imagePath = ''
      }
    },
    selectFile(event) {
      const files = { ...event.target.files }
      // this.file = { ...files[0] };
      this.fileType = files[0].type
      if (files[0].size > this.maxFileSize) {
        this.$emit('addfile', {
          error: 'true',
          message: 'error.inputFile.maxFileSizeExceeded',
        })
        return
      }
      if (!this.acceptedFileTypes.includes(this.fileType)) {
        this.$emit('addfile', {
          error: 'true',
          message: 'error.inputFile.invalidFileType',
        })
        return
      }
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.imagePath = reader.result
        },
        false
      )

      reader.readAsDataURL(event.target.files[0])
      this.isDefaultFile = false
      this.$emit('addfile', event.target.files[0])
    },
    selectInput(name) {
      this.$refs[name].click()
    },
    // loadFile() {
    //   if (!this.$refs.pond.getFile()) return;
    //   this.file = this.$refs.pond.getFile().file;
    //   this.fileType = this.file.type;
    //   const reader = new FileReader();

    //   reader.addEventListener('load', () => {
    //     this.imagePath = reader.result;
    //   }, false);

    //   reader.readAsDataURL(this.file);
    //   this.isDefaultFile = false;
    //   this.$emit('addfile', this.$refs.pond.getFile().file);
    //   if (this.removeOnAdd) {
    //     this.$refs.pond.removeFile();
    //   }
    // }
  },
}
</script>

<style></style>

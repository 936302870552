export default {
  address: 'Adresse',
  addressAddition: 'Complément',
  addTheFamily: 'Ajouter la famille',
  adherent: 'Adhérent',
  adherentCode: 'Code adhérent',
  adherentPlaceholder: 'Choisir un adhérent',
  adherentGroup: "Groupe d'adhérent",
  adherentGroupPlaceholder: 'Choisir un groupe',
  adherentTags: 'Tags Adhérents / Fournisseurs',
  all: 'Tous',
  amount: 'Montant',
  amountInEuro: 'Montant en euros',
  apeCode: 'Code APE',
  articleName: 'Libellé',
  associatedAdherents: 'Groupes',
  attribution: 'Attribution',
  bank: 'Banque',
  bankAccountOwner: 'Titulaire du compte',
  bicCode: 'Code B.I.C',
  billingUnity: 'U.FAC',
  brand: 'Marque',
  brands: 'Marques',
  brandDetail: 'Marque détail',
  brandPlaceholder: 'Choisir une marque',
  calendar: 'Calendrier',
  caliber: 'Calibre',
  calibers: 'Calibres',
  caliberDetail: 'Calibre détail',
  caliberPlaceholder: 'Choisir un calibre',
  catalog: 'Catalogue',
  catalogPlaceholder: 'Choisir un catalogue',
  catalogSettings: 'Paramètres du catalogue',
  catalogs: 'Catalogues',
  catalogsPlaceholder: 'Choisir des catalogues',
  chooseValue: 'Choisir une valeur',
  circuitTypePlaceholder: 'Choisir un type de circuit',
  circuitType: 'Type de circuit',
  civility: 'Civilité',
  civilityPlaceholder: 'Choisir une civilité',
  clear: 'Vider',
  city: 'Ville',
  coef: 'coef.',
  coefficient: 'Coefficient',
  coefLabel: 'Coef:',
  commandUnity: 'U.CMD',
  coefCommandUnity: 'Coef.CMD',
  commission: 'Comission',
  commissionPlaceholder: 'Comission',
  company: 'Service',
  companyPlaceholder: 'Choisir un service',
  conditionsText: 'Information complémentaire',
  contactName: 'Nom du contact',
  contactResponsable: 'Contact Responsable',
  contactType: 'Type de contact',
  contactTypePlaceholder: 'Choisir un type de contact',
  contractLabel: 'Libellé du contrat',
  conventionType: 'Type convention',
  conventionTypePlaceholder: 'Choisir un type convention',
  corporateName: 'Raison sociale',
  country: 'Pays',
  cpvCode: 'Code CPV',
  creditNote: "Demande d'avoir",
  criteria: 'Critères',
  date: 'Date',
  dateInactive: 'Date de fin',
  datePriceUpdate: 'Date de mise à prix',
  dateToSendNotification: 'Date envoi des notifications',
  dateToSendEngagement: "Date envoi des actes d'engagements",
  delay: 'Délai',
  deliveryForm: 'Numéro de bon de livraison',
  deliveryFormPlaceholder: 'Choisir un bon de livraison',
  department: 'Département',
  departmentPlaceholder: 'Choisir un département',
  description: 'Description',
  displayBy: 'Affichage par',
  displayPlaceholder: 'Choisir un affichage',
  documentTitle: 'Titre du document',
  documentType: 'Type de document',
  documentTypePlaceholder: 'Choisir un type de document',
  entryPlaceholder: 'Choisir une saisie',
  email: 'Email',
  emails: 'Emails',
  endDate: 'Date de fin',
  establishmentType: 'Type établissement',
  establishmentTypePlaceholder: "Choisir un type d'établissement",
  family: 'Famille',
  familyPlaceholder: 'Choisir une famille',
  faxNumber: 'Fax',
  feeAmount: 'Montant redevance',
  firstName: 'Prénom',
  format: 'Format',
  frequency: 'Fréquence',
  frequencyPlaceholder: 'Choisir une fréquence',
  globalRevenue: 'CA Global',
  group: 'Groupe',
  groupPlaceholder: 'Choisir un groupe',
  iban: 'IBAN',
  id: 'ID',
  includedFamilies: 'Familles incluses',
  inseeCode: 'Code INSEE',
  internalTags: 'Tags internes',
  label: 'Libellé',
  labelPlaceholder: 'Choisir un libellé',
  labelTerm: 'Label',
  labelTermPlaceholder: 'Choisir un label',
  litigationDate: 'Date du litige',
  litigationTitle: 'Titre litige',
  litigationType: 'Objet du litige',
  mail: 'Mail',
  managementCoef: 'Coefficient de gestion',
  manufacturer: 'Industriel',
  manufacturerPlaceholder: 'Choisir un industriel',
  mealNumber: 'Nombre de repas',
  membershipAmount: "Montant de l'adhésion",
  message: 'Message',
  messageExample: 'Texte pré-enregistré',
  messageExamplePlaceholder: 'Choisir un texte',
  messageType: 'Type de message',
  messageTypePlaceholder: 'Choisir un type de message',
  messageAccueilRao: "Message d'accueil",
  minAmount: 'Montant Mini',
  maxAmount: 'Montant Max',
  name: 'Nom',
  negotiation: 'Négociation',
  negotiationCoef: 'Coefficient de négociation',
  negociationUnity: 'U.NEG',
  note: 'Note',
  noteWithScale: 'Note de 0 à 20',
  noteType: 'Type de note',
  noteTypePlaceholder: 'Choisir un type de note',
  numsubfamily: 'N° Sous Famille',
  officePhone: 'Téléphone bureau',
  olderTender: "Appel d'offre antérieur",
  olderTenderPlaceholder: "Choisir un appel d'offre antérieur",
  optionPlaceholder: 'Choisir une option',
  order: 'N° Famille',
  origin: 'Origine',
  origins: 'Origines',
  originDetail: 'Origine détail',
  originPlaceholder: 'Choisir une origine',
  periodicity: 'Périodicité',
  periodicityPlaceholder: 'Choisir une périodicité',
  phone: 'Tél',
  phoneNumber: 'Téléphone',
  platform: 'Plateforme',
  platformPlaceholder: 'Choisir une plateforme',
  preservation: 'Mode de conservation',
  preservationDetail: 'Mode de conservation détail',
  preservationPlaceholder: 'Choisir un mode de conservation',
  price: 'Prix HT',
  price_ht: 'Prix HT',
  priceDuration: 'Durée validité prix',
  priceDurationPlaceholder: 'Choisir une durée de validité prix',
  product: 'Produit',
  products: 'Produits',
  productName: 'Nom produit',
  productPlaceholder: 'Choisir un produit',
  profile: 'Profil',
  profileLabel: 'Libellé du profil',
  profilePlaceholder: 'Choisir un profil',
  profileType: 'Type de compte',
  profileTypePlaceholder: 'Choisir un type de compte',
  projectManager: 'Chargé de mission',
  projectManagerPlaceholder: 'Choisir un chargé de mission',
  prospect: 'Prospect',
  psiret: 'Siret',
  publicMarket: 'Marché public',
  publicMarketPlaceholder: 'Choisir un Marché public',
  procedure: 'Procédure',
  procedurePlaceholder: 'Choisissez une procédure',
  quotation: 'Cotation',
  quotationPlaceholder: 'Choisir une cotation',
  quotationPrice: 'Cours',
  quotationType: 'Type de cotation',
  quality: 'Qualité',
  qualityPlaceholder: 'Choisir une qualité',
  referencingDate: 'Date du référencement',
  refSupplier: 'Référence fournisseur',
  requiredAmount: 'Montant demandé',
  responsibleName: 'Nom du responsable',
  rfa: 'Taux RFA',
  rfaPlaceholder: 'Choisir taux RFA',
  rate: 'Taux',
  ratePlaceholder: 'Choisir un taux',
  recipients: 'Destinataires',
  resultDisplay: 'Résultat',
  resultDisplayPlaceholder: 'Choisir un résultat',
  ref: 'Ref',
  referencement: 'Référencement',
  region: 'Région',
  regionPlaceholder: 'Choisir une Région',
  resigner: 'Démissionnaire',
  select: 'Sélectionner',
  setting: 'Paramétrage',
  settingPlaceholder: 'Choisir un paramétrage',
  siret: 'SIRET',
  site: 'Site',
  sites: 'Sites',
  sitePlaceholder: 'Choisir un site',
  sortingOrder: 'Ordre de tri',
  standardCart: 'Panier type',
  standardCartPlaceholder: 'Choisir un panier type',
  starNumber: "Nombre d'étoiles",
  starNumberPlaceholder: "Choisir un nombre d'étoiles",
  startDate: 'Date de début',
  status: 'Statut',
  statusPlaceholder: 'Choisir un statut',
  statusPro: 'Statut proclub',
  statusProPlaceholder: 'Choisir un statut proclub',
  storageManagement: 'Gestion Stock',
  subfamily: 'Sous-famille',
  subfamilyPlaceholder: 'Choisir une sous-famille',
  subscriptionDate: "Date d'adhésion",
  supplierClientNumber: 'Numéro client fournisseur',
  supplierCompleteOffer: 'Fournisseur(s) non-attribués',
  supplierAttributed: 'Fournisseur(s) attribué(s)',
  supplierName: 'Nom fournisseur',
  supplier: 'Fournisseur',
  supplierInclude: 'Fournisseurs inclus',
  supplierPlaceholder: 'Choisir un fournisseur',
  supplierUninclude: 'Fournisseurs non-inclus',
  sent: 'Envoyé',
  tags: 'Tags',
  target: 'Prix cible',
  tender: "Appel d'offre",
  tenderObject: "Objet appel d'offre",
  tenderPlaceholder: "Choisir un appel d'offre",
  title: 'Titre',
  unassociated: 'Non rattaché',
  unity: 'Unité',
  totalAmount: 'Montant total',
  tvaIntraNumber: 'N° de TVA intracommunautaire',
  tvaNumber: 'N° de TVA',
  tvaRate: 'Taux de TVA',
  tvaPlaceholder: 'Choisir une TVA',
  type: 'Type',
  typePlaceholder: 'Choisir un type',
  typeTender: "Type Appel d'offre",
  typeTenderPlaceholder: "Choisir un type d'Appel d'offre",
  tenderSelection: "Selection appel d'offre",
  useVolumes: 'Utiliser des volumes de la période du ',
  validity: 'Validité',
  website: 'Site web',
  zipCode: 'Code postal',
  zipCodeAndCity: 'CP Ville',
  noSubmission: 'Ne soumissionne pas au marché public',
  submitForAllPlatforms:
    'Le sociétaire engage toutes les plateformes du réseau',
  submitForHimself: "Le signataire s'engage pour son propre compte",
  submitForCompany: 'Le signataire engage une seul société',
  validatorLv1: 'Validateur niveau 1',
  validatorLv2: 'Validateur niveau 2',
  validatorPlaceholder: 'Choisir un validateur',
  replacementValidator: 'Votre remplacant en cas de congés',
  negotiationPrice: 'Prix négo',
  euros: 'Euros',
  cartEmail: 'Email de panier',
  weeklyFrequency: 'Fréquence hebdomadaire',
  startDateReplacement: 'Date de début de congés',
  endDateReplacement: 'Date de fin de congés',
  sendingMode: "Mode d'envoi",
}

/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint-disable no-console */
import axios from 'axios'
import { authorizationEventBus } from '@apps/shared-components'
const timeout = null

/**
 * Create a new request object
 * If header changes, need to check header in fetch
 */
export function createRequest(url = false) {
  // console.log('PROCESS', process);s
  if (!process.env.VUE_APP_SESSION_LENGTH) {
    console.error('[Prodige] No session length in .env')
    return false
  }
  if (process.env.VUE_APP_ROOT_API) {
    let request = false
    if (url) {
      request = axios.create({
        baseURL: url,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } else {
      request = axios.create({
        baseURL: process.env.VUE_APP_ROOT_API,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }

    if (request) {
      registerInterceptor(request)

      request.interceptors.request.use((config) => {
        const { pathname } = window.location
        const path = pathname
          .split('/')
          .filter((path) => path !== process.env.VUE_APP_NAME)
          .join('/')
        config.headers['X-Referer'] = path
        config.headers['X-App'] = process.env.VUE_APP_NAME
        config.headers['X-vue-route-name'] = localStorage.getItem(
          'current_route_name'
        )
          ? localStorage.getItem('current_route_name')
          : null
        const token = localStorage.getItem('token')
        if (token) {
          config.headers.authorization = token
        } else {
          config.headers.authorization = null
        }
        return config
      })

      return request
    }

    return false
  }
  console.error('[Prodige] No ROOT_API !')
  return false
}

/**
 * @see https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
 */
function registerInterceptor(request) {
  request.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      const rights_valae = response.headers['x-valae-rights']
        ? response.headers['x-valae-rights'].split(', ')
        : []
      // stores.authentication.mutations.setPermissions(rights_valae);
      // console.log('stores', authorizationEventBus.$store);
      authorizationEventBus.$store.dispatch('setPermissions', rights_valae)
      return response
    },
    (error) => {
      // Return any error which is not due to authentication back to the calling service
      if (error.response.status !== 401 || error.config.url === '/auth/login') {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }

      // Logout user if token refresh didn't work or user is disabled
      // Error type 296 is for status_id = 2 (disabled account)
      // Error type 299 is for invalid refreshToken
      if (
        error.config.url === '/auth/refresh' ||
        error.response.data.type === 'err299' ||
        error.response.data.type === 'err296'
      ) {
        const event = new CustomEvent('refresh-token', {
          detail: { success: false, data: error.response.data },
        })
        document.dispatchEvent(event)

        return new Promise((resolve, reject) => {
          reject(error)
        })
      }

      const tokenDate = localStorage.getItem('tokenDate')
      if (
        tokenDate &&
        Date.now() - tokenDate < (process.env.VUE_APP_SESSION_LENGTH || 30000)
      )
        return false

      // Try request again with new token
      return getNewToken()
        .then((data) => {
          // If the user is impersonated by an admin
          // We need to use the refresh token of the admin user
          // Else, we use the user refresh token
          const { token, refresh_token, isImpersonated, impersonatedBy } = data

          const refreshTokenToUse =
            isImpersonated && impersonatedBy && impersonatedBy.refresh_token
              ? impersonatedBy.refresh_token
              : refresh_token

          const event = new CustomEvent('refresh-token', {
            detail: {
              success: true,
              data: { token, refresh_token: refreshTokenToUse },
            },
          })
          document.dispatchEvent(event)

          // New request with new token
          const { config } = error
          config.headers.authorization = token

          return request
            .request(config)
            .then((response) => response)
            .catch((error) => {
              throw error
            })
        })
        .catch((error) => {
          const event = new CustomEvent('refresh-token', {
            detail: { success: false, data: error.response.data },
          })
          document.dispatchEvent(event)
          Promise.reject(error)
        })
    }
  )
}

function getNewToken() {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')

  return axios
    .post(
      `${process.env.VUE_APP_ROOT_API}/auth/refresh`,
      {
        refreshToken,
      },
      {
        headers: {
          'Content-Type': 'Application/json',
          authorization: token,
        },
      }
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

/**
 * Handle HTTP request error, using custom error name
 * returned by the API
 * @param {Object} error Object error return by the API
 */
export function handleError(error) {
  console.error('[Prodige] HTTP call error !')
  console.error(error)

  if (error.response && error.response.data) {
    const errObject = error.response.data
    const { type, display } = errObject

    // Check if the error type is Unauthorized
    if (type === 'err001') {
      clearTimeout(timeout)
      setTimeout(() => {
        console.log('TIMEOUT')
        const event = new CustomEvent('access-error')
        document.dispatchEvent(event)
      }, 2000)
    } else if (type === 'err295') {
      clearTimeout(timeout)
      setTimeout(() => {
        const event = new CustomEvent('password-expired')
        document.dispatchEvent(event)
      }, 2000)
    }
    addMessage(type, display)
  }
  return false
}

export function registerAccessErrorEvent($router) {
  document.addEventListener('access-error', (event) => {
    console.error('[Prodige] Access unauthorize !')
    $router.go(-1)
  })
  document.addEventListener('password-expired', (event) => {
    console.error('[Prodige] Access unauthorize User have to reset password !')
    $router.push({ name: 'RequestPasswordReset' })
    // $router.go(-1);
  })
}

export function addMessage(type, display) {
  const event = new CustomEvent('addMessage', {
    detail: { key: type, params: { display, delayed: 100, error: true } },
  })
  document.dispatchEvent(event)
}

export function registerFlashBag(store) {
  document.addEventListener('addMessage', ({ detail }) => {
    store.dispatch('addMessage', detail)
  })
}

export function registerSessionRefresh(store, router) {
  document.addEventListener('refresh-token', ({ detail }) => {
    if (detail.success) {
      const { token, refresh_token } = detail.data
      return store.dispatch('refreshToken', { token, refresh_token })
    }
    // Log out ...
    store.dispatch('logout')
    return router.push({ name: 'Login' })
  })
}

<template>
  <section class="h-full">
    <router-view />
    <prodige-loader name="securitySpinner" class-name="" />
  </section>
</template>

<script>
export default {
  beforeRouteUpdate(to, from, next) {
    return next((vm) => vm.showSpinner('securitySpinner'))
  },
}
</script>

<template>
  <form autocomplete="off" @submit.prevent="handleFormInformation">
    <prodige-card>
      <!-- INFORMATIONS SECTION -->
      <div class="flex w-full">
        <span class="label w-2/12 block text-md text-dark-grey uppercase">{{
          $t('profile.label.siegeAddressFacturation')
        }}</span>
        <div class="w-10/12">
          <div class="flex flex-wrap justify-between">
            <div class="w-47 w-1/4 relative">
              <ab-input
                v-model="supplierData.office_address.address"
                :label="$t('profile.address')"
                :error="$v.supplierData.office_address.address.$error"
                name="adresse"
              />
              <p v-if="errors" class="error">
                <span
                  v-if="!$v.supplierData.office_address.address.required"
                  class="err-msg"
                  >{{ $t('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative ml-5">
              <ab-input
                v-model="supplierData.office_address.zip_code"
                :label="$t('profile.postalCode')"
                :error="$v.supplierData.office_address.zip_code.$error"
                name="firstname"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.office_address.zip_code.required"
                  class="err-msg"
                  >{{ $t('error.requiredField') }}</span
                >
                <span
                  v-if="!$v.supplierData.office_address.zip_code.numeric"
                  class="err-msg"
                  >{{ $tc('error.numeric') }}</span
                >
                <span
                  v-if="!$v.supplierData.office_address.zip_code.minLength"
                  class="err-msg"
                  >{{ $tc('error.minLength') }}</span
                >
                <span
                  v-if="!$v.supplierData.office_address.zip_code.maxLength"
                  class="err-msg"
                  >{{ $tc('error.maxLength') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative ml-5">
              <ab-input
                v-model="supplierData.office_address.city"
                :label="$t('profile.city')"
                :error="$v.supplierData.office_address.city.$error"
                name="email"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.office_address.city.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8">
              <ab-input
                v-model="supplierData.office_address.department"
                :label="$t('profile.department')"
                :error="$v.supplierData.office_address.department.$error"
                name="department"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.office_address.department.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8 ml-5">
              <ab-input
                v-model="supplierData.office_address.country"
                :label="$t('profile.country')"
                :error="$v.supplierData.office_address.country.$error"
                name="country"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.office_address.country.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8 ml-5">
              <ab-checkbox
                id="adresse-facturation"
                v-model="sameaddress"
                type="checkbox"
                name="adresse-facturation"
                class="flex-checkbox"
                :value="sameaddress"
                :disabled="deactivationState"
              >
                {{ $t('profile.sameAddress') }}
              </ab-checkbox>
            </div>
          </div>
          <div
            v-show="sameaddress === false"
            class="flex flex-wrap justify-between"
          >
            <div class="w-47 w-1/4 relative mt-8">
              <ab-input
                v-model="supplierData.billing_address.address"
                :label="$t('profile.address')"
                :error="$v.supplierData.billing_address.address.$error"
                name="address"
              />
              <p v-if="errors" class="error">
                <span
                  v-if="!$v.supplierData.billing_address.address.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8 ml-5">
              <ab-input
                v-model="supplierData.billing_address.zip_code"
                :label="$t('profile.postalCode')"
                :error="$v.supplierData.billing_address.zip_code.$error"
                name="firstname"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.billing_address.zip_code.numeric"
                  class="err-msg"
                  >{{ $tc('error.numeric') }}</span
                >
                <span
                  v-if="!$v.supplierData.billing_address.zip_code.minLength"
                  class="err-msg"
                  >{{ $tc('error.minLength') }}</span
                >
                <span
                  v-if="!$v.supplierData.billing_address.zip_code.maxLength"
                  class="err-msg"
                  >{{ $tc('error.maxLength') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8 ml-5">
              <ab-input
                v-model="supplierData.billing_address.city"
                :label="$t('profile.city')"
                :error="$v.supplierData.billing_address.city.$error"
                name="email"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.billing_address.city.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8">
              <ab-input
                v-model="supplierData.billing_address.department"
                :label="$t('profile.department')"
                :error="$v.supplierData.billing_address.department.$error"
                name="department"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.billing_address.department.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8 ml-5">
              <ab-input
                v-model="supplierData.billing_address.country"
                :label="$t('profile.country')"
                :error="$v.supplierData.billing_address.country.$error"
                name="country"
              />
              <p v-if="errors">
                <span
                  v-if="!$v.supplierData.billing_address.country.required"
                  class="err-msg"
                  >{{ $tc('error.requiredField') }}</span
                >
              </p>
            </div>
            <div class="w-47 w-1/4 relative mt-8 ml-5" />
          </div>
        </div>
      </div>
      <div class="flex w-full mt-12">
        <span class="label w-2/12 block text-md text-dark-grey uppercase">{{
          $t('profile.label.identification')
        }}</span>
        <div class="w-10/12 flex flex-wrap justify-between">
          <div class="w-47 w-1/4 relative">
            <ab-input
              v-model="supplierData.siret"
              v-mask="'##############'"
              :label="$t('profile.siret')"
              :error="$v.supplierData.siret.$error"
              name="siret"
            />
            <p v-if="errors">
              <span v-if="!$v.supplierData.siret.required" class="err-msg">{{
                $tc('error.requiredField')
              }}</span>
              <span v-if="!$v.supplierData.siret.numeric" class="err-msg">{{
                $tc('error.numeric')
              }}</span>
              <span v-if="!$v.supplierData.siret.minLength" class="err-msg">{{
                $tc('error.minLength')
              }}</span>
              <span v-if="!$v.supplierData.siret.maxLength" class="err-msg">{{
                $tc('error.maxLength', 14)
              }}</span>
            </p>
          </div>
          <div class="w-47 w-1/4 relative ml-5">
            <ab-input
              v-model="supplierData.ape_code"
              :label="$t('profile.apeCode')"
              :error="$v.supplierData.ape_code.$error"
              name="ape_code"
            />
            <p v-if="errors">
              <span v-if="!$v.supplierData.ape_code.required" class="err-msg">{{
                $tc('error.requiredField')
              }}</span>
              <span
                v-if="!$v.supplierData.ape_code.minLength"
                class="err-msg"
                >{{ $tc('error.minLength') }}</span
              >
              <span
                v-if="!$v.supplierData.ape_code.maxLength"
                class="err-msg"
                >{{ $tc('error.maxLength') }}</span
              >
            </p>
          </div>
          <div class="w-47 w-1/4 relative ml-5">
            <ab-input
              v-model="supplierData.tva_number"
              :label="$t('profile.tvaNumber')"
              :error="$v.supplierData.tva_number.$error"
              name="tva_number"
            />
            <p v-if="errors">
              <span
                v-if="!$v.supplierData.tva_number.required"
                class="err-msg"
                >{{ $tc('error.requiredField') }}</span
              >
              <span
                v-if="!$v.supplierData.tva_number.numeric"
                class="err-msg"
                >{{ $tc('error.numeric') }}</span
              >
              <span
                v-if="!$v.supplierData.tva_number.minLength"
                class="err-msg"
                >{{ $tc('error.minLength') }}</span
              >
              <span
                v-if="!$v.supplierData.tva_number.maxLength"
                class="err-msg"
                >{{ $tc('error.maxLength') }}</span
              >
            </p>
          </div>
          <div class="w-47 w-1/4 relative mt-8">
            <ab-input
              v-model="supplierData.website"
              :label="$t('profile.website')"
              name="website"
            />
            <p v-if="errors" />
          </div>
        </div>
      </div>
      <!-- FOOTER SECTION -->
      <template #footer>
        <div class="flex justify-end">
          <prodige-button
            type="button"
            class="btn primary mr-4"
            :disabled="pending"
            @click.native="handleCancel"
          >
            {{ $t('action.cancel') }}
          </prodige-button>
          <prodige-button class="btn secondary" :disabled="pending">
            {{ $t('action.save') }}
          </prodige-button>
        </div>
      </template>
    </prodige-card>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { AbInput, AbIconset, AbCheckbox } from '@ab/material-components'
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from 'vuelidate/lib/validators'
import ProdigeButton from '../../components/forms/buttons/prodige-button.vue'
import ProdigeCard from '../../components/cards/prodige-card.vue'

export default {
  name: 'ProfileInformation',
  components: {
    ProdigeCard,
    AbInput,
    ProdigeButton,
    AbCheckbox,
  },
  props: {
    supplier: {
      type: Object,
    },
  },
  data() {
    return {
      supplierData: {
        office_address: {
          address: '',
          city: '',
          country: '',
          department: '',
          zip_code: '',
        },
        billing_address: {
          address: '',
          city: '',
          country: '',
          department: '',
          zip_code: '',
        },
        siret: '',
        ape_code: '',
        tva_number: '',
        website: '',
      },
      sameaddress: false,
    }
  },
  validations: {
    supplierData: {
      office_address: {
        address: { required },
        city: { required },
        country: { required },
        department: { required },
        zip_code: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
      },
      billing_address: {
        address: { required },
        city: { required },
        country: { required },
        department: { required },
        zip_code: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
      },
      siret: {
        required,
        numeric,
        minLength: minLength(14),
        maxLength: maxLength(14),
      },
      ape_code: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      website: '',
      tva_number: {
        required,
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
    },
  },
  computed: {},
  watch: {
    supplier() {
      this.supplierData = { ...this.supplier }
      if (
        this.supplierData &&
        this.supplierData.supplier_billing_address_id ===
          this.supplierData.supplier_office_address_id
      ) {
        this.sameaddress = true
      }
    },
    sameaddress() {
      if (
        this.supplierData &&
        this.supplierData.supplier_billing_address_id ===
          this.supplierData.supplier_office_address_id &&
        !this.sameaddress
      ) {
        this.supplierData.billing_address = {}
        this.supplierData.supplier_billing_address_id = null
      } else if (
        this.supplierData &&
        this.supplierData.supplier_billing_address_id !==
          this.supplierData.supplier_office_address_id &&
        this.sameaddress
      ) {
        this.supplierData.supplier_billing_address_id =
          this.supplierData.supplier_office_address_id
        this.supplierData.billing_address = {
          ...this.supplierData.office_address,
        }
      }
    },
  },
  methods: {
    handleCancel() {
      this.$parent.$emit('cancel-form')
    },
    handleFormInformation() {
      this.$parent.$emit('submit-form', this.supplierData)
    },
  },
}
</script>

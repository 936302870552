import cloneDeep from 'lodash.clonedeep'

export default {
  data() {
    return {
      pending: false,
      isDirty: false,
      errors: false,
    }
  },
  methods: {
    startValidation(data = null) {
      if (data) {
        this.$v[data].$touch()
        this.errors = this.$v[data].$anyError
      } else {
        this.$v.$touch()
        this.errors = this.$v.$anyError
      }
    },
    startForm(name) {
      this.pending = true
      this.showSpinner(name)
    },
    errorForm(name) {
      this.pending = false
      this.isDirty = false
      this.hideSpinner(name)
    },
    endForm(name) {
      this.errors = false
      if (this.$v) {
        this.$v.$reset()
      }
      this.isDirty = false
      this.pending = false
      this.hideSpinner(name)
    },
    deepCopy(data) {
      return cloneDeep(data)
    },
    uid() {
      return `_${Math.random().toString(36).substr(2, 9)}`
    },
    customlabel(fn) {
      return (item) => fn(item)
    },
  },
}

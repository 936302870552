<template>
  <section class="prodige-tag">
    <ab-dropdown-input
      v-model="tagInput"
      class="mb-6"
      :label="placeholder"
      :label-property="optionLabel"
      :placeholder="placeholder"
      :options="options"
      :config="{ keys: [optionLabel] }"
      :disabled="tagList.length >= maxTags"
      @input="handleAddTag"
      @focus="handleFocus"
      @create:new="createTag"
    />
    <!-- Chips -->
    <div class="flex flex-wrap">
      <span v-for="(tag, idx) in tagList" :key="`tag-${idx}`" class="term">
        <span>{{ tag[optionLabel] }}</span>
        <ab-icon
          icon="close"
          iconset="tag"
          class="text-xxs"
          @click.native="removeTerm(idx)"
        />
        <!-- <ab-icon icon="close" ></ab-icon> -->
      </span>
    </div>
    <ab-iconset iconset="tag">
      <g id="close">
        <path
          d="M16.2 12l6.9-6.9c1.2-1.2 1.2-3 0-4.2-1.2-1.2-3-1.2-4.2 0L12 7.8 5.1.9C3.9-.3 2.1-.3.9.9c-1.2 1.2-1.2 3 0 4.2L7.8 12 .9 18.9c-1.2 1.2-1.2 3 0 4.2.6.6 1.2.9 2.1.9.9 0 1.5-.3 2.1-.9l6.9-6.9 6.9 6.9c.6.6 1.2.9 2.1.9.9 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3 0-4.2L16.2 12z"
        />
      </g>
    </ab-iconset>
  </section>
</template>

<script>
import { AbIcon, AbIconset, AbDropdownInput } from '@ab/material-components'

export default {
  name: 'ProdigeTag',
  components: {
    AbIcon,
    AbIconset,
    AbDropdownInput,
  },
  props: {
    optionLabel: String,
    options: Array,
    placeholder: String,
    startingTags: {
      type: Array,
      default: () => [],
    },
    maxTags: [String, Number],
  },
  data() {
    return {
      tagInput: '',
      tagList: [],
    }
  },
  watch: {
    /* If startingTags from parent component not loaded before prodige-tag component is created,
    need to watch it in order to fill tagList */
    startingTags() {
      this.tagList = this.startingTags
    },
  },
  mounted() {
    this.tagList = this.startingTags
  },
  methods: {
    handleAddTag(newTag) {
      // Push only new values
      if (
        this.tagList.find(
          (tag) => tag[this.optionLabel] === newTag[this.optionLabel]
        )
      ) {
        return
      }
      this.tagList.push(newTag)
      this.tagInput = ''
      this.$emit('handleTagArray', this.tagList)
    },
    handleFocus() {
      this.$emit('focus')
    },
    removeTerm(tagIndex) {
      this.tagList.splice(tagIndex, 1)
      this.tagInput = ''
      this.$emit('handleTagArray', this.tagList)
    },
    createTag(tag) {
      const objTag = { label: tag }
      this.options.push(objTag)
      this.tagInput = ''
    },
  },
}
</script>

<style scoped>
/* Chips */
.term {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  background-color: var(--light-grey);
  border: none;
  outline: none;
  padding: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--dark-grey);
  white-space: nowrap;
  align-items: center;
  border-radius: 5px;
  vertical-align: middle;
  text-decoration: none;
  min-width: 6rem;
}
.term span {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 0.5rem;
  font-size: 0.825rem;
}

.term .ab-icon {
  position: absolute;
  right: -0.25rem;
  display: flex;
  background-color: var(--line-grey-td-left-right);
  border-radius: 0.75rem;
  cursor: pointer;
  height: auto;
  padding: 0.125rem;
  width: 1.075rem;
  height: 1.075rem;
  user-select: none;
  flex-shrink: 0;
  margin-right: 0;
}
/* .term .ab-icon:hover{
  background-color: #666666;
} */
</style>
<style>
.term .ab-icon > i {
  color: var(--dark-default);
  height: 50%;
}
</style>

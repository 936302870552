import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedAdherentMixin: (state) =>
        state.adherent.selectedAdherent
          ? state.adherent.selectedAdherent.id
          : null,
    }),
  },
  watch: {
    async selectedAdherentMixin() {
      this.showSpinner()
      this.reloadAdherentData()
    },
  },
  methods: {
    reloadAdherentData() {},
  },
}

import { getFields, getExports } from '../api/exportSearchResult.js'

export default {
  namespaced: true,
  state: {
    fields: [],
  },
  mutations: {
    getFields(state, data) {
      state.fields = data
    },
  },
  actions: {
    async getFields({ commit }, params) {
      const data = await getFields(params)
      if (data !== false) {
        commit('getFields', data)
      }
      return data
    },
    async export({ commit }, params) {
      const data = await getExports(params)
      return data
    },
  },
}

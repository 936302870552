export default {
  text: {
    id: 'Id',
    type: 'Type',
    editor: 'Editeur',
    editorFirstName: "Prénom de l'éditeur",
    editorLastName: "Nom de l'éditeur",
    title: 'Titre',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    sendingMessage: "Message d'envoi",
    sendingMessageSubline:
      'Vous pouvez choisir le message à afficher aux adhérents',
    adherentSelection: "Sélection d'adhérents",
    adherentSelectionSubline:
      'Vous pouvez choisir le ou les adhérents qui pourront voir le message depuis leur outil adhérent',
  },
  modal: {
    importantMessageTitle: 'Message important',
    blockingMessageTitle: 'Application en maintenance',
  },
  messageType: {
    information: 'Information',
    important: 'Important',
    blocking: 'Bloquant',
  },
}

import { createRequest, handleError } from './api.js'

const request = createRequest()

export function search(params) {
  return request
    .put('/search/', {
      ...params,
    })
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function searchProducts(params) {
  return request
    .put('/search/product', params)
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function searchall() {
  return false
}

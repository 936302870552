var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"prodige-textarea",class:{ 'has-label': _vm.label, disabled: _vm.disabled, mandatory: _vm.required, error: _vm.error },attrs:{"disabled":_vm.disabled}},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}]},[_vm._v(_vm._s(_vm.label))]),_c('textarea',{ref:"input",staticClass:"prodige-textarea border-light-grey rounded-md border border-solid",class:{
      'has-label': _vm.label,
      disabled: _vm.disabled,
      mandatory: _vm.required,
      error: _vm.error,
    },style:(_vm.resizeStyle),attrs:{"disabled":_vm.disabled,"error":_vm.error,"name":_vm.name,"maxResizeColums":_vm.maxResizeColums,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){_vm.$emit('input', $event.target.value)
      _vm.resize($event)},"focus":function($event){_vm.$emit('focus')
      _vm.resize($event)}}})])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section
    class="PasswordUpdateSuccess h-full flex flex-col justify-center align-center items-center"
  >
    <img class="w-64" :src="appLogo.DarkLogo" alt="Logo" />
    <prodige-card class="mt-4 w-1/2 max-w-3xl">
      <template #header>
        <h1
          v-if="$route.meta.isCreate"
          class="py-4 font-poppins-medium font-medium"
        >
          {{ $t('title.passwordCreateSuccess') }}
        </h1>
        <h1 v-else class="py-4 font-poppins-medium font-medium">
          {{ $t('title.passwordUpdateSuccess') }}
        </h1>
      </template>
      <div>
        <span v-if="$route.meta.isCreate" class="label">{{
          $t('text.passwordCreateSuccess')
        }}</span>
        <span v-else class="label">{{ $t('text.passwordResetSuccess') }}</span>
      </div>
      <template #footer>
        <div class="flex justify-end text-right">
          <router-link
            :to="{ name: 'Login' }"
            class="btn secondary w-32 text-center"
          >
            {{ 'OK' }}
          </router-link>
        </div>
      </template>
    </prodige-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  required,
  minLength,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators'
import ProdigeCard from '../../components/cards/prodige-card.vue'
import AppLogo from '../../mixins/appLogo.js'

export default {
  name: 'PasswordUpdateSuccess',
  components: {
    ProdigeCard,
  },
  mixins: [AppLogo],
  props: [],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    this.hideSpinner()
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>

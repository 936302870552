import {
  updateSupplier,
  getSupplier,
  createSupplier,
  getSupplierStatus,
  getSupplierPlatforms,
  getSupplierContacts,
  updateSupplierContacts,
  getSuppliers,
  getCompanySuppliers,
  getCompanyCatalogSuppliers,
  getRevenueSuppliers,
  getSuppliersByAdherent,
  getSuppliersAndFamiliesByAdherent,
  getCommercialTermsByCatalog,
  getSuppliersByCatalog,
  getSuppliersByAdherentForLitigation,
} from '../api/supplier.js'

export default {
  state: {
    suppliers: [],
    suppliers_counter: 0,
    supplier: {},
    companies: [],
    supplier_status: [],
    catalogs: [],
    platforms: [],
    supplier_contacts: {},
    revenue_suppliers: [],
    suppliers_families: [],
    commercialTerms: {},
  },
  mutations: {
    getSuppliers(state, suppliers) {
      state.suppliers = suppliers.rows
      state.suppliers_counter = suppliers.count
    },
    getSupplier(state, supplier) {
      state.supplier = supplier
    },
    updateSupplier(state, supplier) {
      state.supplier = supplier
    },
    updateSupplierContacts(state, supplier) {
      state.supplier_contacts = supplier
    },
    createSupplier(state, supplier) {
      state.supplier = supplier
    },
    getSupplierStatus(state, supplier_status) {
      state.supplier_status = supplier_status
    },
    getSupplierPlatforms(state, platforms) {
      state.platforms = platforms
    },
    getSupplierContacts(state, supplier_contacts) {
      state.supplier_contacts = supplier_contacts
    },
    getCompanySuppliers(state, suppliers) {
      state.suppliers = suppliers
    },
    getCompanyCatalogSuppliers(state, suppliers) {
      state.suppliers = suppliers
    },
    getRevenueSuppliers(state, suppliers) {
      state.suppliers = suppliers
    },
    getSuppliersByAdherent(state, suppliers) {
      state.suppliers = suppliers
    },
    getSuppliersByAdherentForLitigation(state, suppliers) {
      state.suppliers = suppliers
    },
    getSuppliersAndFamiliesByAdherent(state, suppliers_families) {
      state.suppliers_families = suppliers_families
    },
    getCommercialTermsByCatalog(state, commercialTerms) {
      state.commercialTerms = commercialTerms
    },
    getSuppliersByCatalog(state, suppliers) {
      state.suppliers = suppliers
    },
  },
  actions: {
    async getSuppliers({ commit }, { offset, limit }) {
      const data = await getSuppliers(offset, limit)
      if (data) {
        commit('getSuppliers', data)
      }
      return data
    },
    async getSupplier({ commit }, id) {
      const data = await getSupplier(id)
      if (data) {
        commit('getSupplier', data)
      }
      return data
    },
    async updateSupplier({ commit }, supplier) {
      const data = await updateSupplier(supplier)
      if (data) {
        commit('updateSupplier', data)
      }
      return data
    },
    async updateSupplierContacts({ commit }, supplier) {
      const data = await updateSupplierContacts(supplier)
      if (data) {
        commit('updateSupplierContacts', data)
      }
      return data
    },
    async createSupplier({ commit }, supplier) {
      const data = await createSupplier(supplier)
      if (data) {
        commit('createSupplier', data)
      }
      return data
    },
    async getSupplierStatus({ commit }) {
      const data = await getSupplierStatus()
      if (data) {
        commit('getSupplierStatus', data)
      }
      return data
    },
    async getSupplierPlatforms({ commit }, id) {
      const data = await getSupplierPlatforms(id)
      if (data) {
        commit('getSupplierPlatforms', data)
      }
      return data
    },
    async getSupplierContacts({ commit }, id) {
      const data = await getSupplierContacts(id)
      if (data) {
        commit('getSupplierContacts', data)
      }
      return data
    },
    async getCompanySuppliers({ commit }, companyId) {
      const data = await getCompanySuppliers(companyId)
      if (data !== false) {
        commit('getCompanySuppliers', data)
      }
      return data
    },
    async getCompanyCatalogSuppliers({ commit }, catalogCompanyIds) {
      const data = await getCompanyCatalogSuppliers(catalogCompanyIds)
      if (data !== false) {
        commit('getCompanyCatalogSuppliers', data)
      }
      return data
    },
    async getRevenueSuppliers({ commit }, suppliersParams) {
      const data = await getRevenueSuppliers(suppliersParams)
      if (data !== false) {
        commit('getRevenueSuppliers', data)
      }
      return data
    },
    async getSuppliersByAdherent({ commit }, adherentId, is_group) {
      const data = await getSuppliersByAdherent(adherentId, is_group)
      if (data !== false) {
        commit('getSuppliersByAdherent', data)
      }
      return data
    },

    async getSuppliersByAdherentForLitigation({ commit }, adherentId) {
      const data = await getSuppliersByAdherentForLitigation(adherentId)
      if (data !== false) {
        commit('getSuppliersByAdherentForLitigation', data)
      }
      return data
    },
    async getSuppliersAndFamiliesByAdherent({ commit }, adherentId) {
      const data = await getSuppliersAndFamiliesByAdherent(adherentId)
      if (data !== false) {
        commit('getSuppliersAndFamiliesByAdherent', data)
      }
      return data
    },
    async getCommercialTermsByCatalog({ commit }, { supplierId, adherentId }) {
      const data = await getCommercialTermsByCatalog(supplierId, adherentId)
      if (data !== false) {
        commit('getCommercialTermsByCatalog', data)
      }
      return data
    },
    async getSuppliersByCatalog({ commit }, catalogId) {
      const data = await getSuppliersByCatalog(catalogId)
      if (data !== false) {
        commit('getSuppliersByCatalog', data)
      }
      return data
    },
  },
}

import Vue from 'vue'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

export const EventBus = new Vue({ name: 'EventBus' })

export class Search {
  constructor(model, $router, enableMode = true) {
    this.$router = null
    this.model = null
    this.fields = []
    this.filters = []
    this.schema = {}
    this.timeout = null
    this.syncURLTimeout = null
    this.limit = 10
    this.offset = null
    this.orders = []
    this.defaultOrders = []
    this.defaultAssociations = []
    this.toManyAssociations = []
    this.params = {}
    this.autoReload = true
    this.frontSpinner = false
    this.enableMode = enableMode
    this.fieldSelected = null

    this.searchOnFilterRemoved = true
    this.searchOnFilterAdded = true
    this.allowResendSearch = false

    if (!model) return this.isRequired('model')
    this.model = model
    if (!$router) return this.isRequired('$router')
    this.$router = $router
  }

  t = (string) => {
    const { i18n } = window
    return i18n.t(string)
  }

  /**
   * Throw an error from this class
   * @param {String} name The name of the required param
   */
  isRequired = (name) => {
    throw new Error(`Error param \`${name}\` is required!`)
  }

  isValideFieldType = (type) => {
    return Object.values(FieldType).includes(type)
  }

  /**
   * Return whether or not the field name already exist
   * @param {String} name The name of the field param
   */
  isFilterAlreadyExist = (schema, label, name) => {
    return (
      this.filters
        .map((filter) => `${filter.schema}_${filter.label}`)
        .includes(`${schema}_${label}`) &&
      this.filters
        .map((filter) => `${filter.schema}_${filter.name}`)
        .includes(`${schema}_${name}`)
    )
  }

  isValueAlreadyFiltered = (filters, name, value) => {
    return filters.some(
      (filter) => filter.name === name && filter.value === `${value}`
    )
  }

  /**
   * Return whether or not the field name already exist
   * @param {String} name The name of the field param
   */
  isNameAlreadyExist = (name) => {
    return this.fields.map((field) => field.name).includes(name)
  }

  /**
   * Format a date into human readable
   */
  format = (date) => {
    const localDate = typeof date === 'string' ? new Date(Number(date)) : date
    return format(localDate, 'd/M/yyyy', {
      locale: fr,
    })
  }

  /**
   * Emit a getData event via EventBus
   */
  emitGetData = () => {
    return EventBus.$emit('getData')
  }

  /**
   * Emit a applyFilter event via EventBuss
   */
  emitApplyFilter = () => {
    return EventBus.$emit('applyFilter')
  }

  /**
   * Generate a new random UI
   */
  uid = () => {
    return `_${Math.random().toString(36).substr(2, 9)}`
  }

  setDefaultAssociations(associations) {
    this.defaultAssociations = associations
  }

  addGrouping(
    model = this.isRequired('model'),
    fields = [],
    funcName = 'GROUP_CONCAT'
  ) {
    this.toManyAssociations.push({
      model,
      fields,
      funcName,
    })
  }

  addOffset(offset = 1) {
    this.offset = offset === 0 ? 1 : offset
  }

  addLimit(limit) {
    this.limit = limit
  }

  addParam(param, value) {
    this.params[param] = value
  }

  addFieldSelected(key) {
    this.fieldSelected = key
  }

  addOrders(ordersArray) {
    ordersArray.forEach((order) => {
      this.defaultOrders.push(order)
    })
  }

  /**
   * Define if a search must be done after a filter is removed
   * @param {Boolean} flag If the search must be done after a filter is removed
   */
  setSearchOnFilterRemoved(flag) {
    this.searchOnFilterRemoved = flag
    if (!flag) this.setAllowResendSearch(!flag)
  }

  setSearchOnFilterAdded(flag) {
    this.searchOnFilterAdded = flag
    if (!flag) this.setAllowResendSearch(!flag)
  }

  setAllowResendSearch(flag) {
    this.allowResendSearch = flag
  }

  /**
   * Add a new field Object the the list of fields
   * @param {Object} param0 An object containing the fild properties
   */
  addField({
    name = this.isRequired('name'),
    type = this.isRequired('type'),
    data,
    label = this.isRequired('label'),
    keyAsValue = 'id',
    keyAsLabel = 'label',
    searchKey = this.isRequired('searchKey'),
    required,
    hidden = false,
  }) {
    clearTimeout(this.timeout)
    const field = {}

    if (this.isNameAlreadyExist(name)) {
      throw new Error(
        `Error a field with name \`${name}\` already existe in field list`
      )
    }
    field.name = name

    if (!this.isValideFieldType(type)) {
      throw new Error(`Error type : \`${type}\` is invalid!`)
    }
    field.type = type

    if (type === FieldType.ObjectList && !Array.isArray(data)) {
      throw new Error(
        `Error \`ObjectList\` field data must be an Array, ${typeof data} given`
      )
    }
    field.listObjectData = data
    field.label = label

    if (type === FieldType.ObjectList) {
      field.labelOnListObject = keyAsLabel
      field.keyOnListObject = keyAsValue
    }
    field.key = searchKey

    // Add if field should be mandatory in the search
    // This mean, instead of doing a LEFT JOIN
    // The Search engire will perfome an INNER JOIN
    // In order to recolver all data that matches the filter
    field.required = required

    // If need to hide field, put hidden param to true
    field.hidden = hidden

    this.fields.push(field)

    this.timeout = setTimeout(() => {
      this.recoverFilter()
    }, 100)
  }

  /**
   * Update a field data
   * @param {String} fieldName The name of the field param
   * @param {Array} data updated data
   */
  setFieldData(fieldName, data) {
    // Find field index in 'fields' array
    const fieldIdx = this.fields.findIndex((field) => field.name === fieldName)

    // Update field data
    this.fields[fieldIdx].listObjectData = data
  }

  recoverFilter() {
    const filterBy = this.getFilters()
    this.fields.forEach((field) => {
      if (filterBy.length > 0) {
        filterBy
          .filter((filter) => field.name === filter.name)
          .forEach((filter) => {
            if (field.type === FieldType.ObjectList) {
              // Type case the value key as string to match the URL query param
              const value = field.listObjectData.find(
                (value) =>
                  String(value[field.keyOnListObject]) === String(filter.value)
              )
              this.addFilter(field, value, false)
            } else if (field.type === FieldType.FreeSearch) {
              this.addFilter(field, filter.value, false)
            } else if (field.type === FieldType.DateInterval) {
              const [startTime, endTime] = filter.value.split(',')
              const start = new Date(Number(startTime))
              const end = new Date(Number(endTime))
              this.addFilter(field, { start, end }, false)
            }
          })
      }
    })
    const orderBy = this.getOrders()
    // On rassemble les order de l'url et ceux qui sont définis avec la méthode addOrders
    const allOrderBy = [
      ...new Set(orderBy.concat(this.defaultOrders).map(JSON.stringify)),
    ].map(JSON.parse)
    this.orders = []
    allOrderBy.forEach((order) => {
      this.orders.push(order)
    })
    this.offset = this.getPage()
    this.emitApplyFilter()
    this.emitGetData()
  }

  /**
   * Add a new filter to the filter list to be applied to the search
   * @param {Object} selectedItem Object representing the selected field to search
   * @param {*} value The value to search with
   */
  addFilter(selectedItem, value, sync = true, replace = false, hidden = false) {
    const { DateInterval, FreeSearch, ObjectList, eq, gte, lte } = FieldType
    const { EQ, IN, NOT_IN, BETWEEN, LIKE, GTE, LTE } = ValueType
    const filter = {}
    filter.schema = selectedItem.key
    filter.fieldName = selectedItem.name
    filter.uid = this.uid()
    filter.required = selectedItem.required
    filter.hidden = hidden
    if (selectedItem.type === ObjectList) {
      filter.value_type = EQ
      filter.value = value[selectedItem.keyOnListObject]
      filter.name = `${selectedItem.label} : `
      filter.label = `<strong>${value[selectedItem.labelOnListObject]}</strong>`

      if (sync) this.filterBy(selectedItem.name, filter.value, null, replace)
    } else if (selectedItem.type === FreeSearch) {
      filter.value_type = LIKE
      filter.value = value
      filter.name = `${selectedItem.label} : `
      filter.label = `<strong>${value}</strong>`

      if (sync) this.filterBy(selectedItem.name, filter.value, null, replace)
    } else if (selectedItem.type === eq) {
      filter.value_type = EQ
      filter.value = value
      filter.name = `${selectedItem.label} : `
      filter.label = `<strong>${value}</strong>`

      if (sync) this.filterBy(selectedItem.name, filter.value, null, replace)
    } else if (selectedItem.type === gte) {
      filter.value_type = GTE
      filter.value = value
      filter.name = `${selectedItem.label} : `
      filter.label = `<strong>${value}</strong>`

      if (sync) this.filterBy(selectedItem.name, filter.value, null, replace)
    } else if (selectedItem.type === lte) {
      filter.value_type = LTE
      filter.value = value
      filter.name = `${selectedItem.label} : `
      filter.label = `<strong>${value}</strong>`

      if (sync) this.filterBy(selectedItem.name, filter.value, null, replace)
    } else if (selectedItem.type === DateInterval) {
      filter.value_type = BETWEEN
      filter.value = [value.start, value.end]
      filter.name = `${selectedItem.label} : `
      filter.label = `${this.t('text.from')} <strong>${
        value.start ? this.format(value.start) : ''
      }</strong> ${this.t('text.to')} <strong>${
        value.end ? this.format(value.end) : ''
      }</storng>`
      if (sync)
        this.filterBy(
          selectedItem.name,
          filter.value.map((date) => (date ? date.getTime() : '')),
          null,
          replace
        )
    }

    if (!this.isFilterAlreadyExist(filter.schema, filter.label, filter.name)) {
      this.filters.push(filter)
    }
    return filter
  }

  /**
   * Remove a filter from the list of applied filters and update URL
   * @param {Object} filter The filter object
   */
  removeFilter(filter, removeAll = false) {
    // Recover the filed type of the current filter
    const field = this.fields.find((field) => field.name === filter.fieldName)
    // Find the index of the filter in order to delete from URL and the list of filters
    const index = this.filters
      .filter((filter) => !filter.hidden)
      .findIndex((item) => item.uid === filter.uid)

    if (!field || index < 0) return

    // Update URL by removing deleted filter
    // If we do no want to fetch data on remove
    // User replace to update URL
    if (removeAll === false)
      this.filterBy(
        field,
        false,
        index,
        !this.searchOnFilterRemoved,
        this.searchOnFilterRemoved
      )

    // Remove the filter from the list
    if (this.filters.some((filter) => filter.hidden)) {
      const realIndex = this.filters.findIndex(
        (item) => item.uid === filter.uid
      )
      if (realIndex > -1) {
        this.filters.splice(realIndex, 1)
      }
    } else {
      this.filters.splice(index, 1)
    }

    // Trigger a rendering update
    this.emitApplyFilter()
  }

  /**
   * Remove all applied filters
   */
  removeFilters() {
    // Get all applied filters that is not hidden
    let filtersToDelete = this.filters.filter((filter) => !filter.hidden)

    // We use a while here to delete the first index of the filters, because the index is not updated if we use a forEach.
    while (filtersToDelete.length > 0) {
      this.removeFilter(filtersToDelete[0], true)

      // Update the filtersToDelete after deleting the first element.
      filtersToDelete = this.filters.filter((filter) => !filter.hidden)
    }

    // Get the new url (which the query params are supposed to be empty).
    const $route = this.$router.currentRoute
    const orderBy = this.getOrders()
    const route = {
      query: { ...$route.query, filterBy: [], orderBy, page: undefined },
    }
    // call the redirect url page function with replace at true
    this.redirectPage(route, true)
  }

  /**
   * Sync URL with applied filters and trigger an HTTP call for search
   * @param {Oject} field The field type of the filter
   * @param {String} value The value of the filter to apply
   * @param {Number} indexToFilter In case of delete, the index of the filter to delete
   */
  filterBy(field, value, indexToFilter, replace = false, fetchData = true) {
    // Create a temps filter to by applied
    const tempFilter = `${field}|${value}`
    // Get the Vue $route object, in order to recover any other query params
    const $route = this.$router.currentRoute
    // Recover a parsed array of filter from the URL query string
    const filterBy = this.getFilters()
    const orderBy = this.getOrders()

    if (this.isValueAlreadyFiltered(filterBy, field, value)) {
      return false
    }
    if (filterBy.length > 0) {
      const partFilters = filterBy
        .filter((filter, index) => {
          // In case of delete, the filter match the field name and the index to delete
          // We remove it from the list a filters to apply
          if (
            filter.name === field.name &&
            value === false &&
            index === indexToFilter
          )
            return false
          return true
        })
        .map((filter) => `${filter.name}|${filter.value}`)

      // If the value is false, that means that the filter is to delete
      if (value !== false) {
        partFilters.push(tempFilter)
      }

      // Sync URL with new filtered filter
      const route = {
        query: {
          ...$route.query,
          filterBy: partFilters,
          orderBy,
          page: undefined,
        },
      }
      this.redirectPage(route, replace, fetchData)
      return null
    }
    // If we have not yet any filter, force add an array of filters
    const route = {
      query: {
        ...$route.query,
        filterBy: [tempFilter],
        orderBy,
        page: undefined,
      },
    }
    this.redirectPage(route, replace, fetchData)
    return null
  }

  /**
   * Update url queries
   * @param {*} route
   * @param {*} replace
   */
  redirectPage(route, replace = false, fetchData = true) {
    if (replace) {
      this.$router.replace(route, () => (fetchData ? this.emitGetData() : null))
    } else {
      this.$router.push(route, () => (fetchData ? this.emitGetData() : null))
    }
  }

  /**
   * Recover filters from URL query String
   * And return a parsed object containing name and value
   */
  getFilters() {
    // Get the Vue $route object, in order to recover any query params
    const $route = this.$router.currentRoute
    // eslint-disable-next-line no-nested-ternary
    const filterBy =
      $route.query.filterBy && !Array.isArray($route.query.filterBy)
        ? [$route.query.filterBy]
        : $route.query.filterBy
        ? $route.query.filterBy
        : []
    return filterBy.map((filter) => {
      const splitedFilter = filter.split('|')
      if (splitedFilter.length === 2) {
        return {
          name: splitedFilter[0],
          value: splitedFilter[1],
        }
      }
      return false
    })
  }

  /**
   * Recover Orders from URL query String
   * And return a parsed object containing name and value
   */
  getOrders() {
    // Get the Vue $route object, in order to recover any query params
    const $route = this.$router.currentRoute
    // eslint-disable-next-line no-nested-ternary
    const orderBy =
      $route.query.orderBy && !Array.isArray($route.query.orderBy)
        ? [$route.query.orderBy]
        : $route.query.orderBy
        ? $route.query.orderBy
        : []
    return orderBy.map((filter) => {
      if (filter && typeof filter === 'object' && filter.name) return filter
      const splitedFilter = filter.split('|')
      if (splitedFilter.length === 2) {
        return {
          name: splitedFilter[0],
          value: splitedFilter[1],
        }
      }
      return false
    })
  }

  /**
   * Recover Page from URL query String
   * And return a parsed object containing name and value
   */
  getPage() {
    // Get the Vue $route object, in order to recover any query params
    const $route = this.$router.currentRoute
    // eslint-disable-next-line no-nested-ternary
    return $route.query.page ? $route.query.page : 1
  }
}

export const FieldType = {
  ObjectList: 'list_object',
  FreeSearch: 'free_search',
  DateInterval: 'date_interval',
  eq: 'eq',
  lte: 'lte',
  gte: 'gte',
}

export const ValueType = {
  EQ: 'eq',
  IN: 'in',
  NOT_IN: 'notIn',
  BETWEEN: 'between',
  LIKE: 'like',
  LTE: 'lte',
  GTE: 'gte',
}

export default {
  data() {
    return {
      searchInstance: null,
      searchResults: [],
      resultsColumns: [],
      count: 0,
    }
  },
  methods: {
    getData() {},
    getResults(results) {
      this.searchResults = results && results.rows ? results.rows : []
      this.resultsColumns = results && results.columns ? results.columns : []
      this.count = results && results.count ? results.count : 0
    },
  },
  watch: {},
}

<template>
  <section class="prodige-scrolling-card bg-white rounded-lg">
    <header><slot name="header" /></header>
    <main class="overflow-x-auto space-x-4 px-1 py-2 whitespace-no-wrap">
      <slot />
    </main>
    <footer><slot name="footer" /></footer>
  </section>
</template>

<script>
export default {
  name: 'ProdigeScrollingCard',
}
</script>

<style scoped>
.prodige-scrolling-card main > * {
  display: inline-block;
  scroll-snap-align: start;
}

.prodige-scrolling-card main {
  scroll-snap-type: x mandatory;
}
</style>

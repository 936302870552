/* eslint-disable no-console */
export default {
  methods: {
    /* Table sorting */
    getOrders() {
      // eslint-disable-next-line no-nested-ternary
      const orderBy =
        this.$route.query.orderBy && !Array.isArray(this.$route.query.orderBy)
          ? [this.$route.query.orderBy]
          : this.$route.query.orderBy
          ? this.$route.query.orderBy
          : []
      return orderBy.map((filter) => {
        const splitedFilter = filter.split('|')
        if (splitedFilter.length === 2) {
          return {
            name: splitedFilter[0],
            order: splitedFilter[1],
          }
        }
        return false
      })
    },
    async sortby() {
      if (!this.sortable || !this.name) return
      // Loop through order direction, and change the order direction
      const order = ['asc', 'desc', '']
      const sortIdx = order.indexOf(this.sorted)
      this.sorted = sortIdx === order.length - 1 ? order[0] : order[sortIdx + 1]

      const tempFilter = `${this.name}|${this.sorted}`

      // Get the existing URL query params
      const orderBy = this.getOrders()
      if (orderBy.length > 0) {
        // If not already id URL
        if (
          !orderBy.find(
            (order) => order.name === this.name && this.sorted !== ''
          )
        ) {
          // Check if the name is already in orderBy
          // If so, we filter the array to remove the current filter
          const partFilters = orderBy
            .filter((filter) => {
              if (filter.name === this.name && this.sorted === '') return false
              return true
            })
            .map((filter) => `${filter.name}|${filter.order}`)

          const orders = partFilters
          if (this.sorted !== '') {
            orders.push(tempFilter)
            this.$router
              .push(
                { query: { ...this.$route.query, orderBy: orders } },
                this.getData
              )
              .catch(console.info)
            this.$parent.$emit('order')
            return
          }
          this.$router
            .push(
              { query: { ...this.$route.query, orderBy: orders } },
              this.getData
            )
            .catch(console.info)
          this.$parent.$emit('order')
          return
        }

        // Loop through existing filter to remove those which have been reset
        const filteredFilter = orderBy
          .filter((filter) => {
            if (filter.name === this.name && this.sorted === '') return false
            return true
          })
          .map((filter) => {
            const _filter = filter
            if (filter.name === this.name) _filter.order = this.sorted
            return _filter
          })
          .map((filter) => `${filter.name}|${filter.order}`)

        this.$router
          .push(
            { query: { ...this.$route.query, orderBy: filteredFilter } },
            this.getData
          )
          .catch(console.info)
        this.$parent.$emit('order')
        return
      }
      this.$router
        .push(
          { query: { ...this.$route.query, orderBy: [tempFilter] } },
          this.getData
        )
        .catch(console.info)
      this.$parent.$emit('order')
    },
  },
}

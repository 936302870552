import { createRequest, handleError } from './api.js'

const request = createRequest()

export function getStatus(id) {
  return request
    .get(`/status/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getAllStatus() {
  return request
    .get('/status', {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export default (url) => {
  // URL format www.test.com
  if (
    url &&
    /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/gm.test(url) === false
  ) {
    return false
  }
  return true
}

/* eslint-disable no-restricted-syntax */
import { createRequest, handleError } from './api.js'

const request = createRequest()

// eslint-disable-next-line import/prefer-default-export
export function getFields(params) {
  return request
    .put('/exports/fields/', params, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

// eslint-disable-next-line import/prefer-default-export
export function getExports(params) {
  return request
    .put('/exports/exportSearchResult', params, {
      header: {
        // 'Content-Type': 'multipart/form-data'
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

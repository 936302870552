<template>
  <section
    class="RequestPasswordReset h-full flex flex-col justify-center items-center"
  >
    <img class="w-64" :src="appLogo.DarkLogo" alt="Logo" />
    <form
      class="mt-4 w-1/2 max-w-3xl"
      @submit.prevent="handleRequestPasswordReset"
    >
      <prodige-card>
        <template #header>
          <h1 class="py-4 font-poppins-medium font-medium">
            {{ $t('title.requestPasswordReset') }}
          </h1>
        </template>
        <div>
          <div class="flex-column input">
            <div class="w-full relative mt-4">
              <ab-input
                v-model.trim="email"
                required
                :error="$v.email.$error"
                :label="$t('text.email')"
              />
              <span v-if="$v.email.$error" class="err-msg">{{
                $t('error.invalidEmail')
              }}</span>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="text-right flex justify-end items-center">
            <router-link :to="{ name: 'Login' }" class="primary btn mr-4">
              {{ $t('action.cancel') }}
            </router-link>
            <prodige-button
              type="submit"
              class="btn secondary"
              :disabled="pending"
            >
              {{ $t('action.searchAccount') }}
            </prodige-button>
          </div>
        </template>
      </prodige-card>
    </form>
  </section>
</template>

<script>
import { AbInput } from '@ab/material-components'
import { required, minLength, email } from 'vuelidate/lib/validators'
import ProdigeButton from '../../components/forms/buttons/prodige-button.vue'
import ProdigeCard from '../../components/cards/prodige-card.vue'
import AppLogo from '../../mixins/appLogo.js'

export default {
  name: 'RequestPasswordReset',
  components: {
    ProdigeCard,
    AbInput,
    ProdigeButton,
  },
  mixins: [AppLogo],
  data() {
    return {
      email: '',
    }
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  mounted() {
    this.hideSpinner()
  },
  methods: {
    async handleRequestPasswordReset() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.pending = true
        const data = await this.$store.dispatch('resetPassword', {
          email: this.email,
        })
        this.pending = false
        if (data !== false) {
          this.$router.push({ name: 'Login' })
          this.addMessage({
            key: 'text.emailSent',
            params: { display: 'text.emailSent' },
          })
        }
      }
    },
  },
}
</script>

<style scoped></style>

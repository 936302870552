<template>
  <section>
    <prodige-layout />
  </section>
</template>

<script>
import ProdigeLayout from '../components/layouts/prodige-layout.vue'

export default {
  name: 'AppOutlet',
  components: {
    ProdigeLayout,
  },
}
</script>

<style scoped></style>

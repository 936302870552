export default {
  data() {
    return {
      constants: {
        profilType: {
          VALAE: 'valae',
          SUPPLIER: 'supplier',
          ADHERENT: 'adherent',
        },
        search: {
          SERVICE_KEY: 'company',
        },
        adherent: {
          PROSPECT_LABEL: 'label.prospect',
          RESIGNER_LABEL: 'label.resigner',
        },
        supplier: {
          ENABLED_LABEL: 'ACtif',
          DISABLED_LABEL: 'Inactif',
        },
        contactTypes: {
          ADMINISTRATIVE: 'administrative',
          REVENUE: 'revenue',
          CATALOG: 'catalog',
          SITE: 'site',
          TENDER: 'tender',
          LITIGATION: 'litigation',
          PRODUCT_UPDATE: 'product_update',
          STATISTICS: 'statistics',
        },
        apps: {
          BACK_OFFICE: 'back-office',
          TENDERS: 'tenders',
          PUBLIC_NOTICES: 'public-notices',
          FRONT_OFFICE: 'front-office',
        },
        appNames: {
          BACK_OFFICE: 'backOffice',
          FRONT_OFFICE: 'frontOffice',
          TENDERS: 'tenders',
        },
        companyTypes: {
          PRIVATE: 'private',
          PUBLIC: 'public',
        },
        orderStatus: {
          IN_PROGRESS: '100',
          WAITING_FOR_VALIDATION: '150',
          REFUSED_BY_LEVEL1: '200',
          REFUSED_BY_LEVEL2: '250',
          SENT_TO_SUPPLIER: '300',
          SENT_BY_ME: '350',
          READ_BY_SUPPLIER: '400',
          RECEIVED: '450',
        },
        exportsTypes: {
          ORDERS_UNDER_DELIVERY: 1,
          RECEIVED_ORDERS: 2,
          RECEIVED_ORDERS_DETAILS: 3,
          BUYING_LIST: 4,
          PRODUCT_NOT_IN_CATALOG: 5,
          CART_TYPE: 6,
          ORDER_IN_PREPARATION: 7,
          REVENUE_SYNTHETIC_FILE: 8,
          REVENUE: 9,
          REVENUE_REQUEST: 10,
          REVENUE_SYNTHESIS: 11,
          STOCK_OUTGOING: 12,
          STOCK_BY_FAMILY: 13,
          PLATFORM_LIST_BY_GROUP: 14,
          EXPLOITATION_LIST: 15,
          REVENU_BY_GROUP: 17,
          REFERENCEMENT_BY_GROUP: 18,
          CA_VERSUS: 19,
          CATALOG_RFA_BY_SUPPLIER_BY_GROUP: 21,
          CA_BY_ARTICLE_BY_SUPPLIER: 22,
          OPERATION_LIST_BY_PRODUCT: 23,
        },
        sendingMode: {
          SENT_BY_ME: 1,
          EVALAE: 2,
        },
        messageType: {
          INFORMATION: 1,
          IMPORTANT: 2,
          BLOCKING: 3,
        },
        stocksTransactionFlux: {
          ENTRY: '100',
          EXIT: '150',
          DELIVERY: '200',
        },
      },
    }
  },
}

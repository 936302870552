export default {
  methods: {
    registerSpinner(name) {
      this.$store.commit('registerSpinner', name)
    },
    showSpinner(param) {
      const options = {
        timeout: true,
      }
      // If no param is supplied or if one is given
      // Set the options.name props
      if (!param || typeof param === 'string') {
        options.name = param || 'default'
      } else {
        options.name = param.name
        options.timeout = param.timeout
      }
      this.$store.commit('showSpinner', options)
    },
    hideSpinner(name, timeout = 400) {
      // FIXME: value to be refined
      setTimeout(() => {
        this.$store.commit('hideSpinner', name)
      }, timeout)
    },
    preventSpinner(name) {
      this.$store.commit('preventSpinner', name)
    },
    unpreventSpinner(name) {
      this.$store.commit('unpreventSpinner', name)
    },
  },
}

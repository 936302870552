/* eslint-disable no-restricted-syntax */

import { createRequest, handleError } from './api.js'

const request = createRequest()
const request2 = createRequest(process.env.VUE_APP_ROOT_API2)

export function uploadFile({ files, path }) {
  const formData = new FormData()
  formData.append('path', path)
  if (Array.isArray(files)) {
    for (let i = 0; i < files.length; i += 1) {
      formData.append(`file-${i}`, files[i], files[i].name)
    }
  } else {
    formData.append('file', files, files.name)
  }

  return request
    .post('/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function uploadFile2({ files, path }) {
  const formData = new FormData()
  formData.append('path', path)
  if (Array.isArray(files)) {
    for (let i = 0; i < files.length; i += 1) {
      formData.append(`file-${i}`, files[i], files[i].name)
    }
  } else {
    formData.append('file', files, files.name)
  }

  return request2
    .post('/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function downloadFile(id) {
  if (!process.env.VUE_APP_ROOT_API) {
    return false
  }
  const { pathname } = window.location
  const path = pathname
    .split('/')
    .filter((path) => path !== process.env.VUE_APP_NAME)
    .join('/')
  return fetch(`${process.env.VUE_APP_ROOT_API}/file/download/${id}`, {
    method: 'GET',
    headers: {
      'X-Referrer': path,
      authorization: localStorage.getItem('token'),
    },
  })
    .then((response) => {
      if (response.status !== 200) return false
      return response.blob()
    })
    .catch(handleError)
}

export function getFile(id) {
  return request
    .get(`/file/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

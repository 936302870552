<template>
  <nav class="grid" :class="`grid-cols-${allowedAppsCount}`">
    <a
      v-if="backOfficeAccess.access"
      class="application-link"
      :class="isActive('back-office')"
      :href="
        !isDev ? backOfficeAccess.url : 'http://localhost:8081/back-office/'
      "
    >
      <span class="item">
        {{ $t('apps.text.backOffice') }}
      </span>
    </a>
    <a
      v-if="frontOfficeAccess.access"
      class="application-link"
      :class="isActive('front-office')"
      :href="
        !isDev ? frontOfficeAccess.url : 'http://localhost:8084/front-office/'
      "
    >
      <span class="item">
        {{ $t('apps.text.frontOffice') }}
      </span>
    </a>
    <a
      v-if="tendersAccess.access"
      class="application-link"
      :class="isActive('tenders')"
      :href="!isDev ? tendersAccess.url : 'http://localhost:8083/tenders/'"
    >
      <span class="item">
        {{ $t('apps.text.tenders') }}
      </span>
    </a>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { checkAppAccess } from '../../assets/js/helpers.js'

export default {
  name: 'ProdigeAppnav',
  data() {
    return {
      BACK_OFFICE: 'backOffice',
      FRONT_OFFICE: 'frontOffice',
      TENDERS: 'tenders',
      accesses: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.authentication.user,
    }),
    backOfficeAccess() {
      return (
        this.accesses.find(
          (app) => app.name === this.BACK_OFFICE && app.access
        ) || {}
      )
    },
    frontOfficeAccess() {
      return (
        this.accesses.find(
          (app) => app.name === this.FRONT_OFFICE && app.access
        ) || {}
      )
    },
    tendersAccess() {
      return (
        this.accesses.find((app) => app.name === this.TENDERS && app.access) ||
        {}
      )
    },
    allowedAppsCount() {
      const allowedList = [
        this.backOfficeAccess.access,
        this.frontOfficeAccess.access,
        this.tendersAccess.access,
      ]
      return allowedList.filter((allowed) => allowed).length
    },
    isDev: () => process.env.NODE_ENV !== 'production',
  },
  watch: {
    user() {
      // We check the user access rights for all available apps
      this.accesses = checkAppAccess(
        this.$store.state.app.apps,
        this.user.Profile
      )
    },
  },
  async mounted() {
    // Get all available apps
    await this.$store.dispatch('getApps')
    // We check the user access rights for all available apps
    this.accesses = checkAppAccess(
      this.$store.state.app.apps,
      this.user.Profile
    )
  },
  methods: {
    isActive(name) {
      return process.env.VUE_APP_NAME === name ? 'active' : ''
    },
  },
}
</script>

<style scoped>
.application-link {
  @apply relative font-montserrat-medium text-lg text-center flex justify-center items-center py-3 whitespace-no-wrap truncate text-purple transition-colors ease-in duration-75;
}
.application-link::before {
  content: '';
  position: absolute;
  top: 0;
  width: 40%;
  height: 2px;
  @apply bg-orange transition-transform ease-in-out duration-150;
  transform: scale(0);
}
.application-link.active {
  @apply opacity-100 text-orange;
}
.application-link.active::before {
  transform: scale(1);
}
.application-link .item {
  @apply block leading-9 w-full h-full px-6 border-r-2 border-purple;
}
.application-link:last-child .item {
  @apply border-r-0;
}

/* Hover state */
.application-link:hover {
  @apply opacity-75 text-orange;
}
.application-link:hover::before {
  transform: scale(1);
}

.grid-cols-1 {
  @apply grid-cols-1;
}
.grid-cols-2 {
  @apply grid-cols-2;
}
.grid-cols-3 {
  @apply grid-cols-3;
}
</style>

<template>
  <!-- Platform SECTION -->
  <section class="supplier-platform">
    <prodige-table>
      <template #colgroup>
        <col class="w-1/12">
        <col class="w-3/12">
        <col class="w-3/12">
        <col class="w-2/12">
        <col class="w-2/12">
        <col class="w-1/12">
      </template>
      <template #thead>
        <prodige-th>{{ $t('profile.text.company') }}</prodige-th>
        <prodige-th>{{ $t('profile.text.platform') }}</prodige-th>
        <prodige-th>{{ $t('profile.text.zipCodeAndCity') }}</prodige-th>
        <prodige-th>{{ $t('profile.text.responsable') }}</prodige-th>
        <prodige-th>{{ $t('profile.text.contactTel') }}</prodige-th>
        <prodige-th />
      </template>
      <tr
        v-for="platform in platforms_list"
        :key="`platform-${platform.id}`"
        class="odd:bg-light-grey"
      >
        <prodige-td @click.native="openModal(platform.id)">
          <prodige-company
            v-if="platform.Company !== null"
            :company-id="platform.Company.id"
          />
        </prodige-td>
        <prodige-td @click.native="openModal(platform.id)">
          {{ platform.label }}
        </prodige-td>
        <prodige-td @click.native="openModal(platform.id)">
          <span>{{ platform.Address.zip_code }}</span><br>
          <span>{{ platform.Address.city }}</span>
        </prodige-td>
        <prodige-td @click.native="openModal(platform.id)">
          <span>{{ platform.responsible }}</span><br>
          <span>{{ platform.email }}</span>
        </prodige-td>
        <prodige-td @click.native="openModal(platform.id)">
          <span>{{ $t('profile.text.tel') }} {{ platform.phone }}</span><br>
          <span>{{ $t('profile.text.fax') }} {{ platform.fax_number }}</span>
        </prodige-td>
        <prodige-td
          class="text-right"
          @click.native="openModal(platform.id)"
        >
          <ab-icon
            icon="edit"
            iconset="profile"
          />
        </prodige-td>
      </tr>
    </prodige-table>
    <!-- ACTIONS BUTTON -->
    <div class="w-full flex justify-end mt-8">
      <prodige-button
        class="btn primary"
        @click.native="openModal(null)"
      >
        {{ $t('profile.text.newPlatform') }}
      </prodige-button>
    </div>
    <!-- MODAL -->
    <modal
      name="platform"
      width="540"
      height="200"
      :adaptive="true"
    >
      <profile-platform-form
        :form="modal_platform"
        @submitFormModal="submitPlatform"
        @cancelFormModal="cancelPlatform"
      />
    </modal>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from 'vuelidate/lib/validators'
import { AbIcon } from '@ab/basic-components'
import ProdigeButton from '../../components/forms/buttons/prodige-button.vue'
import ProdigeCard from '../../components/cards/prodige-card.vue'
import ProdigeCompany from '../../components/icons/prodige-company.vue'
import ProdigeTable from '../../components/layouts/table/prodige-table.vue'
import ProdigeTd from '../../components/layouts/table/prodige-th.vue'
import ProdigeTh from '../../components/layouts/table/prodige-td.vue'
import ProfilePlatformForm from './ProfilePlatformForm.vue'

export default {
  name: 'ProfilePlatform',
  components: {
    ProdigeButton,
    ProdigeCompany,
    ProdigeTable,
    ProdigeTd,
    ProdigeTh,
    ProfilePlatformForm,
    AbIcon,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      userData: {},
      platforms_list: [],
      modal_platform: {},
    }
  },
  validations: {
    userData: {
      local_platform_list: {},
    },
    modal_platform: {
      Company: { required },
      name: { required },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.authentication.user,
      platforms: (state) => state.supplier.platforms,
      supplier: (state) => state.supplier.supplier,
    }),
  },
  watch: {
    async user() {
      this.userData = { ...this.user }
      const data = await this.$store.dispatch('getSearchResult', {
        model: 'Platform',
        fields: [
          {
            schema: 'supplier_id',
            value_type: 'eq',
            value: this.userData.Supplier.id,
          },
        ],
        offset: 0,
        limit: 50,
        associations: ['Company.Company_type', 'Address'],
        orders: [],
      })
      this.platforms_list = await [...data.rows]
    },
    platforms(newPlatforms, oldPlatforms) {
      this.platforms_list = this.platforms
    },
  },
  methods: {
    async init() {
      await this.$store.dispatch(
        'getSupplierPlatforms',
        this.userData.Supplier.id
      )
    },
    handleCancel() {
      this.$parent.$emit('cancel-form')
    },
    handleFormUpdateMe() {
      this.$parent.$emit('submit-form', this.userData)
    },
    async openModal(platform_id) {
      // TODO: OPEN POPUP TO EDIT PLATFORM DATA
      if (platform_id) {
        this.modal_platform = await this.platforms_list.find(
          (platform) => platform.id === platform_id
        )
      } else {
        this.modal_platform = {
          label: '',
          responsible: '',
          phone: '',
          fax_number: '',
          email: '',
          bank: '',
          bank_account_owner: '',
          iban: '',
          bic: '',
          siret:'',
          Address: {
            address: '',
            city: '',
            department: '',
            country: '',
            zip_code: '',
          },
        }
      }
      this.$modal.show('platform')
    },
    async submitPlatform(platform) {
      this.startValidation('modal_platform')
      this.startForm()
      let data = null
      if (platform.id === undefined) {
        data = await this.$store.dispatch('createPlatform', { ...platform })
      } else {
        data = await this.$store.dispatch('updatePlatform', { ...platform })
      }
      if (!data) {
        this.$store.dispatch('addMessage', {
          key: 'submitPlatformError',
          params: { display: this.$t('error.submitForm'), error: true },
        })
        this.errorForm()
      } else {
        this.$store.dispatch('addMessage', {
          key: 'submitPlatformSuccess',
          params: { display: this.$t('success.submitForm'), error: false },
        })
        this.cancelPlatform()
        this.init()
        this.endForm()
      }
    },
    cancelPlatform() {
      this.$modal.hide('platform')
      this.modal_platform = {}
    },
  },
}
</script>

<template>
  <section class="prodige-history text-xxs text-dark-grey font-medium">
    {{ $t('text.creationdate') }}{{ new Date(createdAt) | date
    }}{{ $t('text.udpateddate') }}{{ new Date(updatedAt) | date }}
  </section>
</template>

<script>
export default {
  name: 'ProdigeHistory',
  props: {
    createdAt: {
      type: String,
      default: '0',
    },
    updatedAt: {
      type: String,
      default: '0',
    },
  },
}
</script>

<style scoped></style>

export default {
  title: {
    userManagement: 'Gestion des utilisateurs',
  },
  text: {
    ID: 'Id',
    lastname: 'Nom',
    firstname: 'Prénom',
    email: 'Email',
    profile: 'Profil',
    creationDate: 'Date de création',
    enabled: 'Actif',
    company: 'Service',
    profil: 'Profil',
    perimetre: 'Perimetre',
    status: 'Statut',
    adherentGroupNotIncluded: "Groupe d'adhérents non inclus",
    adherentGroupIncluded: "Groupe d'adhérents inclus",
    adherentNotIncluded: 'Adhérents non inclus',
    adherentIncluded: 'Adhérents inclus',
    orderValidators: 'Niveau de commande',
    create_product_hc: 'Création de produit hors catalogue',
    create_supplier_hc: 'Création de fournisseur hors catalogue',
    updateModal: 'Veuillez vous déconnecter pour appliquer les modifications',
  },
  error: {
    loadUser: 'Erreur utilisateur introuvable',
    getProfiles: 'Impossible de charger les Profiles',
    allStatus: 'Impossible de charger les Status',
    getStatus: 'Impossible de charger le status user',
    getCompanies: 'Impossible de charger les entreprises',
    getRoles: 'Impossible de charger les Qualités',
    getAdherents: 'Impossible de charger les Adhérents',
    getGroupAdherents: "Impossible de charger les Groupes d'adhérent",
    cancelForm: 'Erreur lors de la réinitialisation du formulaire',
    formVerificationError: 'Un ou plusieurs champs sont incorrects',
    updateUser: "Une erreur s'est produite lors de l'enregistrement",
    submitForm: "Une erreur s'est produite lors de l'envoi du formulaire",
    lastName: 'Nom invalide',
    firstName: 'Prénom invalide',
    email: "Le format de l'email saisi est incorrect",
    phone: 'Numéro de téléphone invalide',
    status: 'Veuillez séléctionner un Statut',
    profile: 'Veuillez séléctionner un Profil',
    company: 'Veuillez séléctionner un Service',
    role: 'Veuillez séléctionner une Qualité',
    createUserExists: 'Cette adresse email est déjà associé à un compte',
    createUserDB: "Une erreur coté serveur s'est produite Code:err432",
    createUserFindAfterCreate:
      "Une erreur coté serveur s'est produite Code:err433",
  },
  success: {
    submitForm: 'Enregistrement effectué avec succès',
    cancelForm: 'Formulaire réinitialisé',
  },
  action: {
    addNewUser: 'Nouvel utilisateur',
    fakeUser: "Se faire passer pour l'utilisateur",
  },
  description: {
    perimetreAdherent:
      "Vous pouvez ici choisir quel groupe d'adhérents et/ou adhérents est visible par cet utilisateur",
    perimetreSupplier:
      'Vous pouvez ici choisir quel fournisseur est visible par cet utilisateur',
  },
}

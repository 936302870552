import { createRequest, handleError } from './api.js'

const request = createRequest()

export function getMenu(application) {
  return request
    .get(`/core/menu/${application}`, {})
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function getAllMenus(includeparents = true) {
  return request
    .get('/core/menu/list', {
      params: {
        includeparents,
      },
    })
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export default {
  text: {
    backOffice: 'Back office',
    'back-office': 'Back office',
    frontOffice: 'Outils Adhérents',
    'front-office': 'Outils Adhérents',
    tenders: "Réponse appels d'offre",
    publicNotice: 'Avis Public',
    unilogin: 'eValae',
  },
}

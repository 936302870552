import { render, staticRenderFns } from "./prodige-input-type-file.vue?vue&type=template&id=b3384858&"
import script from "./prodige-input-type-file.vue?vue&type=script&lang=js&"
export * from "./prodige-input-type-file.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../unilogin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
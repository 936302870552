import {
  createGenericSetting,
  createNote,
  createSettingByAdherent,
  createZoneSetting,
  getDepartments,
  getCompanyEstablishments,
  getFamilySettingsByAdherent,
  getGenericSettings,
  getNotes,
  getNoteTypes,
  getSettings,
  getSettingsByAdherent,
  getZoneSettings,
  getPublicNoticeDocuments,
  removeGenericSetting,
  removeNote,
  removeZoneSetting,
  updateFamilySettingsByAdherent,
  updateGenericSettings,
  updateNotes,
  updateSettingsByAdherent,
  updateZoneSettings,
  updatePublicNoticeDocuments,
  getOrderTaxes,
} from '../api/setting.js'

export default {
  state: {
    departments: [],
    brands: [],
    calibers: [],
    civilities: [],
    publicNotice: null,
    establishmentTypes: [],
    labels: [],
    notes: [],
    noteTypes: [],
    origins: [],
    preservations: [],
    rates: [],
    roles: [],
    municipalities: [],
    settingCount: null,
    settingData: [],
    settings: [],
    statuses: [],
    unities: [],
    newEntity: '',
    procedures: [],
  },

  mutations: {
    createGenericSetting(state, newEntity) {
      state.newEntity = newEntity
    },
    createNote(state, newEntity) {
      state.newEntity = newEntity
    },
    createZoneSetting(state, newEntity) {
      state.newEntity = newEntity
    },
    createSettingByAdherent(state, newEntity) {
      state.newEntity = newEntity
    },
    getDepartments(state, departments) {
      state.departments = departments
    },
    getCompanyEstablishments(state, establishmentTypes) {
      state.establishmentTypes = establishmentTypes
    },
    getFamilySettingsByAdherent(state, settingData) {
      state.settingData = settingData
    },
    getGenericSettings(state, settingData) {
      state.settingData = settingData
    },
    getSettingsByAdherent(state, settingData) {
      state.settingData = settingData.rows
      state.settingCount = settingData.count
    },
    getBrands(state, brands) {
      state.brands = brands
    },
    getCalibers(state, calibers) {
      state.calibers = calibers
    },
    getCivilities(state, civilities) {
      state.civilities = civilities
    },
    getLabels(state, labels) {
      state.labels = labels
    },
    getNotes(state, notes) {
      state.notes = notes
    },
    getNoteTypes(state, noteTypes) {
      state.noteTypes = noteTypes
    },
    getOrigins(state, origins) {
      state.origins = origins
    },
    getPreservations(state, preservations) {
      state.preservations = preservations
    },
    getGouvMunicipalities(state, municipalities) {
      state.municipalities = municipalities
    },
    getRates(state, rates) {
      // Sort rates from lowest to highest value
      const sortedRates = rates.sort((a, b) => {
        if (Number(a.label) > Number(b.label)) return 1
        if (Number(a.label) < Number(b.label)) return -1
        return 0
      })
      state.rates = sortedRates
    },
    getRoles(state, roles) {
      state.roles = roles
    },
    getSettings(state, settings) {
      state.settings = settings
    },
    getStatuses(state, statuses) {
      state.statuses = statuses
    },
    getUnities(state, unities) {
      state.unities = unities
    },
    getZoneSettings(state, settingData) {
      state.settingData = settingData
    },
    getProcedures(state, settingData) {
      state.procedures = settingData
    },
    getPublicNoticeDocuments(state, publicNotice) {
      state.publicNotice = publicNotice
    },
    removeGenericSetting(state, newEntity) {
      state.newEntity = newEntity
    },
    removeNote(state, newEntity) {
      state.newEntity = newEntity
    },
    removeZoneSetting(state, newEntity) {
      state.newEntity = newEntity
    },
    // updateGenericSettings(state, settingData) {
    //   state.newEntity = settingData;
    // },
    updateNotes(state, settingData) {
      state.newEntity = settingData
    },
    updateSettingsByAdherent(state, settingData) {
      state.newEntity = settingData
    },
    updateZoneSettings(state, settingData) {
      state.newEntity = settingData
    },
    updateFamilySettingsByAdherent(state, settingData) {
      state.newEntity = settingData
    },
    updatePublicNoticeDocuments(state, settingData) {
      state.newEntity = settingData
    },
    getOrderTaxes(state, orderTax) {
      state.settingData = orderTax
    },
  },
  actions: {
    async createGenericSetting({ commit }, settingData) {
      const data = await createGenericSetting(settingData)
      if (data !== false) {
        commit('createGenericSetting', data)
      }
      return data
    },
    async createNote({ commit }, noteScaleData) {
      const data = await createNote(noteScaleData)
      if (data !== false) {
        commit('createNote', data)
      }
      return data
    },
    async createZoneSetting({ commit }, settingData) {
      const data = await createZoneSetting(settingData)
      if (data !== false) {
        commit('createZoneSetting', data)
      }
      return data
    },
    async createSettingByAdherent({ commit }, settingData) {
      const data = await createSettingByAdherent(settingData)
      if (data !== false) {
        commit('createSettingByAdherent', data)
      }
      return data
    },
    async getDepartments({ commit }) {
      const data = await getDepartments()
      if (data !== false) {
        commit('getDepartments', data)
      }
      return data
    },
    async getCivilities({ commit }) {
      const data = await getGenericSettings('civility')
      if (data !== false) {
        commit('getCivilities', data)
      }
      return data
    },
    async getCompanyEstablishments({ commit }, status) {
      const data = await getCompanyEstablishments(status)
      if (data !== false) {
        commit('getCompanyEstablishments', data)
      }
      return data
    },
    async getFamilySettingsByAdherent({ commit }, adherentId) {
      const data = await getFamilySettingsByAdherent(adherentId)
      if (data !== false) {
        commit('getFamilySettingsByAdherent', data)
      }
      return data
    },
    async getGenericSettings({ commit }, model) {
      const data = await getGenericSettings(model)
      if (data !== false) {
        commit('getGenericSettings', data)
      }
      return data
    },
    async getSettingsByAdherent({ commit }, settingData) {
      const data = await getSettingsByAdherent(settingData)
      if (data !== false) {
        commit('getSettingsByAdherent', data)
      }
      return data
    },
    async getProcedures({ commit }) {
      const data = await getGenericSettings('procedure')
      if (data !== false) {
        commit('getProcedures', data)
      }
      return data
    },
    async getBrands({ commit }) {
      const data = await getGenericSettings('brand')
      if (data !== false) {
        commit('getBrands', data)
      }
      return data
    },
    async getCalibers({ commit }) {
      const data = await getGenericSettings('caliber')
      if (data !== false) {
        commit('getCalibers', data)
      }
      return data
    },
    async getLabels({ commit }) {
      const data = await getGenericSettings('label')
      if (data !== false) {
        commit('getLabels', data)
      }
      return data
    },
    async getNotes({ commit }, noteScaleParams) {
      const data = await getNotes(noteScaleParams)
      if (data !== false) {
        commit('getNotes', data)
      }
      return data
    },
    async getNoteTypes({ commit }, noteType) {
      const data = await getNoteTypes(noteType)
      if (data !== false) {
        commit('getNoteTypes', data)
      }
      return data
    },
    async getOrigins({ commit }) {
      const data = await getGenericSettings('origin')
      if (data !== false) {
        commit('getOrigins', data)
      }
      return data
    },
    async getPreservations({ commit }) {
      const data = await getGenericSettings('preservation')
      if (data !== false) {
        commit('getPreservations', data)
      }
      return data
    },
    async getRates({ commit }) {
      const data = await getGenericSettings('rate')
      if (data !== false) {
        commit('getRates', data)
      }
      return data
    },
    async getRoles({ commit }) {
      const data = await getGenericSettings('role')
      if (data !== false) {
        commit('getRoles', data)
      }
      return data
    },
    async getSettings({ commit }, appName) {
      const data = await getSettings(appName)
      if (data !== false) {
        commit('getSettings', data)
      }
      return data
    },
    async getUnities({ commit }) {
      const data = await getGenericSettings('unity')
      if (data !== false) {
        commit('getUnities', data)
      }
      return data
    },
    async getStatuses({ commit }) {
      const data = await getGenericSettings('status')
      if (data !== false) {
        commit('getStatuses', data)
      }
      return data
    },
    async getZoneSettings({ commit }, model) {
      const data = await getZoneSettings(model)
      if (data !== false) {
        commit('getZoneSettings', data)
      }
      return data
    },
    async getPublicNoticeDocuments({ commit }) {
      const data = await getPublicNoticeDocuments()
      if (data !== false) {
        commit('getPublicNoticeDocuments', data)
      }
      return data
    },
    async removeGenericSetting({ commit }, settingData) {
      const data = await removeGenericSetting(settingData)
      if (data !== false) {
        commit('removeGenericSetting', data)
      }
      return data
    },
    async removeNote({ commit }, noteScaleId) {
      const data = await removeNote(noteScaleId)
      if (data !== false) {
        commit('removeNote', data)
      }
      return data
    },
    async removeZoneSetting({ commit }, settingData) {
      const data = await removeZoneSetting(settingData)
      if (data !== false) {
        commit('removeZoneSetting', data)
      }
      return data
    },
    async updateFamilySettingsByAdherent({ commit }, settingData) {
      const data = await updateFamilySettingsByAdherent(settingData)
      if (data !== false) {
        commit('updateFamilySettingsByAdherent', data)
      }
      return data
    },
    async updateGenericSettings({ commit }, settingData) {
      const data = await updateGenericSettings(settingData)
      if (data !== false) {
        commit('updateGenericSettings', data)
      }
      return data
    },
    async updateNotes({ commit }, settingData) {
      const data = await updateNotes(settingData)
      if (data !== false) {
        commit('updateNotes', data)
      }
      return data
    },
    async updateSettingsByAdherent({ commit }, settingData) {
      const data = await updateSettingsByAdherent(settingData)
      if (data !== false) {
        commit('updateSettingsByAdherent', data)
      }
      return data
    },
    async updateZoneSettings({ commit }, settingData) {
      const data = await updateZoneSettings(settingData)
      if (data !== false) {
        commit('updateZoneSettings', data)
      }
      return data
    },
    async updatePublicNoticeDocuments({ commit }, settingData) {
      const data = await updatePublicNoticeDocuments(settingData)
      if (data !== false) {
        commit('updatePublicNoticeDocuments', data)
      }
      return data
    },
    async getOrderTaxes({ commit }) {
      const data = await getOrderTaxes()
      if (data !== false) {
        commit('getOrderTaxes', data)
      }
      return data
    },
  },
}

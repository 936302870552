export default {
  text: {
    litigationNews: 'Actualité des litiges',
    litigationNb:
      "Vous n'avez pas de litiges en cours | Vous avez {count} litige en cours de traitement | Vous avez {count} litiges en cours de traitement",
    notices: 'Notifications',
    noticeNb:
      "Vous n'avez pas de notification | Vous avez {count} nouvelle notification | Vous avez {count} nouvelles notifications",
    messages: 'Messages',
    messageNb: "Vous n'avez pas de message",
  },
  success: {},
  error: {},
}

import { getStatus, getAllStatus } from '../api/status.js'

export default {
  state: {
    status: {},
    allStatus: [],
    inactiveStatus: 2,
  },
  getters: {
    inactiveStatus: (state) => state.inactiveStatus,
  },
  mutations: {
    getStatus(state, status) {
      state.status = status
    },
    getAllStatus(state, allStatus) {
      state.allStatus = allStatus
    },
  },
  actions: {
    async getStatus({ commit }, id) {
      const data = await getStatus(id)
      if (data) {
        commit('getStatus', data)
      }
      return data
    },
    async getAllStatus({ commit }) {
      const data = await getAllStatus()
      if (data) {
        commit('getAllStatus', data)
      }
      return data
    },
  },
}

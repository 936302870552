<template>
  <router-view />
</template>
<script>
export default {
  name: 'App',
  watch: {
    '$route': function (route) {
      if (route.matched.length > 2) {
        localStorage.setItem('current_route_name', route.matched[2].name)
      } else {
        localStorage.setItem('current_route_name', route.name)
      }
    }
  },
  mounted() {
    if (this.$route.matched.length > 2) {
      localStorage.setItem('current_route_name', this.$route.matched[2].name)
    } else {
      localStorage.setItem('current_route_name', this.$route.name)
    }
  }
}
</script>

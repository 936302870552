import { routers } from '@apps/shared-components'
import store from '../store/index.js'

const { isLogged, publicRoutes } = routers.guards

export { isLogged, publicRoutes }

export function isAllowed(routeName) {
  const menu = store.getters.getMenu
}

<template>
  <label
    class="prodige-draggable-item focus:bg-purple border border-light-grey rounded-lg flex items-center w-full h-full px-2 py-1 text-sm hover:bg-purple hover:text-white"
    :class="checked ? 'selected' : ''"
    click="plop"
  >
    <input
      class="hidden"
      type="checkbox"
      :checked="value"
      @input="handleSelect"
    />
    <ab-icon class="icon" icon="drag" iconset="dragable" />
    <span class="select-none"><slot /></span>
  </label>
</template>

<script>
import { AbIcon } from '@ab/basic-components'

export default {
  name: 'ProdigeDraggableItem',
  components: {
    AbIcon,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    handleSelect(event) {
      this.$emit('input', event.target.checked)
      this.checked = event.target.checked
    },
  },
}
</script>

<style scoped>
.prodige-draggable-item:focus {
  outline: none;
}
.selected {
  @apply bg-purple text-white font-medium font-poppins-medium;
}
.icon {
  @apply w-4 mr-2 !important;
}
.prodige-draggable-item:hover {
  cursor: move;
}
</style>

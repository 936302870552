<template>
  <label class="prodige-chips inline-block">
    <div class="term">
      <span class="ctn"><slot /></span>
      <ab-icon
        icon="close"
        iconset="tag"
        class="text-xxs"
        @click.native="$emit('delete')"
      />
    </div>
    <ab-iconset iconset="tag">
      <g id="close"
        ><path
          d="M16.2 12l6.9-6.9c1.2-1.2 1.2-3 0-4.2-1.2-1.2-3-1.2-4.2 0L12 7.8 5.1.9C3.9-.3 2.1-.3.9.9c-1.2 1.2-1.2 3 0 4.2L7.8 12 .9 18.9c-1.2 1.2-1.2 3 0 4.2.6.6 1.2.9 2.1.9.9 0 1.5-.3 2.1-.9l6.9-6.9 6.9 6.9c.6.6 1.2.9 2.1.9.9 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3 0-4.2L16.2 12z"
      /></g>
    </ab-iconset>
  </label>
</template>

<script>
import { AbIcon, AbIconset } from '@ab/material-components'

export default {
  name: 'ProdigeChips',
  components: {
    AbIcon,
    AbIconset,
  },
}
</script>

<style scoped>
/* Chips */
.term {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  background-color: var(--light-grey);
  border: none;
  outline: none;
  padding: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--dark-grey);
  white-space: nowrap;
  align-items: center;
  border-radius: 5px;
  vertical-align: middle;
  text-decoration: none;
  min-width: 6rem;
  height: 1.3rem;
}
.term > span {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  font-size: 0.825rem;
  min-width: 1rem;
  width: 100%;
  height: 100%;
}

.term .ab-icon {
  position: absolute;
  right: -0.25rem;
  display: flex;
  background-color: var(--line-grey-td-left-right);
  border-radius: 0.75rem;
  cursor: pointer;
  height: auto;
  padding: 0.125rem;
  width: 1.075rem;
  height: 1.075rem;
  user-select: none;
  flex-shrink: 0;
  margin-right: 0;
}
/* .term .ab-icon:hover{
  background-color: #666666;
} */
</style>
<style>
.term .ab-icon > i {
  color: var(--dark-default);
  height: 50%;
}
</style>

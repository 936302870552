import { getApps } from '../api/app.js'

export default {
  state: {
    apps: [],
  },
  mutations: {
    getApps(state, apps) {
      state.apps = apps
    },
  },
  actions: {
    async getApps({ commit }) {
      const data = await getApps()
      if (data !== false) {
        commit('getApps', data)
      }
      return data
    },
  },
}

<template>
  <section
    class="supplier-platform-form rounded-lg pb-5 bg-white w-3/6 h-2/3 overflow-y-scroll"
  >
    <h1
      class="bg-light-grey text-grey-title-pop-up rounded-lg rounded-b-none text-lg border-middle-grey py-3 px-5 mb-6"
    >
      {{ $t('profile.text.platformRao') }}
    </h1>
    <section class="bg-white px-5">
      <form @submit.prevent="submitForm">
        <div class="flex w-full mb-6">
          <div class="w-full">
            <div class="input flex flex-wrap justify-between">
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.label"
                  :label="$t('profile.text.platformName')"
                  :error="$v.formData.label.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.label.required"
                    class="err-msg"
                  >{{
                    $tc('error.requiredField', 0)
                  }}</span>
                  <span
                    v-if="!$v.formData.label.maxLength"
                    class="err-msg"
                  >{{
                    $tc('error.maxLength', 0)
                  }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.Address.address"
                  :label="$t('profile.text.address')"
                  :error="$v.formData.Address.address.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.Address.address.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.siret"
                  :label="$t('profile.siret')"
                  :error="$v.formData.siret.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.Address.address.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.Address.department"
                  :label="$t('profile.text.department')"
                  :error="$v.formData.Address.department.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.Address.department.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.Address.zip_code"
                  :label="$t('profile.text.zipCode')"
                  :error="$v.formData.Address.zip_code.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.Address.zip_code.numeric"
                    class="err-msg"
                  >{{ $t('error.dataTypeNumber') }}</span>
                  <span
                    v-if="!$v.formData.Address.zip_code.minLength"
                    class="err-msg"
                  >{{ $tc('error.minLength', 0) }}</span>
                  <span
                    v-if="!$v.formData.Address.zip_code.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.Address.city"
                  :label="$t('profile.text.city')"
                  :error="$v.formData.Address.city.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.Address.city.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.Address.country"
                  :label="$t('profile.text.country')"
                  :error="$v.formData.Address.country.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.Address.country.maxLength"
                    class="err-msg"
                  >{{ $t('error.dataTypeNumber') }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.responsible"
                  :label="$t('profile.text.responsibleName')"
                  :error="$v.formData.responsible.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.responsible.required"
                    class="err-msg"
                  >{{ $tc('error.requiredField', 0) }}</span>
                  <span
                    v-if="!$v.formData.responsible.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.email"
                  :label="$t('profile.text.email')"
                  :error="$v.formData.email.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.email.required"
                    class="err-msg"
                  >{{
                    $tc('error.requiredField', 0)
                  }}</span>
                  <span
                    v-if="!$v.formData.email.email"
                    class="err-msg"
                  >{{
                    $tc('error.email', 0)
                  }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.phone"
                  v-mask="'## ## ## ## ##'"
                  :label="$t('profile.text.phone')"
                  :error="$v.formData.phone.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.phone.required"
                    class="err-msg"
                  >{{
                    $tc('error.requiredField', 0)
                  }}</span>
                  <span
                    v-if="!$v.formData.phone.minLength"
                    class="err-msg"
                  >{{
                    $tc('error.minLength', 10)
                  }}</span>
                  <span
                    v-if="!$v.formData.phone.maxLength"
                    class="err-msg"
                  >{{
                    $tc('error.maxLength', 1, { n: 10 })
                  }}</span>
                </p>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.fax_number"
                  v-mask="'## ## ## ## ##'"
                  :label="$t('profile.text.faxNumber')"
                  :error="$v.formData.fax_number.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.fax_number.minLength"
                    class="err-msg"
                  >{{ $tc('error.minLength', 10) }}</span>
                  <span
                    v-if="!$v.formData.fax_number.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-full">
                <prodige-title class="font-bold">
                  <ab-icon
                    icon="euro"
                    iconset="profile"
                  />
                  {{ $t('profile.label.bankInfo') }}
                </prodige-title>
              </div>
              <div class="w-47 mb-8">
                <ab-input
                  v-model.trim="formData.bank"
                  :label="$t('profile.text.bank')"
                  :error="$v.formData.bank.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.bank.maxLength"
                    class="err-msg"
                  >{{
                    $tc('error.maxLength', 0)
                  }}</span>
                </p>
              </div>
              <div class="w-47">
                <ab-input
                  v-model.trim="formData.bank_account_owner"
                  :label="$t('profile.text.bankAccountOwner')"
                  :error="$v.formData.bank_account_owner.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.bank_account_owner.maxLength"
                    class="err-msg"
                  >{{ $tc('error.maxLength', 0) }}</span>
                </p>
              </div>
              <div class="w-47">
                <ab-input
                  v-model.trim="formData.iban"
                  :label="$t('profile.text.iban')"
                  :error="$v.formData.iban.$error"
                />
                <p v-if="errors">
                  <span
                    v-if="!$v.formData.iban.maxLength"
                    class="err-msg"
                  >{{
                    $tc('error.maxLength', 0)
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-end">
          <prodige-button
            class="btn primary mr-4"
            :disabled="pending"
            @click.native="cancelForm"
          >
            {{ $t('action.cancel') }}
          </prodige-button>
          <prodige-button
            class="btn secondary"
            :disabled="pending"
          >
            {{ $t('action.save') }}
          </prodige-button>
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { AbInput } from '@ab/material-components'
import { AbIcon } from '@ab/basic-components'

const {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} = require('vuelidate/lib/validators')

export default {
  name: 'ProfilePlatformForm',
  components: {
    AbInput,
    AbIcon,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        label: '',
        responsible: '',
        phone: '',
        fax_number: '',
        email: '',
        bank: '',
        bank_account_owner: '',
        iban: '',
        bic: '',
        siret: '',
        Address: {
          address: '',
          city: '',
          department: '',
          country: '',
          zip_code: '',
        },
        Company: {},
      },
    }
  },
  computed: {
    ...mapState({
      supplier: (state) => state.supplier.supplier,
    }),
  },
  validations: {
    formData: {
      label: { maxLength: maxLength(64), required },
      responsible: { maxLength: maxLength(64), required },
      phone: { required, maxLength: maxLength(14), minLength: minLength(14) },
      fax_number: { minLength: minLength(14), maxLength: maxLength(14) },
      email: { required, email },
      bank: { maxLength: maxLength(64) },
      bank_account_owner: { maxLength: maxLength(64) },
      iban: { maxLength: maxLength(34) },
      bic: { maxLength: maxLength(11) },
      siret: { maxLength: maxLength(15) },
      Address: {
        address: { maxLength: maxLength(100) },
        city: { maxLength: maxLength(100) },
        department: { maxLength: maxLength(100) },
        country: { maxLength: maxLength(100) },
        zip_code: { maxLength: maxLength(5), minLength: minLength(5), numeric },
      },
    },
  },
  mounted() {
    this.formData = { ...this.form }
    this.formData.Address = { ...this.form.Address }
  },
  methods: {
    cancelForm() {
      this.$emit('cancelFormModal')
    },
    submitForm() {
      this.startValidation('formData')
      if (this.errors) {
        this.$store.dispatch('addMessage', {
          key: 'submitPlatformError',
          params: { display: this.$t('error.submitForm'), error: true },
        })
        this.errorForm()
      } else {
        this.$emit('submitFormModal', {
          ...this.formData,
          Supplier: this.supplier,
        })
        this.$v.formData.$reset()
      }
    },
  },
}
</script>

<style scoped></style>

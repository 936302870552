import {
  login,
  getMe,
  updateMe,
  resetPassword,
  setResetPassword,
  verifyResetPasswordToken,
  updatePassword,
  impersonateUser,
  haveUserAccessTo,
} from '../api/authentication.js'
import { checkAppAccess } from '../router/index.js'
import constants from '../mixins/constants.js'

const {
  constants: { appNames },
} = constants.data()

let user = {}
// Prevent error in parsing JSON
try {
  user = JSON.parse(window.localStorage.getItem('user'))
} catch (error) {
  user = {}
}

export default {
  state: {
    user: user || {},
    token: window.localStorage.getItem('token') || '',
    refreshToken: window.localStorage.getItem('refreshToken') || '',
    tokenDate: window.localStorage.getItem('tokenDate') || '',
    newEntity: null,
    permissions: [],
  },
  getters: {
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    userId: (state) => state.user.id,
    user: (state) => state.user,
    permissions: (state) => state.permissions,
  },
  mutations: {
    setCurrentUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user
    },
    setToken(state, token) {
      localStorage.setItem('token', token)
      state.token = token
    },
    setRefreshToken(state, refreshToken) {
      localStorage.setItem('refreshToken', refreshToken)
      state.refreshToken = refreshToken
    },
    setTokenDate(state, date) {
      localStorage.setItem('tokenDate', date)
      state.tokenDate = date
    },
    setUpdatedUser(state, user) {
      state.newEntity = user
    },
    setResetToken(state, email) {
      state.newEntity = email
    },
    setResetPassword(state, password) {
      state.newEntity = password
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
  },
  actions: {
    setPermissions({ commit }, permissions) {
      commit('setPermissions', permissions)
    },
    async login({ commit }, params) {
      const data = await login(params)
      if (data !== false) {
        const { token, refresh_token, ...user } = data
        commit('setCurrentUser', user)
        commit('setToken', token)
        commit('setRefreshToken', refresh_token)
        commit('setTokenDate', Date.now())
      }
      return data
    },
    async refreshToken({ commit }, { token, refresh_token }) {
      commit('setToken', token)
      commit('setRefreshToken', refresh_token)
      commit('setTokenDate', Date.now())
    },
    logout({ commit, dispatch }) {
      window.localStorage.clear()
      dispatch('clearSelectedAdherent')
      dispatch('addMessage', {
        key: 'text.youHaveBeenLogout',
        params: { display: 'text.youHaveBeenLogout', clean: true },
      })
    },
    async getMe({ commit }) {
      const data = await getMe()
      if (data !== false) {
        commit('setCurrentUser', data)
      }
      return data
    },
    async updateMe({ commit }, userData) {
      const data = await updateMe(userData)
      if (data !== false) {
        commit('setCurrentUser', data)
        commit('setUpdatedUser', data)
      }
      return data
    },
    async resetPassword({ commit }, email) {
      const data = await resetPassword(email)
      if (data !== false) {
        commit('setResetToken', data)
      }
      return data
    },
    async verifyToken({ commit }, token) {
      return verifyResetPasswordToken(token)
    },
    async setResetPassword({ commit }, data) {
      return setResetPassword(data)
    },
    async modifyPassword({ commit }, userData) {
      return updatePassword(userData)
    },
    async impersonateUser({ commit }, userToImpersonate) {
      const data = await impersonateUser(userToImpersonate)
      if (data !== false) {
        const { token, refresh_token, ...user } = data
        commit('setCurrentUser', user)
        commit('setToken', token)
        commit('setTokenDate', Date.now())
      }
      return data
    },
    async checkAppAccess({ dispatch, rootState, getters }) {
      // Get all available apps
      await dispatch('getApps')

      // We check the user access rights for all available apps
      const accesses = checkAppAccess(rootState.app.apps, getters.user.Profile)

      const backOfficeAccess = accesses.find(
        (app) => app.name === appNames.BACK_OFFICE && app.access
      )
      const frontOfficeAccess = accesses.find(
        (app) => app.name === appNames.FRONT_OFFICE && app.access
      )
      const tendersAccess = accesses.find(
        (app) => app.name === appNames.TENDERS && app.access
      )

      const _isDev = process.env.NODE_ENV !== 'production'

      // If the user has the right to access back office,
      // We redirect the user to the back office app
      if (backOfficeAccess) {
        return _isDev
          ? 'http://localhost:8081/back-office/'
          : backOfficeAccess.url
      }
      // If the user has the right to access tenders,
      // We redirect the user to the tenders app
      if (tendersAccess) {
        return _isDev ? 'http://localhost:8083/tenders/' : tendersAccess.url
      }

      // If the user has the right to access front office,
      // We redirect the user to the front office app
      if (frontOfficeAccess) {
        return _isDev
          ? 'http://localhost:8084/front-office/'
          : frontOfficeAccess.url
      }
      return false
    },
    async haveAccess({ commit }, route) {
      return haveUserAccessTo(route)
    },
  },
}

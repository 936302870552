import { getMenu, getAllMenus } from '../api/menu.js'

export default {
  state: {
    menu: [],
    allMenus: [],
  },
  getters: {
    getMenu: (state) => state.menu,
  },
  mutations: {
    getMenu(state, menu) {
      state.menu = menu
    },
    getAllMenus(state, menus) {
      state.allMenus = menus
    },
  },
  actions: {
    async getMenu({ commit }, application) {
      const data = await getMenu(application)
      if (data !== false) {
        commit('getMenu', data)
      }
      return data
    },
    async getAllMenus({ commit }, includeParent = true) {
      const data = await getAllMenus(includeParent)
      if (data !== false) {
        commit('getAllMenus', data)
      }
      return data
    },
  },
}

<template>
  <button
    class="add-button ml-8 bg-white rounded-t-md pt-3 pb-3 pl-2 pr-2 flex items-center text-sm text-ml-grey ml-5"
    type="button"
  >
    <ab-icon icon="circled-add" />
    <slot />
  </button>
</template>

<script>
import { AbIcon } from '@ab/material-components'

export default {
  name: 'ProdigeAddButton',
  components: { AbIcon },
}
</script>

<style scoped>
.add-button {
  @apply bg-white;
  @apply text-orange;
}
</style>

import { createRequest, handleError } from './api.js'

const request = createRequest()

export function getDashboardMessageTypes() {
  return request
    .get('/dashboardmessage/type', {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getDashboardMessagesByAdherent({ id, is_group }) {
  return request
    .get(`/dashboardmessage/adherent/${id}`, {
      params: {
        is_group,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getDashboardMessage(id) {
  return request
    .get(`/dashboardmessage/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function createDashboardMessage(messageData) {
  return request
    .post('/dashboardmessage/', messageData, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateDashboardMessage(messageData) {
  return request
    .put(`/dashboardmessage/${messageData.id}`, messageData, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function deleteDashboardMessage(id) {
  return request
    .delete(`/dashboardmessage/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export default {
  data() {
    return {
      currentPage: Number(this.$route.query.page) || 1,
    }
  },
  created() {
    const unwatch = this.$watch(function watcher() {
      if (!this.currentPage) this.currentPage = 1

      // The element that include this mixin must define a getData() method,
      // in order to get the data when the page changed.
      this.getData()
    })
    unwatch()
  },
  methods: {
    async getData(isNewSearch) {
      return false
    },
    pageCount(counter, limitCount) {
      if (counter) {
        return Math.ceil(counter / limitCount)
      }
      return 1
    },
  },
  watch: {
    currentPage(newCurrentPage, oldCurrentPage) {
      if (!newCurrentPage) this.currentPage = 1
      const query = {}
      if (this.currentPage > 1) query.page = `${this.currentPage}`
      // Sync to URI
      this.$router.push({
        query: {
          ...this.$route.query,
          page: query.page,
        },
      })
      this.getData()
    },
    '$route.query.page': function watchPage(page) {
      if (typeof page === 'undefined') this.currentPage = 1
    },
  },
}

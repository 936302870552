<template>
  <section>
    <div class="tabs mt-8">
      <ul class="flex mx-5">
        <li
          v-for="(tab, idx) in tabs"
          :key="`tab-${idx}`"
          :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
        >
          <a
            :href="`?tab=${tab.href}`"
            class="flex items-center py-3 px-5 block bg-white text-md text-dark-grey hover:bg-purple hover:text-white"
            rel="noopener noreferrer"
            @click.prevent="navigate(tab)"
            >{{ tab.name }}
          </a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProdigeTabs',
  props: {
    defaultTab: String,
  },
  data() {
    return {
      tabs: [],
    }
  },
  watch: {
    // If the tab query param change select the corresponding tab
    '$route.query.tab': function handleTabChange() {
      const { tab } = this.$route.query
      this.selectTab(tab)
      this.hideSpinner('default', 0)
    },
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    // If their is aleady a defined tab in the URL
    // Define the correspinding tab as active
    if (this.$route.query.tab) {
      this.selectTab(this.$route.query.tab)
    }
  },
  methods: {
    selectTab(currentTab) {
      this.tabs.forEach((tab) => {
        /* eslint-disable no-param-reassign */
        tab.isActive = tab.href === currentTab
      })
    },
    navigate(tab) {
      if (this.$route.query.tab !== tab.href && !tab.isDisabled) {
        this.$router.push({ query: { tab: tab.href } })
      }
    },
  },
}
</script>

<style scoped>
.tabs a {
  border-right: 1px solid #f2f2f2;
}

.tabs li {
  @apply flex;
}

.tabs li:first-child a {
  border-top-left-radius: 10px;
}

.tabs li:last-child a {
  border-top-right-radius: 10px;
  border-right: 0;
}
.is-active a {
  @apply bg-purple text-white;
}
.is-disabled a {
  @apply cursor-default;
}
.is-disabled a:hover {
  @apply bg-white text-black;
}
</style>

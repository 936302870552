export default {
  beforeRouteEnter(to, from, next) {
    if (to.query.from) {
      next((vm) => {
        if (vm.init) vm.init()
      })
    }
    next()
  },
  computed: {
    isDuplicate() {
      return !!this.$route.query.from && this.$route.meta.isCreate
    },
  },
}

export default {
  text: {
    company: 'Service',
    adherent: 'Adhérents',
    litigationDate: 'Date litige',
    supplier: 'Fournisseurs',
    type: 'Type',
    status: 'Statut',
    creationDate: 'Date création',
    description: 'Description du litige',
    order: 'Commande',
    state: 'Etat',
    object: 'Objet',
    sender: 'Emetteur',
    title: 'Titre',
    catalog: 'Catalogue',
    family: 'Famille',
    product: 'Produit',
    orderNb: 'No Commande',
    deliveryForm: 'Bon de livraison',
    numero: 'Numéro',
  },
  modalText: {},
  error: {
    litigationNotFound: "Le litige n'a pas été trouvé",
    getLitigations: 'Erreur lors de la récupération des litiges.',
    missingDescription: 'Veuillez ajouter une description',
    litigationUpdateError: "Erreur lors de l'enregistrement.",
    litigationErrorOnSendLitigation: "Erreur lors de l'envoi du litige",
    litigationErrorOnGettingById: 'Erreur litige introuvable',
    noProjectManagerFound:
      "Aucun chargé de mission n'est associé à l'adhérent concerné par le litige, contactez Valaé.",
  },
  success: {},
}

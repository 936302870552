/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 * @see https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 */

export function deepMerge(target, ...sources) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepMerge(target, ...sources)
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Check if the current use has access to application
 * @param {Array} apps An array of applications
 * @param {Array} profile ProfileMenu
 * @returns Boolean
 */
export function checkAppAccess(apps, profile) {
  const { Profile_Menu } = profile
  // User has access to an appliction if
  // At least his profile give him access to one menu of this application (view or edit)
  const accesses = apps.map((app) => {
    return {
      id: app.id,
      name: app.machine_name,
      access: Profile_Menu.some(
        (item) =>
          item.Menu &&
          item.Menu.application_id === app.id &&
          (item.view_access || item.edit_access)
      ),
      url: app.url,
    }
  })
  return accesses
}

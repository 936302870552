export default {
  add: 'Ajouter',
  addCart: 'Créer panier',
  addDashboardMessage: 'Générer un message',
  addDocument: 'Ajouter un document',
  addDepartment: 'Départements livrés',
  addLitigation: 'Nouveau litige',
  addNewManufacturer: 'Nouvel Industriel',
  addNewSupplier: 'Nouveau fournisseur',
  addProduct: 'Nouveau produit',
  addService: 'Nouveau service',
  addTender: "Nouvel Appel d'offre",
  cancel: 'Annuler',
  close: 'Fermer',
  confirm: 'Confirmer',
  delete: 'Supprimer',
  displayLitigations: 'Voir mes litiges',
  displayNotices: 'Voir mes notifications',
  duplicate: 'Dupliquer',
  export: 'Exporter',
  exportAdherentRevenue: 'Export fiche CA',
  exportSheet: 'Exporter excel',
  exportPDF: 'Exporter le PDF',
  generateRequest: 'Générer une demande',
  import: 'Importer',
  includeResponse: 'Intégrer les réponses',
  remind: 'Relancer',
  receiveOrder: 'Réceptionner commande',
  save: 'Enregistrer',
  saveEdition: 'Enregistrer les modifications',
  searchAccount: 'Trouver le compte',
  send: 'Envoyer',
  sendLitigation: 'Envoyer le litige',
  saveAndEdit: 'Enregistrer et editer',
  update: 'Modifier',
  valid: 'Valider',
  createLitigation: 'Créer un litige',
  exportNegotiation: 'Exporter la négociation',
  back: 'Retour',
  deleteFilters: 'Supprimer les filtres',
}

import {
  updatePlatform,
  getPlatform,
  getPlatforms,
  createPlatform,
  deletePlatform,
  getPlatformsBySupplierId,
  getPlatformsBySupplierAndAdherentId,
} from '../api/platform.js'

export default {
  state: {
    platform: {},
    platforms: [],
  },
  mutations: {
    getPlatform(state, platform) {
      state.platform = platform
    },
    getPlatforms(state, platforms) {
      state.platforms = platforms
    },
    getPlatformsBySupplierId(state, platforms) {
      state.platforms = platforms
    },
    getPlatformsBySupplierAndAdherentId(state, platforms) {
      // Store original platforms, not platform_response_tenders
      const originalPlatforms = platforms.map(
        (platform) => platform.OriginalPlatform
      )

      // Different platofrm_response can have the save platform, need to create a Set
      state.platforms = [...new Set(originalPlatforms.map(JSON.stringify))].map(
        JSON.parse
      )
    },
    updatePlatform(state, platform) {
      state.platform = platform
    },
    createPlatform(state, platform) {
      state.platform = platform
    },
    deletePlatform(state) {
      state.platform = {}
    },
  },
  actions: {
    async getPlatform({ commit }, id) {
      const data = await getPlatform(id)
      if (data) {
        commit('getPlatform', data)
      }
      return data
    },
    async getPlatforms({ commit }) {
      const data = await getPlatforms()
      if (data) {
        commit('getPlatforms', data)
      }
      return data
    },
    async updatePlatform({ commit }, platform) {
      const data = await updatePlatform(platform)
      if (data) {
        commit('updatePlatform', data)
      }
      return data
    },
    async createPlatform({ commit }, platform) {
      const data = await createPlatform(platform)
      if (data) {
        commit('createPlatform', data)
      }
      return data
    },
    async deletePlatform({ commit }, id) {
      const data = await deletePlatform(id)
      commit('deletePlatform')
      return data
    },

    async getPlatformsBySupplierId({ commit }, id) {
      const data = await getPlatformsBySupplierId(id)
      if (data !== false) {
        commit('getPlatformsBySupplierId', data)
      }
      return data
    },

    async getPlatformsBySupplierAndAdherentId({ commit }, param) {
      const data = await getPlatformsBySupplierAndAdherentId(param)
      if (data !== false) {
        commit('getPlatformsBySupplierAndAdherentId', data)
      }
      return data
    },
  },
}

<template>
  <prodige-chips @delete="$emit('delete')">
    <div>
      <span>{{ filter.name }}</span>
      <span v-html="filter.label" />
    </div>
  </prodige-chips>
</template>

<script>
import ProdigeChips from '../../layouts/prodige-chips.vue'

export default {
  name: 'SearchChips',
  components: {
    ProdigeChips,
  },
  props: {
    filter: Object,
  },
}
</script>

<style scoped></style>

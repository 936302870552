import Vue from 'vue'
import Vuex from 'vuex'

import { stores } from '@apps/shared-components'
// Import package.json
import { version } from '../../package.json'

const { app, notification, authentication, ui, loader, profile } = stores

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version,
  },
  mutations: {
    getVersion: (state) => state.version,
  },
  actions: {},
  modules: {
    app,
    notification,
    authentication,
    ui,
    loader,
    profile,
  },
})

<template>
  <form autocomplete="off" @submit.prevent="handleFormUpdateMe">
    <prodige-card>
      <!-- INFORMATIONS SECTION -->
      <div class="flex w-full">
        <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
          $t('profile.information')
        }}</span>
        <div class="w-8/12 flex flex-wrap justify-between">
          <div class="w-47 relative">
            <ab-input
              v-model="userData.last_name"
              :label="$t('profile.lastname')"
              :error="$v.userData.last_name.$error"
              name="lastname"
            />
            <p v-if="errors" class="error">
              <span v-if="!$v.userData.last_name.minLength" class="err-msg">{{
                $tc('error.minLength', 2)
              }}</span>
              <span v-if="!$v.userData.last_name.maxLength" class="err-msg">{{
                $tc('error.maxLength')
              }}</span>
            </p>
          </div>
          <div class="w-47 relative">
            <ab-input
              v-model="userData.first_name"
              :label="$t('profile.firstname')"
              :error="$v.userData.first_name.$error"
              name="firstname"
            />
            <p v-if="errors">
              <span v-if="!$v.userData.first_name.minLength" class="err-msg">{{
                $tc('error.minLength', 2)
              }}</span>
              <span v-if="!$v.userData.first_name.maxLength" class="err-msg">{{
                $tc('error.maxLength')
              }}</span>
            </p>
          </div>
          <div class="w-47 relative mt-8">
            <ab-input
              v-model="userData.email"
              :label="$t('profile.email')"
              :error="$v.userData.email.$error"
              name="email"
            />
            <p v-if="errors">
              <span v-if="!$v.userData.email.required" class="err-msg">{{
                $tc('error.requiredField')
              }}</span>
              <span v-if="!$v.userData.email.email" class="err-msg">{{
                $tc('profile.error.email')
              }}</span>
            </p>
          </div>
          <div class="w-47 relative mt-8">
            <ab-input
              v-model="userData.phone_number"
              v-mask="'## ## ## ## ##'"
              :label="$t('profile.phone')"
              :error="$v.userData.phone_number.$error"
              name="phone"
            />
            <p v-if="errors">
              <span v-if="!$v.userData.phone_number.numeric" class="err-msg">{{
                $t('error.dataTypeNumber')
              }}</span>
              <span
                v-if="!$v.userData.phone_number.minLength"
                class="err-msg"
                >{{ $tc('error.minLength', 10) }}</span
              >
              <span
                v-if="!$v.userData.phone_number.maxLength"
                class="err-msg"
                >{{ $tc('error.maxLength', 10) }}</span
              >
            </p>
          </div>
          <div class="w-47 relative mt-8">
            <multiple-select
              v-model="userData.Profile"
              :select-label="$t('profile.profile')"
              track-by="id"
              label="label"
              disabled
            />
          </div>
          <div class="w-47 relative mt-8">
            <multiple-select
              v-model="userData.Role"
              :options="roles"
              :select-label="$t('label.quality')"
              :placeholder="$t('label.qualityPlaceholder')"
              track-by="id"
              label="label"
            />
          </div>
          <!-- Logo section -->
          <div class="w-full relative mt-10">
            <prodige-file-input
              ref="fileinput"
              form="form"
              :files="userData.Avatar"
              :label="fileInput.pondLabel"
              :multiple="false"
              :max-file-size="fileInput.maxFileSizeTrunc"
              :accepted-file-types="fileInput.pondAcceptedFileType"
              :preview="true"
              @addfile="addImage($event), (isDirty = true)"
              @removefile="removeImage($event), (isDirty = true)"
            />
          </div>
        </div>
      </div>
      <!-- PASSWORD SECTION -->
      <div class="flex w-full mt-12">
        <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
          $t('profile.password')
        }}</span>
        <div class="w-8/12">
          <div class="w-47">
            <router-link
              :to="{ name: 'PasswordUpdate' }"
              class="btn secondary w-40"
            >
              {{ $t('text.changePassword') }}
            </router-link>
          </div>
        </div>
      </div>
      <!-- PERIMETER SECTION -->
      <div
        v-if="userData.Profile && userData.Profile.profile_type_id !== 1"
        class="flex w-full mt-12"
      >
        <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
          $t('profile.perimeter')
        }}</span>
        <div class="w-8/12 flex flex-wrap justify-between">
          <div
            v-if="userData.Profile && userData.Profile.profile_type_id === 3"
            class="w-47 relative"
          >
            <label class="text-sm font-semi-bold w-full">
              {{ $t('profile.adherentGroup') }}
            </label>
            <div v-if="userData.GroupAdherent.length > 0" class="flex flex-col">
              <tr
                v-for="(group, idx) in userData.GroupAdherent"
                :key="`group-${idx}`"
              >
                <div
                  class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2 w-full"
                >
                  <p>{{ group.name }}</p>
                </div>
              </tr>
            </div>
            <div v-else>
              <div
                class="text-xs text-gray-900 font-semi-bold bg-gray-200 border rounded-md p-2 mt-2"
              >
                <p>{{ $t('profile.noGroup') }}</p>
              </div>
            </div>
          </div>
          <div
            v-if="userData.Profile && userData.Profile.profile_type_id === 3"
            class="w-47 relative"
          >
            <label class="text-sm font-semi-bold w-full">
              {{ $t('profile.adherent') }}
            </label>
            <div v-if="userData.Adherent.length > 0" class="flex flex-col">
              <tr
                v-for="(adherent, idx) in userData.Adherent"
                :key="`adherent-${idx}`"
              >
                <div
                  class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2"
                >
                  <p>{{ adherent.name }}</p>
                </div>
              </tr>
            </div>
            <div v-else>
              <div
                class="text-xs text-gray-900 font-semi-bold bg-gray-200 border rounded-md p-2 mt-2"
              >
                <p>{{ $t('profile.noAdherent') }}</p>
              </div>
            </div>
          </div>
          <div
            v-if="userData.Profile && userData.Profile.profile_type_id === 2"
            class="w-47 relative"
          >
            <label class="text-sm font-semi-bold w-full">
              {{ $t('profile.supplier') }}
            </label>
            <div
              v-if="userData.Supplier && userData.Supplier.length > 0"
              class="flex flex-col"
            >
              <tr
                v-for="(supplier, idx) in userData.Supplier"
                :key="`supplier-${idx}`"
              >
                <div
                  class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2"
                >
                  <p>{{ supplier.name }}</p>
                </div>
              </tr>
            </div>
            <div
              v-else-if="userData.Supplier && userData.Supplier.id"
              class="flex flex-col"
            >
              <div
                class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2"
              >
                <p>{{ userData.Supplier.name }}</p>
              </div>
            </div>
            <div v-else>
              <div
                class="text-xs text-gray-900 font-semi-bold bg-gray-200 border rounded-md p-2 mt-2"
              >
                <p>{{ $t('profile.noSupplier') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Validator -->
      <div
        v-if="isCurrentUserAdherent"
        class="mt-12 w-full flex flex-wrap justify-between"
      >
        <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
          $t('profile.orderValidators')
        }}</span>
        <div class="w-8/12 flex flex-wrap justify-between">
          <div v-if="userData.ValidatorOne" class="w-47 mb-8">
            <label class="text-sm font-semi-bold w-full">{{
              $t('text.validatorLv1')
            }}</label>
            <div class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2">
              <p>
                {{ userData.ValidatorOne.first_name }}
                {{ userData.ValidatorOne.last_name }}
              </p>
            </div>
          </div>
          <div v-if="userData.ValidatorTwo" class="w-47">
            <label class="text-sm font-semi-bold w-full">{{
              $t('text.validatorLv2')
            }}</label>
            <div class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2">
              <p>
                {{ userData.ValidatorTwo.first_name }}
                {{ userData.ValidatorTwo.last_name }}
              </p>
            </div>
          </div>
          <div v-if="isValidator" class="w-47">
            <multiple-select
              v-model="userData.ReplacementValidator"
              :select-label="$t('label.replacementValidator')"
              :placeholder="$t('label.validatorPlaceholder')"
              :allow-empty="true"
              :multiple="false"
              :options="validators"
              :error="false"
              :custom-label="
                customlabel((item) => `${item.first_name} ${item.last_name}`)
              "
              track-by="id"
            />
          </div>
          <div
            v-if="
              userData.ValidatorOne == null && userData.ValidatorTwo == null
            "
            class="w-47"
          >
            <span class="w-full block text-md text-grey">{{
              $t('profile.noValidators')
            }}</span>
          </div>
        </div>
      </div>

      <!-- FOOTER SECTION -->
      <template #footer>
        <div class="flex justify-end">
          <prodige-button
            type="button"
            class="btn primary mr-4"
            :disabled="pending"
            @click.native="handleCancel"
          >
            {{ $t('action.cancel') }}
          </prodige-button>
          <prodige-button class="btn secondary" :disabled="pending">
            {{ $t('action.save') }}
          </prodige-button>
        </div>
      </template>
    </prodige-card>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { AbInput, MultipleSelect, AbIconset } from '@ab/material-components'
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from 'vuelidate/lib/validators'
import ProdigeButton from '../../components/forms/buttons/prodige-button.vue'
import ProdigeCard from '../../components/cards/prodige-card.vue'
import ProdigeFileInput from '../../components/forms/inputs/file/prodige-file-input.vue'
import defaultPicture from '../../assets/images/default-user.png'
import passwordComplexity from '../../mixins/passwordComplexity.js'

export default {
  name: 'ProfileUser',
  components: {
    ProdigeCard,
    AbInput,
    ProdigeButton,
    MultipleSelect,
    ProdigeFileInput,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      defaultPicture,
      userData: {
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
      },
      fileInput: {
        image: '',
        maxFileSize: 10000000,
        maxFileSizeTrunc: '10 MB',
        pondLabel: 'Glissez votre fichier image ou <u>cliquez ici</u>',
        pondAcceptedFileType: ['image/png', 'image/jpeg', 'image/gif'],
      },
      newAvatar: false,
    }
  },
  computed: {
    ...mapState({
      roles: (state) => state.setting.roles,
      validators: (state) => state.userManagement.validators,
      isValidator: (state) => state.userManagement.isValidator,
    }),
    isCurrentUserAdherent() {
      const { user } = this.$store.getters
      if (
        user.Profile &&
        user.Profile.Profile_type &&
        user.Profile.Profile_type.machine_name ===
          this.constants.profilType.ADHERENT
      ) {
        return true
      }
      return false
    },
  },
  validations: {
    userData: {
      email: {
        required,
        email,
      },
      first_name: {
        minLength: minLength(2),
        maxLength: maxLength(64),
      },
      last_name: {
        minLength: minLength(2),
        maxLength: maxLength(64),
      },
      phone_number: {
        numeric,
        minLength: minLength(14),
        maxLength: maxLength(14),
      },
    },
  },
  watch: {
    user() {
      this.userData = { ...this.user }
      if (
        this.userData.Profile &&
        this.userData.Profile.Profile_type &&
        this.userData.Profile.Profile_type.machine_name ===
          this.constants.profilType.ADHERENT
      ) {
        this.$store.dispatch('getValidatorUsers', {
          adherents: this.userData.Adherent.map((adherent) => adherent.id),
          userId: this.userData.id,
        })
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('getRoles')
  },
  methods: {
    handleCancel() {
      this.$emit('cancel-form')
    },
    handleFormUpdateMe() {
      this.$emit('submit-form', this.userData)
    },
    addImage(file) {
      this.$parent.fileInput.image = file
      this.$parent.newAvatar = true
    },
    removeImage() {
      this.$parent.userData.avatar_file_id = null
      this.$parent.fileInput.image = ''
      this.$parent.newAvatar = false
    },
  },
}
</script>

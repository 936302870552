import { createRequest, handleError } from './api.js'

const request = createRequest()

export function createGenericSetting(data) {
  return request
    .post(
      `/setting/generic/${data.model}`,
      {
        ...data,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function createNote({ model, ...data }) {
  return request
    .post(`/note/${model}`, data, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function createZoneSetting({ model, label, departmentsId, order }) {
  return request
    .post(
      `/setting/zone/${model}`,
      {
        label,
        departmentsId,
        order,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function createSettingByAdherent({
  model,
  adherent_id,
  is_group = false,
  ...data
}) {
  return request
    .post(`/setting/adherent/${adherent_id}/${model}`, {
      isGroup: is_group,
      ...data,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getDepartments() {
  return request
    .get('/department', {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getSettings(appName) {
  return request
    .get(`/setting/${appName}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getGenericSettings(model) {
  return request
    .get(`/setting/generic/${model}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getZoneSettings(model) {
  return request
    .get(`/setting/zone/${model}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getSettingsByAdherent({
  adherent_id,
  model,
  ...requestParams
}) {
  return request
    .get(`/setting/adherent/${adherent_id}/${model}`, {
      params: {
        ...requestParams,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getNotes({ model, noteTypeId, tenderId, familyId }) {
  return request
    .get(`/note/${model}`, {
      params: {
        note_type_id: noteTypeId,
        tender_id: tenderId,
        family_id: familyId,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getNoteTypes(noteType) {
  return request
    .get('/note/type', {
      params: {
        note_type: noteType,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function removeGenericSetting({ model, id }) {
  return request
    .delete(`/setting/generic/${model}/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function removeNote({ model, id }) {
  return request
    .delete(`/note/${model}/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function removeZoneSetting({ model, id }) {
  return request
    .delete(`/setting/zone/${model}/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateGenericSettings({ model, data }) {
  return request
    .put(
      `/setting/generic/${model}`,
      {
        data,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateNotes({ model, ...data }) {
  return request
    .put(`/note/${model}`, data, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateZoneSettings({ model, data }) {
  return request
    .put(
      `/setting/zone/${model}`,
      {
        data,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateSettingsByAdherent({ model, data }) {
  return request
    .put(
      `/setting/adherent/${model}`,
      {
        data,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function getFamilySettingsByAdherent(id) {
  return request
    .get(`/setting/family/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateFamilySettingsByAdherent({ id, ...data }) {
  return request
    .put(`/setting/family/${id}`, data, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getCompanyEstablishments(status) {
  return request
    .get(`/setting/establishmenttype/${status}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getPublicNoticeDocuments() {
  return request
    .get('/setting/publicnotice', {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updatePublicNoticeDocuments(data) {
  return request
    .put(
      '/setting/publicnotice',
      {
        data,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function getOrderTaxes() {
  return request
    .get('/order/order-taxes', {})
    .then(({ data }) => data)
    .catch(handleError)
}

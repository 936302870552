<template>
  <section
    v-if="itemData"
    :id="`${id}`"
    class="prodige-accordion-item accordion-item"
    :class="{ 'is-active': itemData.active }"
  >
    <dt class="accordion-item-title">
      <button class="accordion-item-trigger" @click.prevent="toggle">
        <h4 class="accordion-item-title-text">
          {{ itemData.title }}
        </h4>
        <span class="accordion-item-trigger-icon" />
      </button>
    </dt>
    <transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <dd v-if="itemData.active" class="accordion-item-details">
        <!-- <md-content class="accordion-item-details-inner" :content="itemData.content"></md-content> -->
        <slot />
      </dd>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'ProdigeAccordionItem',
  props: {
    title: String,
    active: Boolean,
    item: Object,
    multiple: String,
    groupId: Number,
  },
  data() {
    return {
      id: null,
      itemData: {
        title: this.title,
        active: this.active,
      },
    }
  },
  watch: {
    title() {
      this.itemData = {
        ...this.itemData,
        title: this.title,
      }
    },
    active() {
      this.itemData = {
        ...this.itemData,
        active: this.active,
      }
    },
  },
  mounted() {
    this.id = `item-${this._uid}`
  },
  methods: {
    toggle(event) {
      if (this.multiple)
        this.$set(this.itemData, 'active', !this.itemData.active)
      else {
        this.$parent.$children.forEach((item, index) => {
          if (
            item.$el.id === event.currentTarget.parentElement.parentElement.id
          )
            this.$set(this.itemData, 'active', !this.itemData.active)
          else item.$set(item.itemData, 'active', false)
        })
      }
    },
    startTransition(el) {
      const item = el
      item.style.height = `${el.scrollHeight}px`
    },
    endTransition(el) {
      const item = el
      item.style.height = ''
    },
  },
}
</script>

<style scoped>
.accordion-item-trigger,
.accordion-item-details-inner {
  padding: 1rem 1.25rem;
}

.accordion-item-title {
  position: relative;
}
.accordion-item-title h4 {
  font-size: 1.25rem;
  margin-bottom: 0;
  padding-right: 1.25rem;
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
}

.accordion-item-trigger-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  -webkit-transform: translateY(-2px) rotate(45deg);
  transform: translateY(-2px) rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.is-active .accordion-item-trigger-icon {
  -webkit-transform: translateY(2px) rotate(225deg);
  transform: translateY(2px) rotate(225deg);
}

.accordion-item-details {
  overflow: hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  -webkit-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>

/**
 * @see https://github.com/albertodeago/vue-virtualized-list
 */

const Slot = {
  name: 'VirtualListSlot',

  props: {
    tag: {
      type: String,
      default: 'div',
    },

    uniqueKey: {
      type: String,
      default: String,
    },
  },

  render(h) {
    const { tag } = this

    return h(tag, this.$slots.default)
  },
}

export default {
  name: 'VirtualizedList',

  props: {
    dReduceHeight: Boolean,
    /**
     * The type of element of the header
     */
    headerContainerEl: {
      type: String,
      default: 'div',
    },

    /**
     * The class of element of the header container
     */
    headerContainerClass: {
      type: String,
      default: '',
    },

    /**
     * The type of element of the outer container
     */
    outerContainerEl: {
      type: String,
      default: 'div',
    },

    /**
     * The class of element of the outer container
     */
    outerContainerClass: {
      type: String,
      default: 'vue-virtualized-list__scroll',
    },

    /**
     * The type of element of the outer container
     */
    scrollSizerEl: {
      type: String,
      default: 'div',
    },

    /**
     * The class of element of the outer container
     */
    scrollSizerClass: {
      type: String,
      default: 'relative virtual-list',
    },

    /**
     * The type of element of the inner container
     */
    innerContainerEl: {
      type: String,
      default: 'div',
    },

    /**
     * The type of element of the block container
     * The block container can be user for non scrollable elemnt such as table
     */
    blockContainerEl: {
      type: String,
      default: 'div',
    },

    /**
     * The class of element of the block container
     */
    blockContainerClass: {
      type: String,
      default: '',
    },

    /**
     * The class of element of the inner container
     */
    innerContainerClass: {
      type: String,
      default: 'vue-virtualized-list',
    },

    /**
     * The type of element of the item container
     */
    itemContainerEl: {
      type: String,
      default: 'div',
    },

    items: {
      type: Array,
      required: true,
    },
    itemHeight: {
      type: Number,
      required: true,
    },

    /**
     * Indicates the amount of elements not visible to render. They are kind of useful
     * if the user scrolls very fast or in similar cases.
     * In my tests 5 seems to be ideal most of the times
     */
    bench: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      firstItemToRender: null, // index of the first item to render
      lastItemToRender: null, // index of the last item to render
      scrollTop: 0, // current scrolltop offset of the scrollable container
    }
  },

  computed: {
    firstToRender() {
      return Math.max(0, this.firstItemToRender - this.bench)
    },

    lastToRender() {
      return Math.min(this.items.length, this.lastItemToRender + this.bench)
    },
  },

  watch: {
    /**
     * If the height of the items changes we need to recalculate the visible items and
     * re-render if needed
     */
    itemHeight() {
      this.update()
    },
  },

  /**
   * Setup the initial state and attach the scroll listener
   */
  mounted() {
    this.firstItemToRender = 0
    this.lastItemToRender = Math.floor(this.$el.clientHeight / this.itemHeight)

    this.$el.addEventListener('scroll', this.onScroll, false)
  },

  methods: {
    /**
     * Triggers an update.
     * Fake a scroll to recalculate the visible items
     */
    update() {
      this.$nextTick(() => {
        this.onScroll({
          target: {
            scrollTop: this.scrollTop,
          },
        })
      })
    },

    /**
     * @param evt - the scroll event
     */
    onScroll(evt) {
      this.scrollTop = evt.target.scrollTop
      this.firstItemToRender = Math.floor(this.scrollTop / this.itemHeight)
      this.lastItemToRender =
        this.firstItemToRender +
        Math.ceil(this.$el.clientHeight / this.itemHeight)
    },

    /**
     * Return the VNode of the elements to render
     * Recover the index of the wanted nodes to render base on the position
     * @param {Function} h - Vue render function
     */
    getRenderedItems(h) {
      const toRender = this.items.slice(this.firstToRender, this.lastToRender)
      return toRender.map((item, i) =>
        h(
          this.itemContainerEl,
          {
            style: {
              // Need to remove row item transformation as table (the container) do not scroll anymore
              // transform: `translateY(${Math.max(0, ((this.firstToRender + i) * this.itemHeight) - (this.itemHeight * i))}px)`
            },
            class: [
              { highlight: !!item.highlight },
              item.highlight,
              { disabled: item.disabled },
            ],
          },
          this.$scopedSlots.default(item)
        )
      )
    },
  },

  render(h) {
    const { tableHeader, colgroup, header } = this.$slots

    const list = this.getRenderedItems(h)

    // The list to be rendered
    const renderScroll = h(
      this.innerContainerEl,
      {
        class: this.innerContainerClass,
      },
      list
    )

    // The main container of the virtual element
    // This contaner is the scroll canvas
    const renderList = h(
      this.outerContainerEl,
      {
        class: this.outerContainerClass,
        style: {
          height: '100%',
          overflow: 'auto',
          position: 'relative',
          display: 'block',
        },
      },
      [
        // Create a height definer element that will define the size on the croll canvas
        h(
          Slot,
          {
            class: this.scrollSizerClass,
            style: Object.assign({
              // Define the scroll height
              // FIXME: Debug reduce height
              // Why did I need to reduce the height ?
              height: `${
                this.items.length * this.itemHeight -
                (this.dReduceHeight ? list.length * this.itemHeight : 0)
              }px`,
              minHeight: '70vh',
            }),
            props: { tag: this.scrollSizerEl },
          },
          [
            // To apply virtual scroll on non scrollable element
            // We use a slot that with represent our non scrollable element
            h(
              Slot,
              {
                class: this.blockContainerClass,
                props: {
                  tag: this.blockContainerEl,
                  uniqueKey: this.blockContainerEl,
                },
              },
              [
                // table-header slot
                tableHeader || null,

                // colgroup slot
                colgroup
                  ? h(
                      Slot,
                      {
                        props: {
                          tag: 'colgroup',
                          uniqueKey: 'colgroup',
                        },
                      },
                      colgroup
                    )
                  : null,

                // header slot
                header
                  ? h(
                      Slot,
                      {
                        class: this.headerContainerClass,
                        props: {
                          tag: this.headerContainerEl,
                          uniqueKey: this.headerContainerEl,
                        },
                      },
                      header
                    )
                  : null,

                // tbody slot
                renderScroll,
              ]
            ),
          ]
        ),
      ]
    )
    return renderList
  },
}

<template>
  <div v-show="isActive">
    <component
      :is="component"
      v-if="!disabled"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'ProdigeTab',
  props: {
    state: {
      type: String,
      default: 'pending',
      validator: (value) =>
        ['pending', 'current', 'done'].indexOf(value) !== -1,
    },
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    component: {
      type: Object,
    },
  },
  data() {
    return {
      isActive: false,
      isDisabled: false,
    }
  },
  computed: {
    href() {
      return this.slugify(this.name.toLowerCase())
    },
  },
  watch: {
    disabled(newValue, oldValue) {
      this.isDisabled = newValue
    },
  },
  mounted() {
    this.isActive = this.selected
    this.isDisabled = this.disabled
  },
  methods: {
    slugify(str) {
      let slug = str.replace(/^\s+|\s+$/g, '')
      slug = slug.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaeeeeiiiioooouuuunc-------'
      for (let i = 0, l = from.length; i < l; i += 1) {
        slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      slug = slug
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return slug
    },
  },
}
</script>

<style scoped></style>

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const view = vm
      if (!view.previousListPage) {
        view.previousListPage = from
      }
    })
  },
  data() {
    return {
      previousListPage: null,
    }
  },
  methods: {
    returnToList(name) {
      // If the previous page name is not defined
      // Return the the given page with name
      if (this.previousListPage && this.previousListPage.name) {
        return this.$router.push(this.previousListPage)
      }
      return this.$router.push({ name })
    },
  },
}

import { render, staticRenderFns } from "./prodige-button.vue?vue&type=template&id=52bee794&scoped=true&"
import script from "./prodige-button.vue?vue&type=script&lang=js&"
export * from "./prodige-button.vue?vue&type=script&lang=js&"
import style0 from "./prodige-button.vue?vue&type=style&index=0&id=52bee794&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../unilogin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bee794",
  null
  
)

export default component.exports
<template>
  <button class="prodige-button" :type="type" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ProdigeButton',
  props: {
    type: {
      type: String,
      default: 'submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.prodige-button {
  border: none;
  transition: all 0.3s ease;
  font-size: inherit;
  @apply bg-gray-200;
  @apply font-medium;
  @apply py-1;
  @apply px-4;
  @apply rounded-md;
  @apply font-poppins-medium;
  @apply text-sm;
  cursor: pointer;
}
.prodige-button:hover {
  background: rgba(235, 235, 235, 0.8);
  cursor: pointer;
}
/* Primary button */
.prodige-button.primary {
  @apply bg-orange;
  @apply text-white;
  @apply cursor-pointer;
}

/* Secondary button */
.prodige-button.secondary {
  @apply bg-white;
  @apply text-orange;
  @apply border-solid;
  @apply border-2;
  @apply border-orange;
}

/* Third button */
.prodige-button.third {
  @apply bg-white;
  @apply text-purple;
  @apply border-solid;
  @apply border-2;
  @apply border-purple;
}

.prodige-button.btn.primary:hover,
.prodige-button.btn.secondary:hover,
.prodige-button.btn.third:hover {
  opacity: 0.8;
}

.prodige-button:focus {
  outline: none;
}
/* Danger button */
.prodige-button.danger {
  @apply bg-red;
  @apply text-white;
}
.prodige-button.danger:hover {
}
/* Disabled state */
.prodige-button:disabled {
  @apply cursor-default opacity-50;
}
</style>

<template>
  <section id="prodige-export">
    <button
      class="bg-white rounded-t-md pt-3 pb-3 pl-2 pr-2 flex items-center text-sm text-ml-grey ml-5"
      type="button"
      @click="openModalExport"
    >
      <svg
        class="mr-2"
        width="16"
        height="15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M9.35.381A.313.313 0 009.085.32L.96 2.194a.313.313 0 00-.242.307v10.625c0 .155.112.286.265.309l8.125 1.25a.312.312 0 00.36-.309V.626A.312.312 0 009.35.381z"
            fill="#4CAF50"
          />
          <path
            d="M15.405 13.439h-6.25a.313.313 0 110-.625h5.938V2.189H9.155a.313.313 0 010-.625h6.25c.173 0 .313.14.313.312v11.25c0 .173-.14.313-.313.313z"
            fill="#4CAF50"
          />
          <path
            d="M6.655 10.314a.313.313 0 01-.265-.147l-3.125-5a.313.313 0 11.53-.33l3.125 5a.312.312 0 01-.265.477z"
            fill="#FAFAFA"
          />
          <path
            d="M3.53 10.314a.312.312 0 01-.264-.478l3.125-5a.312.312 0 11.53.33l-3.125 5a.312.312 0 01-.266.148z"
            fill="#FAFAFA"
          />
          <path
            d="M11.655 13.439a.312.312 0 01-.312-.313V1.876a.313.313 0 11.625 0v11.25c0 .173-.14.313-.313.313z"
            fill="#4CAF50"
          />
          <path
            d="M15.405 11.564h-6.25a.313.313 0 110-.625h6.25a.312.312 0 110 .625zM15.405 9.689h-6.25a.313.313 0 010-.625h6.25a.313.313 0 010 .625zM15.405 7.814h-6.25a.313.313 0 010-.625h6.25a.313.313 0 010 .625zM15.405 5.939h-6.25a.313.313 0 010-.625h6.25a.313.313 0 010 .625zM15.405 4.064h-6.25a.313.313 0 010-.625h6.25a.313.313 0 010 .625z"
            fill="#4CAF50"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" transform="translate(.718)" d="M0 0h15v15H0z" />
          </clipPath>
        </defs>
      </svg>
      {{ $t('text.exportTable') }}
    </button>

    <modal name="export">
      <prodige-card class="w-1/2">
        <template slot="header">
          <h2 class="px-6 py-4 font-semibold">
            {{ $t('exportSearchResult.title.modal') }}
          </h2>
        </template>
        <template>
          <div class="px-6 py-4">
            <div class="w-full flex py-4">
              <ab-input
                v-model="exportData.filename"
                :label="$t('exportSearchResult.text.filename')"
                :error="$v.exportData.filename.$error"
                type="text"
                required
              />
              <p
                v-if="
                  $v.exportData.filename.$error &&
                  !$v.exportData.filename.required
                "
                class="err-msg"
              >
                {{ $t('error.requiredField') }}
              </p>
            </div>
            <div class="w-full flex">
              <prodige-draggable
                v-model="exportData.local_fields"
                :source="availableFields"
                label-key="label"
                search-key="label"
                value-key="key"
                :auto-fill-source="false"
                :source-label="$t('exportSearchResult.text.fieldsIncluded')"
                :destination-label="
                  $t('exportSearchResult.text.FieldsNotIncluded')
                "
              ></prodige-draggable>
              <p
                v-if="
                  $v.exportData.local_fields.$error &&
                  !$v.exportData.local_fields.required
                "
                class="err-msg"
              >
                {{ $t('error.requiredField') }}
              </p>
            </div>
            <div class="flex justify-end mt-6">
              <prodige-button
                class="primary mr-4"
                @click.native="closeModalExport"
              >
                {{ $t('action.cancel') }}
              </prodige-button>
              <prodige-button
                class="secondary"
                @click.native="exportSearchResult"
              >
                {{ $t('exportSearchResult.title.btn') }}
              </prodige-button>
            </div>
          </div>
        </template>
      </prodige-card>
    </modal>
  </section>
</template>

<script>
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { AbInput } from '@ab/material-components'
import ProdigeDraggable from '../draggable/prodige-draggable.vue'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'ProdigeExportButton',
  components: {
    AbInput,
    ProdigeDraggable,
  },
  props: {
    associations: {
      type: Array,
    },
    filters: {
      type: Array,
    },
    model: null,
    excludeModel: {
      type: Array,
    },
    orders: {
      type: Array,
    },
  },
  data() {
    return {
      fields: [],
      exportData: {
        local_fields: [],
        filename: null,
      },
    }
  },
  validations: {
    exportData: {
      local_fields: { required },
      filename: { required },
    },
  },
  computed: {
    availableFields() {
      return this.fields
    },
  },
  watch: {
    async associations() {
      this.getFields()
    },
    async model() {
      this.getFields()
    },
  },
  mounted() {
    this.exportData.filename = this.model
  },
  methods: {
    async getFields() {
      const data = await this.$store.dispatch('exportSearchResult/getFields', {
        model: this.model,
        associations: this.associations,
        excludeModel: this.excludeModel,
      })
      const exportData = data.map((d) => ({
        ...d,
        label: `${this.$t(
          `field.modelName.${d.path.replaceAll('.', '_')}`
        )} - ${this.$t(`field.field.${d.field}`)}`,
      }))
      this.fields = exportData
    },
    openModalExport() {
      // affichage liste champs modal
      this.$modal.show('export')
    },
    closeModalExport() {
      this.$modal.hide('export')
    },
    async exportSearchResult() {
      this.startValidation('exportData')
      this.startForm()
      if (this.$v.exportData.$error) {
        this.$store.dispatch('addMessage', {
          key: 'exportSearchResultError',
          params: {
            display: 'exportSearchResult.error.formVerificationError',
            error: true,
          },
        })
        this.errorForm()
        return
      }
      let fileName = this.$t(this.exportData.filename).replaceAll(' ', '_')
      const currentDate = format(new Date(), 'd/M/yyyy-HH:mm:ss', {
        locale: fr,
      })
      fileName = `${fileName}_${currentDate}`
      const buffer = await this.$store.dispatch('exportSearchResult/export', {
        model: this.model,
        associations: this.associations,
        fields: this.exportData.local_fields,
        filters: this.filters,
        orders: this.orders,
      })
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${buffer}`
      const downloadLink = document.createElement('a')
      downloadLink.download = `${fileName}.xlsx`
      downloadLink.href = linkSource
      downloadLink.target = '_blank'
      downloadLink.click()
      this.endForm()
    },
  },
}
</script>

<style scoped></style>

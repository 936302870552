<template>
  <section>
    <prodige-card class="p-6 relative">
      <header>
        <nav aria-label="Progress" class="relative">
          <span class="absolute h-0.5 top-4 left-16 right-16 bg-gray-200" />
          <ol class="flex justify-between items-start text-center -mb-6">
            <prodige-step-item
              v-for="(step, idx) in currentSteps"
              v-show="!step.disabled"
              :key="idx"
              :state="step.state"
              :href="step.href"
              :name="step.name"
            />
          </ol>
        </nav>
      </header>
    </prodige-card>

    <slot name="header" />

    <main class="mt-12">
      <slot />
    </main>
  </section>
</template>

<script>
import ProdigeStepItem from './prodige-step-item.vue'
import step from '../../../mixins/step.js'
import ProdigeCard from '../../cards/prodige-card.vue'

export default {
  name: 'ProdigeSteps',
  components: {
    ProdigeStepItem,
    ProdigeCard,
  },
  mixins: [step],
  props: {
    lastSavedStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      steps: [],
    }
  },
  computed: {
    currentSteps() {
      return this.steps
    },
    availableSteps() {
      return this.steps.filter((step) => !step.disabled)
    },
  },
  watch: {
    // If the step query param change select the corresponding step
    '$route.query.step': function handlestepChange() {
      const { step } = this.$route.query
      this.selectstep(step)
      // this.hideSpinner('default', 0);
    },
    lastSavedStep() {
      if (
        this.availableSteps.length &&
        this.lastSavedStep <= this.availableSteps.length
      ) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              step: this.availableSteps[Math.max(0, this.lastSavedStep - 1)]
                .href,
            },
          })
          .catch(() => {})
      } else {
        this.$router
          .replace({
            query: { ...this.$route.query, step: this.availableSteps[0].href },
          })
          .catch(() => {})
      }
    },
  },
  mounted() {
    // To prenvent from getting all the children components using the $children property
    // We use the $slots property to access vnodes, which are direct child of the current component slot
    // This way we can iterate over vnodes and recovent the component inside
    // @see https://vuejs.org/v2/guide/render-function.html#VNodes-Must-Be-Unique
    this.$nextTick(this.initSteps)

    this.EventBus.$on('next-step', this.selectNextStep)
    this.EventBus.$on('updated-data', this.initSteps)
  },
  methods: {
    async initSteps() {
      const slots = this.$slots.default
      this.steps = slots.map((slot) => {
        return {
          name: slot.child.name,
          href: this.slugify(slot.child.name),
          disabled: slot.child.disabled,
          state: 'pending',
          component: slot.child,
        }
      })
      if (
        this.availableSteps.length &&
        this.lastSavedStep <= this.availableSteps.length
      ) {
        this.$router
          .replace({
            query: {
              ...this.$route.query,
              step: this.availableSteps[Math.max(0, this.lastSavedStep - 1)]
                .href,
            },
          })
          .catch(() => {})
      } else if (!this.$route.query.step) {
        this.$router
          .replace({
            query: { ...this.$route.query, step: this.availableSteps[0].href },
          })
          .catch(() => {})
      }

      // If their is aleady a defined step in the URL
      // Define the correspinding step as active
      if (this.$route.query.step) {
        this.selectstep(this.$route.query.step)
      }
    },
    getCurrentStep() {
      return this.steps.findIndex(
        (step) => step.href === this.$route.query.step
      )
    },
    selectNextStep() {
      const idx = this.getCurrentStep()
      if (idx > -1) {
        const currentStep = this.availableSteps[idx]
        currentStep.state = 'current'

        const nextStep = this.availableSteps[idx + 1]
        if (nextStep) {
          this.$router
            .push({ query: { ...this.$route.query, step: nextStep.href } })
            .catch(() => {})
        } else {
          // End step ...
        }
      }
    },
    selectstep(currentStep) {
      this.availableSteps.forEach((step, idx) => {
        /* eslint-disable no-param-reassign */
        step.component.isActive = step.href === currentStep
        if (step.component.isActive) {
          step.state = 'current'
        } else if (
          idx <= this.lastSavedStep - 1 &&
          idx !== this.lastSavedStep - 1
        ) {
          step.state = 'done'
        }
      })
    },
    navigate(step) {
      if (
        this.$route.query.step !== step.href &&
        !step.disabled &&
        !this.$route.query.step
      ) {
        this.$router.push({ query: { step: step.href } }).catch(() => {})
      }
    },
  },
}
</script>

<style scoped>
/* li {
  @apply flex-1;
}
li:last-child {
  @apply flex-grow-0;
} */
</style>

<template>
  <th
    class="prodige-th uppercase text-md font-poppins-medium text-dark-grey font-medium py-3 px-6 text-left border-b select-none whitespace-no-wrap"
    :class="`text-${position} ${
      sortable && name ? 'sortable cursor-pointer' : 'cursor-default'
    }`"
    tabindex="0"
    @click="handleSort"
  >
    <span><slot /></span>
    <div
      v-if="sortable"
      class="inline-block w-4 ml-2 align-middle text-orange"
      :class="
        sort.sortDir === 'desc'
          ? 'transform rotate-180 transition-transform ease-linear duration-100'
          : ''
      "
    >
      <svg
        v-if="sort.colSorted === name && sort.sortDir"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
  </th>
</template>

<script>
export default {
  name: 'ProdigeDataTh',
  props: {
    sort: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'right'].includes(val),
    },
  },
  data() {
    return {
      sorted: '',
    }
  },
  methods: {
    handleSort() {
      if (this.sortable) {
        this.sort.sortBy(this.name)
      }
    },
  },
}
</script>

<style scoped>
.sortable:hover {
  @apply underline;
}
th:focus {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: underline;
  outline: none;
}
</style>

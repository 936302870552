<template>
  <td class="px-6 py-4" :class="`align-${align} text-${textAlign}`">
    <div class="text-sm leading-5" :class="textColor">
      <slot />
    </div>
    <div class="text-sm leading-5 text-gray-500">
      <slot name="subline" />
    </div>
    <div class="text-xs leading-5 text-gray-500">
      <slot name="subline-xs" />
    </div>
  </td>
</template>

<script>
export default {
  name: 'ProdigeTd',
  props: {
    align: {
      type: String,
      default: 'top',
      validator: (val) =>
        [
          'baseline',
          'top',
          'middle',
          'bottom',
          'text-top',
          'text-bottom',
        ].includes(val),
    },
    textAlign: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'center', 'right'].includes(val),
    },
    textColor: {
      type: String,
      default: 'text-dark-grey',
    },
  },
}
</script>

<style scoped></style>

<template>
  <section class="prodige-card bg-white rounded-lg shadow pt-2 pb-6">
    <header v-if="hasHeader" class="border-b leading-3 px-6">
      <slot name="header" />
    </header>
    <main class="px-6 py-4">
      <slot />
    </main>
    <footer v-if="hasFooter" class="px-6 mt-6">
      <slot name="footer" />
    </footer>
  </section>
</template>

<script>
export default {
  name: 'ProdigeCard',
  computed: {
    hasHeader() {
      return !!this.$slots.header
    },
    hasFooter() {
      return !!this.$slots.footer
    },
  },
}
</script>

<style scoped></style>

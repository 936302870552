export default {
  login: 'Login',
  home: 'Tableau de bord',
  setting: 'Gestion des paramètres',
  product: 'Gestion Produit',
  litigation: 'Litiges',
  revenueRequest: "Demande de chiffre d'affaires",
  revenueResponse: "Résultat chiffre d'affaires",
  tender: "Appel d'Offres",
  tenderPM: "Appel d'offre MP",
  synthesisCreation: 'Création document de Synthèse',
  selectFamilies: 'Sélection Famille',
  selectAMO: 'Sélection des adhérents AMO',
  statisticRequest: 'Gestion de demandes de statistiques',
  publicNotice: 'Avis marché public',
  usermanagement: 'Gestion Utilisateurs',
  catalogs: 'Catalogues',
  referencement: 'Référencement',
  attributionPM: 'Attribution marché public',
  orderLevel: 'Gestion des niveaux de commande',
  catalogProductService: 'Catalogues Produits et Services',
  cartInProgress: 'Panier en préparation',
  waintingOrder: "Suivi d'envoi des commandes",
  buyingHabitsList: "Habitudes d'achats",
  receivedOrder: 'Commandes réceptionnées',
  standardCartList: 'Paniers type',
  purchase: 'Achats',
  AdminCartTemplateList: 'Admin Panier type',
  AdminCartTemplateCreate: 'Création panier type',
  notice: 'Boite de notifications',
  exports: 'Exports',
  message: 'Gestion des messages',
  messageCreate: 'Nouveau message',
  messageEdit: 'Edition de message',
  stock: 'Stock',
  EntryVoucher: "Bon d'entrée",
  EntryVoucherDetail: "Bon d'entrée N° {id}",
  EntryVoucherDetailCreate: "Nouveau bon d'entrée",
  ExitVoucher: 'Bon de sortie',
  ExitVoucherDetail: 'Bon de sortie N° {id}',
  ExitVoucherDetailCreate: 'Nouveau bon de sortie',
  inventory: 'Stock - Inventaire',
  priceUpdate: 'Mise à jour cotation',
}

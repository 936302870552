import {
  getUsers,
  updateUser,
  getUserData,
  createUser,
  getProjectManagers,
  getUserPerimeter,
  getValidatorUsers,
  getUserFamilies,
  getUserMenu,
} from '../api/userManagement.js'

import { getAllStatus } from '../api/status.js'

export default {
  state: {
    users: [],
    users_counter: 0,
    user: {},
    user_status: [],
    projectManagers: [],
    userPerimeter: {},
    validators: [],
    families: [],
    isValidator: false,
    newEntity: null,
    userMenu: [],
  },
  mutations: {
    getUsers(state, users) {
      state.users = users.rows
      state.users_counter = users.count
    },
    setUser(state, user) {
      state.user = user
    },
    getAllUserStatus(state, user_status) {
      state.user_status = user_status
    },
    updateUser(state, user) {
      state.newEntity = user
    },
    createUser(state, user) {
      state.user = user
    },
    getProjectManagers(state, projectManagers) {
      state.projectManagers = projectManagers
    },
    getUserPerimeter(state, perimeter) {
      state.userPerimeter = perimeter
    },
    getValidatorUsers(state, validators) {
      state.validators = validators
    },
    getIsValidator(state, isValidator) {
      state.isValidator = isValidator
    },
    getUserFamilies(state, families) {
      state.families = families
    },
    getUserMenu(state, menu) {
      state.userMenu = menu
    }
  },
  actions: {
    async getUsers({ commit }, { offset, limit }) {
      const data = await getUsers(offset, limit)
      if (data) {
        commit('getUsers', data)
      }
      return data
    },
    async getUser({ commit }, id) {
      const data = await getUserData(id)
      if (data) {
        commit('setUser', data)
      }
      return data
    },
    async getAllUserStatus({ commit }) {
      const data = await getAllStatus()
      if (data) {
        commit('getAllUserStatus', data)
      }
      return data
    },
    async updateUser({ commit }, user) {
      const data = await updateUser(user)
      if (data) {
        commit('updateUser', data)
      }
      return data
    },
    async createUser({ commit }, user) {
      const data = await createUser(user)
      if (data) {
        commit('createUser', data)
      }
      return data
    },
    async getProjectManagers({ commit }) {
      const data = await getProjectManagers()
      if (data) {
        commit('getProjectManagers', data)
      }
      return data
    },
    async getUserPerimeter({ commit }) {
      const data = await getUserPerimeter()
      if (data) {
        commit('getUserPerimeter', data)
      }
      return data
    },
    async getValidatorUsers({ commit }, { adherents, userId }) {
      const data = await getValidatorUsers({ adherents, userId })
      if (data) {
        commit('getValidatorUsers', data.validators)
        commit('getIsValidator', data.isValidator)
      }
      return data
    },
    async getUserFamilies({ commit }) {
      const data = await getUserFamilies()
      if (data) {
        commit('getUserFamilies', data)
      }
      return data
    },
    async getUserMenu({ commit }) {
      const data = await getUserMenu()
      if (data) {
        commit('getUserMenu', data)
      }
      return data
    }
  },
}

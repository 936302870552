<template>
  <section class="prodige-tooltip">
    <div ref="tooltip" class="container">
      <div class="btn" @mouseover="toggle">
        <slot name="container" />
        <div v-show="focus" class="tooltip-more" :style="widthClass">
          <slot name="text" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProdigeTooltip',
  props: {
    actions: {
      type: Array,
      default: null,
    },
    width: [String, Number],
  },
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    widthClass() {
      return this.width ? `width: ${Number(this.width)}px` : ''
    },
  },
  created() {
    document.addEventListener('mouseover', this.documentFocus)
  },
  destroyed() {
    document.removeEventListener('mouseover', this.documentFocus)
  },
  methods: {
    toggle() {
      this.focus = true
    },
    documentFocus(e) {
      const el = this.$refs.tooltip
      const { target } = e
      if (el !== target && !el.contains(target)) {
        this.focus = false
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  cursor: pointer;
}
/* Button 'more' */
.btn {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
}

/* tooltip */
.tooltip-more {
  position: absolute;
  padding: 0.5rem;
  top: 100%;
  /* background: #F5F5F5; */
  border-radius: 5px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  z-index: 999;
}
.tooltip-center .tooltip-more {
  margin-left: -50%;
}
.tooltip-right .tooltip-more {
  right: 25%;
}
.tooltip-more:focus {
  outline: none;
}
/* Arrow */
.tooltip-more:after {
  position: absolute;
  content: ' ';
  bottom: 100%;
  border: solid transparent;
  border-width: 0.5rem;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: var(--app-white-color);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  margin-left: -0.7rem;
  height: 0;
  width: 0;
  pointer-events: none;
}
.tooltip-center .tooltip-more:after {
  left: 50%;
}
.tooltip-right .tooltip-more:after {
  right: 0.25rem;
}
/* List */
.prodige-tooltip .item {
  display: block;
  width: 100%;
  padding: 0.75rem;
}
</style>

export default {
  data() {
    return {}
  },
  methods: {
    limitText(count) {
      return `et ${count} ${count === 1 ? 'autre' : 'autres'}`
    },
    // Create an array containing a single object with the groupe label and the group elemments
    createGroupOptions(label, options) {
      if (options.length) {
        return [
          {
            label,
            options,
          },
        ]
      }
      return []
    },
  },
}

<template>
  <div
    class="prodige-table relative flex flex-col"
    :class="roundedTop ? 'corner-top-left corner-top-right' : ''"
  >
    <div
      v-if="spinner.loading"
      class="absolute bg-white rounded-lg inset-0 opacity-75 z-30"
    >
      <prodige-loader
        class="absolute rounded-lg h-32 z-50 opacity-75"
        name="table"
      />
    </div>
    <div
      class="table-ctn-1 relative -my-2"
      :class="roundedTop ? 'corner-top-left corner-top-right' : ''"
    >
      <div
        class="table-ctn-2 relative py-2 align-middle inline-block min-w-full"
        :class="roundedTop ? 'corner-top-left corner-top-right' : ''"
      >
        <div
          v-if="!virtual"
          :class="[
            ctn3Classes,
            roundedTop ? 'corner-top-left corner-top-right' : '',
          ]"
        >
          <table
            class="min-w-full"
            :class="{ striped, 'rounded-top': roundedTop }"
          >
            <slot name="table-header" />
            <colgroup>
              <slot name="colgroup" />
            </colgroup>
            <thead
              class="border-b border-gray-200"
              :class="roundedTop ? 'corner-top-left corner-top-right' : ''"
              v-on="$listeners"
            >
              <tr>
                <slot name="thead" />
              </tr>
            </thead>
            <tbody v-if="!removeTbody" class="divide-y divide-gray-200">
              <slot />
            </tbody>
            <template v-else>
              <slot />
            </template>
            <tfoot>
              <slot name="tfoot" />
            </tfoot>
          </table>
        </div>

        <template v-else>
          <slot name="vtable" />
        </template>

        <div class="pagination">
          <slot name="pagination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProdigeTable',
  props: {
    striped: {
      type: Boolean,
      default: false,
    },
    roundedTop: {
      type: Boolean,
      default: true,
    },
    virtual: {
      type: Boolean,
      default: false,
    },
    removeTbody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ctn3Classes:
        'table-ctn-3 relative shadow border-b border-gray-200 corner-bottom-left corner-bottom-right',
    }
  },
  computed: {
    ...mapState({
      spinner(state) {
        if (state.loader.spinner && state.loader.spinner.table) {
          return state.loader.spinner.table
        }
        return { loading: false }
      },
    }),
  },
  mounted() {
    if (!this.$store.state.loader.spinner.table) {
      this.$store.commit('registerSpinner', 'table')
    }
  },
}
</script>

<style>
/* Rounded top */
.corner-top-left {
  border-top-left-radius: 0.5rem;
}
.corner-top-right {
  border-top-right-radius: 0.5rem;
}
table {
  @apply w-full;
}
table.rounded-top th:first-child {
  border-top-left-radius: 0.5rem;
}
table.rounded-top th:last-child {
  border-top-right-radius: 0.5rem;
}
/* Rounded bottom */
.corner-bottom-left {
  border-bottom-left-radius: 0.5rem;
}
.corner-bottom-right {
  border-bottom-right-radius: 0.5rem;
}
table tbody,
table tr:last-child,
table tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}
table tbody,
table tr:last-child,
table tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}

table tr {
  @apply bg-white;
}
/* Striped */
table.striped tbody tr:nth-child(even) {
  @apply bg-light-grey;
  box-shadow: inset 0px 6px 18px rgba(0, 0, 0, 0.06);
}

/* Highligh active row */
table tr:hover,
table tr.active {
  @apply relative bg-gray-50 cursor-pointer;
}

table tr td:first-child {
  position: relative;
}
table tr td:first-child::before {
  content: '';
  transform: scale(0);
  transition-duration: 0.1s;
  @apply absolute left-0 top-0 w-2 h-full bg-orange rounded-r-lg transition-transform ease-in-out;
}
table tr:hover td:first-child::before,
table tr.active td:first-child::before {
  transform: scale(1);
}

/* Overflow behavior */
.prodige-table .table-ctn-1,
.prodige-table .table-ctn-2 {
  width: 100%;
  max-width: 100%;
}
.prodige-table .table-ctn-3 {
  max-width: 100%;
  width: 100%;
  overflow: auto;
}
</style>

import MiniLogo from '../assets/images/logo/logo-mini.svg'

import DarkLogo from '../assets/images/logo/Logo_eValae_dark.svg'
import WhiteLogo from '../assets/images/logo/Logo_eValae_white.svg'

// Tenders
import AO from '../assets/images/logo/ao.svg'

// Adherents
import Adherents from '../assets/images/logo/adherents.svg'

const Mini = {
  MiniLogo,
}

export default {
  computed: {
    appLogo() {
      return { DarkLogo, WhiteLogo, MiniLogo }
    },
  },
}

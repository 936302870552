<template>
  <div class="mb-6">
    <header class="relative">
      <h1 class="titre-page">
        <ab-icon
          v-if="$route.meta.icon"
          class="text-purple"
          :icon="$route.meta.icon"
          iconset="icons"
        />
        <slot />
      </h1>
      <div class="line" />
      <div class="absolute right-0 top-0 text-sm font-poppins">
        <slot name="buttons" />
      </div>
    </header>
    <div
      v-if="returnButton"
      class="mt-4 text-purple text-base flex items-center cursor-pointer"
      @click="
        returnPath && returnPath.name
          ? $router.push(returnPath)
          : $router.go(-1)
      "
    >
      <ab-icon class="text-purple" icon="back" />
      {{ $t('action.back') }}
    </div>
  </div>
</template>

<script>
import { AbIcon } from '@ab/material-components'

export default {
  name: 'ProdigeTitle',
  components: {
    AbIcon,
  },
  props: {
    returnButton: {
      type: Boolean,
      default: false,
    },
    routeNameButton: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    returnPath() {
      // Return following routeNameButton type
      if (this.routeNameButton && this.routeNameButton.name)
        return this.routeNameButton
      if (this.routeNameButton) return { name: this.routeNameButton }
      return null
    },
  },
}
</script>

<style scoped>
.titre-page {
  border-bottom: 1px solid;
  z-index: 1;
  @apply relative text-purple inline-block text-xl pb-3 border-purple pr-8;
}

.titre-page + .line {
  height: 1px;
  @apply bg-line-grey-td-left-right bottom-0 absolute w-full;
}
</style>

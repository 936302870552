import { uploadFile, uploadFile2, downloadFile, getFile } from '../api/file.js'

export default {
  state: {
    files: [],
    newEntity: null,
  },

  mutations: {
    uploadFile(state, file) {
      state.newEntity = file
    },
    uploadFile2(state, file) {
      state.newEntity = file
    },
  },
  actions: {
    async uploadFile({ commit }, fileData) {
      const data = await uploadFile(fileData)
      if (data !== false) {
        commit('uploadFile', data)
      }
      return data
    },
    async uploadFile2({ commit }, fileData) {
      const data = await uploadFile2(fileData)
      if (data !== false) {
        commit('uploadFile2', data)
      }
      return data
    },
    async downloadFile({ commit }, file_id) {
      const data = await downloadFile(file_id)
      return data
    },
    async getFile({ commit }, file_id) {
      const data = await getFile(file_id)
      return data
    },
  },
}

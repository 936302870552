<template>
  <div
    class="flex bg-white border-l-8 border-purple rounded-md p-6 relative mb-8 shadow-containerShadow"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ProdigeDetailHead',
}
</script>

<style scoped></style>

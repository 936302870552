import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.authentication.user,
    }),

    isCurrentUserAdherent() {
      return (
        this.user.Profile &&
        this.user.Profile.Profile_type &&
        this.user.Profile.Profile_type.machine_name ===
          this.constants.profilType.ADHERENT
      )
    },
    isCurrentUserSupplier() {
      return (
        this.user.Profile &&
        this.user.Profile.Profile_type &&
        this.user.Profile.Profile_type.machine_name ===
          this.constants.profilType.SUPPLIER
      )
    },
    isCurrentUserBackoffice() {
      return (
        this.user.Profile &&
        this.user.Profile.Profile_type &&
        this.user.Profile.Profile_type.machine_name ===
          this.constants.profilType.VALAE
      )
    },
    isCurrentAppFrontOffice() {
      return process.env.VUE_APP_NAME && this.constants.apps.FRONT_OFFICE
    },
  },
  methods: {
    isSoluceo(machine_name) {
      return machine_name === this.constants.companyTypes.PRIVATE
    },
  },
}

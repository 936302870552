export default {
  address: 'Adresse',
  information: 'Information',
  firstname: 'Prénom',
  lastname: 'Nom',
  email: 'Email',
  city: 'Ville',
  department: 'Département',
  country: 'Pays',
  sameAddress: 'Identique adresse facturation',
  siret: 'Siret',
  apeCode: 'Code Ape',
  tvaNumber: 'N° TVA',
  website: 'Site internet',
  phone: 'Téléphone',
  profile: 'Profil',
  postalCode: 'Code postal',
  quality: 'Qualité',
  password: 'Mot de passe',
  oldPassword: 'Mot de passe actuel',
  confirm: 'Confirmation',
  perimeter: 'Périmètre',
  adherentGroup: "Groupe d'adhérents",
  newPassword: 'Nouveau mot de passe',
  adherent: 'Adhérents',
  noGroup: "Aucun Groupe d'adhérents",
  noAdherent: 'Aucun Adhérent',
  supplier: 'Fournisseurs',
  noSupplier: 'Aucun Fournisseurs',
  noCatalog: 'Aucun Catalogue',
  noFamily: 'Aucune Famille',
  orderValidators: 'Niveau de commande',
  noValidators: 'Aucun niveau de commande défini',
  tab: {
    user: 'Utilisateur',
    informations: 'Informations',
    catalog: 'Catalogue',
    platform: 'Plateformes',
  },
  label: {
    siegeAddressFacturation: 'Adresse Siege & Facturation',
    identification: 'Identification',
    catalog: 'Catalogue',
    family: 'Famille',
    bankInfo: 'INFOS RIB',
  },
  text: {
    company: 'Service',
    platform: 'Plateforme',
    platformRao: 'Plateforme RAO',
    zipCodeAndCity: 'Code postal & Ville',
    responsable: 'Responsable',
    contactTel: 'Contact Tel.',
    newPlatform: 'Ajouter une plateforme',
    platformDeletionConfirmation: 'Supprimer une plateforme',
    tel: 'Tél',
    fax: 'Fax',
    platformName: 'Nom plateforme',
    address: 'Adresse',
    city: 'Ville',
    department: 'Département',
    zipCode: 'Code postal',
    country: 'Pays',
    responsibleName: 'Responsable',
    phone: 'Téléphone',
    faxNumber: 'Fax',
    email: 'Email',
    bank: 'Banque',
    bankAccountOwner: 'Titulaire du compte',
    iban: 'IBAN',
    bicCode: 'Code BIC',
    companyPlaceholder: 'Séléctionnez un service',
  },
  error: {
    profileNotFound: "Le profil n'a pas été trouvé",
    profileTypeNotFound: "Le type du profil n'a pas été trouvé",
    profileCreationError: 'Erreur lors de la création du profil',
    profileUpdateError: 'Erreur lors de la modification du profil',
    profileDeletionError: 'Erreur lors de la suppression du profil',
    lastName: 'Nom invalide.',
    firstName: 'Prénom invalide.',
    email: "Le format de l'email saisi est incorrect",
    phone: 'Numéro de téléphone invalide.',
    oldPass: 'Mot de passe actuel incorrect.',
    newPass: 'Niveau de sécurité du mot de passe faible.',
    passComplexity:
      'Minimum 8 caractères, une Majuscule, un caractère spécial.',
    passwordDoNotMatch: 'Les mots de passes ne correspondent pas.',
  },
}

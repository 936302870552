<template>
  <router-link
    :to="{ name: menu.routeName }"
    class="prodige-navitem relative group flex items-center px-2 py-3 text-sm leading-5 font-montserrat font-medium text-white hover:text-white hover:bg-orange rounded-bl-lg rounded-tl-lg focus:outline-none transition ease-in-out duration-150 w-full"
    :event="''"
    :exact="menu.exact && !menu.SubMenu.length"
    @click.native.prevent="toggleMenu(menu)"
  >
    <div class="w-full flex items-center justify-start">
      <div class="flex items-center w-full">
        <div class="text-center w-6 ml-2">
          <ab-icon
            class="inline-block h-6 w-6 transition ease-in-out duration-150"
            :icon="menu.icon"
            iconset="icons"
          />
        </div>
        <transition name="fade">
          <span
            v-show="!hideItem"
            class="item-text ml-3 flex-grow whitespace-no-wrap truncate"
            ><slot
          /></span>
        </transition>
      </div>
      <span v-if="!hideItem" class="flex-1" />
      <ab-icon
        v-if="menu.SubMenu.length && !hideItem"
        icon="chevron-right"
        iconset="sidenav"
      />
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <prodige-submenu
        v-if="menu.SubMenu.length"
        v-show="displaySubMenu"
        class="prodige-submenu absolute z-50"
        :menus="menu.SubMenu"
      />
    </transition>
  </router-link>
</template>

<script>
import { AbIcon } from '@ab/material-components'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import ProdigeSubmenu from './prodige-submenu.vue'

export default {
  name: 'ProdigeNavitem',
  components: {
    AbIcon,
    ProdigeSubmenu,
  },
  props: {
    icon: String,
    menu: {
      type: Object,
      default() {
        return {
          path: '#',
        }
      },
    },
    disabled: Boolean,
    hideItem: Boolean,
  },
  data() {
    return {
      displaySubMenu: false,
      typpyInstance: null,
    }
  },
  watch: {
    hideItem() {
      if (!this.hideItem) {
        this.typpyInstance.disable()
      } else {
        this.typpyInstance.enable()
      }
    },
    displaySubMenu() {
      if (this.displaySubMenu) {
        this.typpyInstance.disable()
      } else {
        this.typpyInstance.enable()
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.close)
    this.typpyInstance = tippy(this.$el, {
      content: this.getChildrenTextContent(this.$slots.default),
      placement: 'right',
      theme: 'proclub',
    })
    if (!this.hideItem) {
      this.typpyInstance.disable()
    } else {
      this.typpyInstance.enable()
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
    this.typpyInstance.destroy()
  },
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.displaySubMenu = false
      }
    },
    toggleMenu(menu) {
      // If there is submenus, prenvent the navigation
      // Otherwise, trigger the navigation
      if (!this.disabled) {
        return this.$router.push({ name: menu.routeName }).catch(() => {})
      }
      // If the navigation is prevented, display the sub menu
      this.displaySubMenu = !this.displaySubMenu
      return this.displaySubMenu
    },
    getChildrenTextContent(children) {
      return children
        .map((node) => {
          return node.children
            ? this.getChildrenTextContent(node.children)
            : node.text
        })
        .join('')
    },
  },
}
</script>

<style scoped>
.ab-icon {
  margin: 0 !important;
}
.active,
.router-link-active,
.router-link-exact-active {
  @apply text-white bg-orange font-semibold rounded-tl-lg rounded-bl-lg;
}

.active:hover,
.router-link-active:hover,
.router-link-exact-active:hover {
  @apply text-white bg-orange rounded-tl-lg rounded-bl-lg;
}
.item-text {
  max-width: 10rem;
}
.prodige-submenu {
  top: 0;
  left: 100%;
}
.prodige-navitem[active] .prodige-submenu {
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: width 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  width: 0;
}
</style>
<style>
.tippy-box[data-theme~='proclub'] {
  @apply bg-purple text-white;
}
.tippy-box[data-theme~='proclub'][data-placement^='top']
  > .tippy-arrow::before {
  border-top-color: #464e7e;
}
.tippy-box[data-theme~='proclub'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: #464e7e;
}
.tippy-box[data-theme~='proclub'][data-placement^='left']
  > .tippy-arrow::before {
  border-left-color: #464e7e;
}
.tippy-box[data-theme~='proclub'][data-placement^='right']
  > .tippy-arrow::before {
  border-right-color: #464e7e;
}
</style>

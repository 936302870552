import Vue from 'vue'

// Import global deps
import { components, mixins } from '@apps/shared-components'

import Vuelidate from 'vuelidate'

// Sentry loging
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import App from './App.vue'
import './registerServiceWorker.js'
import router from './router/index.js'
import store from './store/index.js'

// Import our main application style
import './assets/styles/tailwind.css'
import './assets/styles/main.css'
import '@apps/shared-components/src/assets/styles/styles.css'
import '@apps/shared-components/src/assets/styles/ab-components.css'
import '@apps/shared-components/src/assets/styles/nprogress.css'

import i18n from './i18n/plugin.js'

Vue.use(Vuelidate)

const { ProdigeLoader } = components
Vue.component('ProdigeLoader', ProdigeLoader)

const { notification, loader, form } = mixins
// Register global mixin to use toast, and loader, and form
Vue.mixin(notification)
Vue.mixin(loader)
Vue.mixin(form)

Vue.config.productionTip = false

if (process.env.VUE_APP_SENTRY) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    autoSessionTracking: true,
    logError: true,
    sendDefaultPii: true,
    debug: false,
    attachStacktrace: true,
    environment: process.env.VUE_APP_ENV || 'development',
    // release: store.getters.getVersion,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
  Sentry.configureScope((scope) => {
    scope.setExtra('state', JSON.stringify(store.state))
  })
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

import { createRequest, handleError } from './api.js'

const request = createRequest()

export function getApps() {
  return request
    .get('/app', {})
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function getApp() {}

export default {
  home: 'Accueil',
  adherents: 'Adhérents',
  adherentsGroupManagement: 'Gestion groupes adhérents',
  adherentsManagement: 'Gestion adhérents',
  suppliers: 'Fournisseurs',
  suppliersGroupManagement: 'Gestion groupes fournisseurs',
  suppliersManagment: 'Gestion fournisseurs',
  manufacturers: 'Industriels',
  products: 'Produits',
  servicies: 'Services',
  tenders: 'Appels d’offres',
  tendersResponse: "Appel d'offre MP",
  revenues: 'Chiffres d’affaires',
  revenuesRequest: "Demande chiffres d'affaires",
  revenuesResponse: "Résultats chiffre d'affaires",
  statistics: 'Statistique',
  issues: 'Litiges',
  catalogues: 'Catalogues',
  referencing: 'Référencement',
  referencement: 'Référencement',
  userManagement: 'Gestion utilisateurs',
  settings: 'Paramétrages',
  attribution: 'Attribution MP',
  purchases: 'Achat',
  purchasesManagement: 'Pilotage Achat',
  orderLevel: 'Niveaux de commandes',
  catalogs: 'Catalogues',
  orders: 'Commandes',
  stocks: 'Stocks',
  consultation: 'Consultation',
  stockEntry: "Bon d'entrée",
  stockExit: 'Bon de sortie',
  inventoryEntry: 'Inventaire',
  exports: 'Exports',
  users: 'Utilisateurs',
  cartInProgress: 'Paniers en préparation',
  orderTracking: "Suivi d'envoi des commandes",
  orderReceipt: 'Commandes réceptionnées',
  standardCart: 'Paniers type',
  buyingHabits: "Habitudes d'achats",
  publicmarket: 'Marchés publics',
  infoMP: 'Infos marchés publics',
  adminCartType: 'Admin panier type',
  export: 'Exports',
  dashboardMessage: 'Messages',
  priceUpdate: 'Mise à jour cotation',
  profil: 'Mon Profil',
  notice: 'Notifications',
}

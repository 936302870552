import { createRequest, handleError } from './api.js'

const request = createRequest()

export function getHistoryByEntityId(params) {
  return request
    .get(
      '/history/entity/',
      {
        params: {
          model: params.model,
          entity_id: params.entityId,
        },
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function getHistory(id) {
  return request
    .get(`/history/${id}`, {})
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

import { authorizationEventBus } from '@apps/shared-components'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      authorizationEventBus,
    }
  },
  computed: {
    ...mapState({ permissions: (state) => state.authentication.permissions }),
    canCreate() {
      return this.permissions.find((element) => element === 'create')
        ? true
        : false
    },
    canRead() {
      return this.permissions.find((element) => element === 'read')
        ? true
        : false
    },
    canUpdate() {
      return this.permissions.find((element) => element === 'update')
        ? true
        : false
    },
    canDelete() {
      return this.permissions.find((element) => element === 'delete')
        ? true
        : false
    },
  },
  mounted() {
    if (
      this.authorizationEventBus.$store.getters.permissions !==
      this.$store.getters.permissions
    ) {
      this.$store.dispatch(
        'setPermissions',
        this.authorizationEventBus.$store.getters.permissions
      )
    }
  },
}

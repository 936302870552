import Vue from 'vue'

export const EventBus = new Vue({ name: 'EventBus' })

export default {
  data() {
    return {
      EventBus,
    }
  },
  watch: {
    '$parent.isActive': function handleActive() {
      if (this.$parent.isActive) {
        this.initStep()
      }
    },
  },
  methods: {
    initStep() {
      this.hideSpinner('default', 0)
    },
    nextStep() {
      EventBus.$emit('next-step')
    },
    stepDataLoaded() {
      EventBus.$emit('updated-data')
    },
    slugify(str) {
      let slug = str.replace(/^\s+|\s+$/g, '')
      slug = slug.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaeeeeiiiioooouuuunc-------'
      for (let i = 0, l = from.length; i < l; i += 1) {
        slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      slug = slug
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return slug
    },
  },
}

export default {
  state: {
    sideNavOpened: localStorage.getItem('sideNavOpened')
      ? localStorage.getItem('sideNavOpened') === 'true'
      : true,
  },
  mutations: {
    toggleSideNav(state, flag) {
      localStorage.setItem('sideNavOpened', flag)
      state.sideNavOpened = flag
    },
  },
  actions: {},
}

<template>
  <figure class="h-full flex items-center justify-center">
    <img class="w-full h-full" :src="logo" :alt="alt" />
  </figure>
</template>

<script>
import LogoProclubPublic from '../../assets/images/logo/logo_valae_proclub.svg'
import LogoProclubPrivate from '../../assets/images/logo/logo_valae_soluceo.svg'
import LogoValae from '../../assets/images/logo/logo_valae.svg'

export default {
  name: 'ProdigeCompany',
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    logo() {
      if (this.services.length > 1) {
        return LogoValae
      }
      // eslint-disable-next-line no-nested-ternary
      return this.services[0] &&
        this.services[0].Company_type &&
        this.services[0].Company_type.machine_name === 'public'
        ? LogoProclubPublic
        : this.services[0] &&
          this.services[0].Company_type &&
          this.services[0].Company_type.machine_name === 'private'
        ? LogoProclubPrivate
        : LogoValae
    },
    alt() {
      if (
        this.services.length < 2 &&
        this.services[0] &&
        this.services[0].Company_type &&
        this.services[0].Company_type.machine_name === 'public'
      ) {
        return this.services[0].Company_type.label
      }
      return 'Valae'
    },
  },
}
</script>

<style scoped>
figure {
  width: 108px;
  height: 54px;
}
figure img {
  object-fit: contain;
}
img {
  max-height: 3rem;
  object-fit: contain;
}
</style>

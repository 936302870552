import { createRequest, handleError } from './api.js'

const request = createRequest()

// Get all suppliers from a company
export function getCompanySuppliers(companyId) {
  return request
    .get('/supplier/company', {
      params: {
        company_id: companyId,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

// Get all suppliers given company, catalog and revenue start & end dates
export function getRevenueSuppliers({
  companies_id,
  catalogs_id,
  revenue_start,
  revenue_end,
  revenue_id,
}) {
  return request
    .get('/supplier/revenue', {
      params: {
        companies_id,
        catalogs_id,
        revenue_start,
        revenue_end,
        revenue_id,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

// Get all suppliers from a list of companies and catalogs
export function getCompanyCatalogSuppliers({
  companiesId,
  catalogsId,
  startDate,
  endDate,
}) {
  return request
    .get('/supplier/companycatalog', {
      params: {
        companies_id: companiesId,
        catalogs_id: catalogsId,
        start_date: startDate,
        end_date: endDate,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getSupplierContacts(supplier_id) {
  return request
    .get(`/supplier/contacts/${supplier_id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}
export function getSupplierPlatforms(supplier_id) {
  return request
    .get(`/platform/supplier/${supplier_id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateSupplier(supplier) {
  return request
    .put(
      `/supplier/${supplier.id}`,
      {
        ...supplier,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateSupplierContacts(supplier) {
  return request
    .put(
      `/supplier/${supplier.id}`,
      {
        ...supplier,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function createSupplier(supplier) {
  return request
    .post(
      '/supplier/',
      {
        ...supplier,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function getSuppliers(offset, limit) {
  return request
    .get('/supplier/all', {
      params: {
        offset,
        limit,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getSupplier(id) {
  return request
    .get(`/supplier/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getSupplierStatus() {
  return request
    .get('/supplier/status/', {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

// Used in 'front-office' application
export function getSuppliersByAdherent({ id, is_group }) {
  // 'id' : adherent group id or adhernt id
  return request
    .get(`/supplier/adherent/${id}`, {
      params: {
        is_group,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

// Used in 'front-office' application for litigations
export function getSuppliersByAdherentForLitigation({ id }) {
  // 'id' : adherent group id or adhernt id
  return request
    .get(`/supplier/litigation/adherent/${id}`)
    .then(({ data }) => data)
    .catch(handleError)
}

// Used in 'front-office' application
export function getSuppliersAndFamiliesByAdherent(adherent_id) {
  return request
    .get(`/supplier/family/adherent/${adherent_id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

// Used in 'front-office' application
export function getCommercialTermsByCatalog(supplierId, adherentId) {
  return request
    .get(`/supplier/terms/${supplierId}/${adherentId}`)
    .then(({ data }) => data)
    .catch(handleError)
}

// Used in 'front-office' application
export function getSuppliersByCatalog(catalogId) {
  // 'id' : adherent group id or adhernt id
  return request
    .get('/supplier/catalog', {
      params: {
        catalogId,
      },
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

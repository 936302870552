import Vue from 'vue'

export default {
  state: {
    spinner: { default: { loading: false, timeouts: [], prevent: false } },
    timeout: 200000,
  },
  mutations: {
    preventSpinner(state, name = 'default') {
      if (state.spinner[name]) {
        Vue.set(state.spinner, name, {
          loading: false,
          timeouts: state.spinner[name] ? state.spinner[name].timeouts : [],
          prevent: true,
        })
      }
    },
    unpreventSpinner(state, name = 'default') {
      if (state.spinner[name]) {
        Vue.set(state.spinner, name, {
          loading: false,
          timeouts: state.spinner[name] ? state.spinner[name].timeouts : [],
          prevent: false,
        })
      }
    },
    registerSpinner(state, name) {
      state.spinner = Object.assign({}, state.spinner, {
        [name]: { loading: false, timeouts: [], prevent: false },
      })
    },
    showSpinner(state, options = { name: 'default', timeout: true }) {
      if (state.spinner[options.name] && state.spinner[options.name].prevent) {
        Vue.set(state.spinner, options.name, {
          loading: false,
          timeouts: state.spinner[options.name]
            ? state.spinner[options.name].timeouts
            : [],
          prevent: false,
        })
        return
      }
      Vue.set(state.spinner, options.name, {
        loading: true,
        timeouts: state.spinner[options.name]
          ? state.spinner[options.name].timeouts
          : [],
        prevent: false,
      })

      // If the spinner if not removed after the timeout defined if the store, we call a calback to remove it
      // If dispatch a global event to handle the loading error
      if (state.spinner[options.name] && options.timeout) {
        state.spinner[options.name].timeouts.push(
          setTimeout(() => {
            const event = new CustomEvent('loading-failed', {
              detail: options.name,
            })
            document.dispatchEvent(event)
          }, state.timeout)
        )
      }
    },
    hideSpinner(state, name = 'default') {
      // If we remove the loader before the timeout,
      // Clear the timeout to prevent from triggering the global event
      if (state.spinner[name])
        state.spinner[name].timeouts.forEach((timeout) => clearTimeout(timeout))
      if (!name)
        Object.keys(state.spinner).map((key) =>
          Vue.set(state.spinner, key, {
            loading: false,
            timeouts: state.spinner[name].timeouts,
            prevent: false,
          })
        )
      else
        Vue.set(state.spinner, name, {
          loading: false,
          timeouts: state.spinner[name].timeouts,
          prevent: false,
        })
    },
  },
  actions: {},
}

<template>
  <li
    class="prodige-step-item relative cursor-pointer w-32"
    :class="[state]"
    @click.prevent="handleChangeStep"
  >
    <a class="relative flex flex-col items-center">
      <span
        class="circle w-8 h-8 flex items-stretch justify-center rounded-full"
      >
        <span
          v-if="state === 'done' || state === 'current'"
          class="absolute self-center"
        >
          <!-- Completed -->
          <svg
            v-if="state === 'done'"
            class="w-5 h-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          <!-- Ongoing -->
          <svg
            v-else-if="state === 'current'"
            class="w-5 h-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
            />
          </svg>
        </span>
        <!-- Todo -->
        <span
          v-else
          class="absolute self-center h-3 w-3 bg-white rounded-full"
          aria-hidden="true"
        />
      </span>

      <!-- Labels -->
      <span class="label mt-2">{{ name }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'ProdigeStepItem',
  props: {
    name: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: 'pending',
      validator: (value) =>
        ['pending', 'current', 'done'].indexOf(value) !== -1,
    },
    href: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChangeStep() {
      if (this.state !== 'pending') {
        this.$router
          .push({ query: { ...this.$route.query, step: this.href } })
          .catch(() => {})
      }
    },
  },
}
</script>

<style scoped>
.label {
  @apply text-dark-grey;
}
.prodige-step-item.pending .circle {
  background-color: var(--step-pending-bg-color, #ab81b8);
  color: var(--step-pending-color, #ffffff);
}
.prodige-step-item.pending .circle:hover {
  @apply border-gray-400;
}
.prodige-step-item.current .circle {
  background-color: var(--step-current-bg-color, #28b7df);
  color: var(--step-current-color, #ffffff);
}
.prodige-step-item.done .circle {
  background-color: var(--step-done-bg-color, #87ca89);
  color: var(--step-done-color, #ffffff);
}
</style>

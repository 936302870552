<template>
  <section id="prodige-search">
    <prodige-card class="border-l-8 border-purple">
      <template #header>
        <div class="flex justify-between items-start">
          <h1 class="py-4 font-poppins-medium font-medium">
            {{ $t('text.search') }}
          </h1>
          <div
            v-if="listFilters.length"
            class="flex justify-between items-start"
          >
            <button
              class="py-4 underline text-sm whitespace-no-wrap"
              type="button"
              @click="deleteFilters"
            >
              {{ $t('action.deleteFilters') }}
            </button>
          </div>
        </div>
      </template>
      <section class="prodige-search">
        <form @submit.prevent="handleSearch">
          <!-- Active filters -->
          <section class="filters-zone">
            <div
              v-if="listFilters.length"
              class="flex justify-between items-start"
            >
              <div class="flex flex-wrap w-full">
                <search-chips
                  v-for="field in listFilters"
                  :key="`filter-${field.uid}`"
                  :filter="field"
                  @delete="deleteFilter(field)"
                />
              </div>
            </div>
          </section>

          <!-- Search type selector -->
          <section
            class="search-zone flex flex-wrap w-full items-center justify-between mt-2"
          >
            <div
              class="search-available-fields relative flex items-center md:justify-between lg:justify-start md:w-full lg:w-1/2"
            >
              <!-- Search in -->
              <span
                class="block h-full leading-8 w-3/12 text-xs mr-6 whitespace-no-wrap"
                >{{ $t('text.searchOn') }} :
              </span>
              <multiple-select
                v-model="selectedField"
                class="w-9/12"
                :placeholder="$t('text.searchOn')"
                :allow-empty="false"
                :multiple="false"
                :options="availableFields"
                label="label"
                track-by="label"
              />
            </div>

            <div
              v-if="fieldSelected && Object.keys(fieldSelected).length"
              class="search-values flex flex-column justify-between md:justify-between lg:justify-start md:w-full lg:w-1/2 md:mt-4 lg:mt-0"
            >
              <span
                v-if="fieldSelected.type === ObjectList"
                class="block h-full leading-8 w-3/12 text-xs mr-6 whitespace-no-wrap"
                >{{ $t('text.correspondTo') }}</span
              >
              <span
                v-if="fieldSelected.type === FreeSearch"
                class="block h-full leading-8 w-3/12 text-xs mr-6 whitespace-no-wrap"
                >{{ $t('text.includes') }}</span
              >

              <!-- LIST OF OBJECTS -->
              <div v-if="fieldSelected.type === ObjectList" class="w-9/12">
                <multiple-select
                  v-model="selectedObjValue"
                  class="mandatory"
                  :allow-empty="false"
                  :multiple="false"
                  :placeholder="$t('label.chooseValue')"
                  :options="fieldSelected.listObjectData"
                  :label="fieldSelected.labelOnListObject"
                  :track-by="fieldSelected.keyOnListObject"
                />
              </div>

              <!-- FREE TEXT -->
              <div v-if="fieldSelected.type === FreeSearch" class="flex w-9/12">
                <ab-input
                  v-model.trim="writtedSearchValue"
                  :placeholder="fieldSelected.label"
                  type="text"
                />
                <prodige-tooltip
                  v-if="showTooltip"
                  class="tooltip-right"
                  width="250"
                >
                  <template slot="container">
                    <ab-icon iconset="prodigesearch" icon="information" />
                  </template>
                  <template slot="text">
                    {{ $t('text.searchTooltip') }}
                  </template>
                </prodige-tooltip>
              </div>

              <!-- BETWEEN TWO DATES -->
              <div
                v-if="fieldSelected.type === DateInterval"
                class="w-9/12 flex items-center space-x-4"
              >
                <ab-datepicker
                  v-model="searchDates.start"
                  :placeholder="$t('text.startDate')"
                  format="dd/MM/yyyy"
                  :full-month-name="true"
                  :language="datePickerLocal"
                  clear-text=""
                />
                <span
                  class="block h-full leading-8 w-3/12 text-xs mr-6 whitespace-no-wrap"
                  >{{ $t('text.to') }}</span
                >
                <ab-datepicker
                  v-model="searchDates.end"
                  :placeholder="$t('text.endDate')"
                  format="dd/MM/yyyy"
                  :full-month-name="true"
                  :disabled-dates="computedDisabledDateTo"
                  :language="datePickerLocal"
                  clear-text=""
                />
              </div>

              <prodige-button
                class="search-btn inline-flex items-center rounded-md ml-4"
                type="submit"
              >
                <ab-icon icon="searchBar" />
              </prodige-button>
            </div>
          </section>
        </form>
      </section>
    </prodige-card>

    <ab-iconset iconset="prodigesearch">
      <g id="information">
        <path
          d="M12 2A10 10 0 1 0 22 12 10.01114 10.01114 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8.00917 8.00917 0 0 1 12 20Zm0-8.5a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0v-3A1 1 0 0 0 12 11.5Zm0-4a1.25 1.25 0 1 0 1.25 1.25A1.25 1.25 0 0 0 12 7.5Z"
          fill="#707683"
        />
      </g>
    </ab-iconset>
  </section>
</template>

<script>
import {
  AbIcon,
  AbIconset,
  AbInput,
  AbDatepicker,
  MultipleSelect,
} from '@ab/material-components'
import ProdigeButton from '../buttons/prodige-button.vue'
import ProdigeCard from '../../cards/prodige-card.vue'

import SearchChips from './search-chips.vue'
import ProdigeTooltip from '../../layouts/prodige-tooltip.vue'
import { EventBus, FieldType } from '../../../mixins/search.js'
const { ObjectList, FreeSearch, DateInterval } = FieldType

export default {
  name: 'ProdigeSearch',
  components: {
    ProdigeCard,
    AbInput,
    AbDatepicker,
    MultipleSelect,
    AbIcon,
    AbIconset,
    ProdigeButton,
    ProdigeTooltip,
    SearchChips,
  },
  props: {
    search: {
      type: Object,
    },
    actionName: {
      type: String,
      default: 'getSearchResult',
    },
    showTooltip: {
      // Prop to show the tooltip besides a FreeSearch filter
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false,
      ObjectList,
      FreeSearch,
      DateInterval,
      filters: [],
      selectedField: null,
      selectedObjValue: null,
      writtedSearchValue: '',
      searchDates: {
        start: null,
        end: null,
      },
    }
  },
  computed: {
    listFilters() {
      return this.filters.filter((filter) => filter.hidden === false)
    },
    fieldSelected() {
      return this.selectedField
    },
    availableFields() {
      return this.search.fields
        .filter((field) => !field.hidden)
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    computedDisabledDateTo() {
      const epoch = 8640000
      return {
        to: new Date(new Date(this.searchDates.start).getTime() - epoch),
      }
    },
  },
  watch: {
    'searchDates.start': function watchStartDate() {
      if (this.searchDates.start.getTime() >= this.searchDates.end.getTime()) {
        this.searchDates.end = this.searchDates.start
      }
    },
    selectedField() {
      this.resetValues()
    },
    '$route.query.page': function watchPage(page) {
      this.search.addOffset(page)
    },
    availableFields() {
      if (this.search.fieldSelected) {
        this.selectedField = this.search.fields.find(
          (field) => field.key === this.search.fieldSelected
        )
      }
      this.getDefaultService()
    },
  },
  mounted() {
    if (!this.search) {
      throw new Error('Error no search instance given!')
    }

    EventBus.$on('getData', this.searchAction)
    EventBus.$on('applyFilter', this.applyFilters)
  },
  beforeDestroy() {
    EventBus.$off()
  },
  methods: {
    getDefaultService() {
      const { user } = this.$store.getters
      const { Company: services } = user

      // If the current is associated to only 1 service
      // Add this service as a default search filter
      if (services.length === 1) {
        const selectedField = this.availableFields.find(
          (field) => field.name === this.constants.search.SERVICE_KEY
        )
        if (selectedField) {
          this.search.addFilter(selectedField, services[0], true, true)
        }
      }
    },
    resetValues() {
      this.selectedObjValue = null
      this.writtedSearchValue = ''
      this.searchDates = {
        start: new Date(),
        end: new Date(),
      }
    },
    handleSearch() {
      this.preventSpinner('default')

      // If resend search is enabled, we allow the engine to send search with the current filter
      const canResendSearch =
        this.search.allowResendSearch && this.search.getFilters().length

      switch (this.selectedField.type) {
        case ObjectList:
          if (!this.selectedObjValue && canResendSearch) {
            this.unpreventSpinner('default')
            this.searchAction()
            break
          } else if (!this.selectedObjValue) {
            break
          }
          this.search.addFilter(this.selectedField, this.selectedObjValue)
          break
        case FreeSearch:
          if (!this.writtedSearchValue && canResendSearch) {
            this.unpreventSpinner()
            this.searchAction()
            break
          } else if (!this.writtedSearchValue) {
            break
          }
          this.search.addFilter(this.selectedField, this.writtedSearchValue)
          break
        case DateInterval:
          if (
            !this.searchDates.start &&
            !this.searchDates.end &&
            canResendSearch
          ) {
            this.unpreventSpinner()
            this.searchAction()
            break
          } else if (!this.searchDates.start && !this.searchDates.end) {
            break
          }
          if (this.searchDates.start) {
            this.searchDates.start.setHours(0, 0, 0)
          }
          if (this.searchDates.end) {
            this.searchDates.end.setHours(23, 59, 59)
          }
          this.search.addFilter(this.selectedField, this.searchDates)
          break
        default:
          break
      }
      this.resetValues()
    },
    applyFilters() {
      this.filters = this.search.filters
    },
    async searchAction() {
      if (this.isLoaded) {
        // this.preventSpinner('default');
      }
      if (this.search.frontSpinner) {
        this.showSpinner()
      } else {
        this.showSpinner('table')
      }
      if (this.search.autoReload === true || this.search.getFilters().length) {
        this.$store
          .dispatch(this.actionName, {
            model: this.search.model,
            fields: this.search.filters,
            offset: (this.search.getPage() - 1) * this.search.limit,
            limit: this.search.limit,
            associations: this.search.defaultAssociations,
            toMany: this.search.toManyAssociations,
            orders: this.search.orders,
            params: this.search.params,
            enableMode: this.search.enableMode,
          })
          .then((searchResuts) => {
            this.$emit('searchResults', searchResuts)
            this.hideSpinner()
            this.hideSpinner('table')
            this.isLoaded = true
          })
      } else {
        this.$emit('searchResults', [])
        this.hideSpinner()
      }
    },
    deleteFilter(index) {
      // Prevent to show spinner when removing filter unless it's on the front app
      if (this.search.frontSpinner) {
        this.preventSpinner('default')
      }

      // Get copy of the current search filters
      const filters = [...this.search.filters]

      this.search.removeFilter(index, false, false)

      // If there where only 1 filter left, and the search on filter is removed disabled
      // We trigger a new search when the last filter is removed
      if (filters.length === 1 && this.search.searchOnFilterRemoved === false) {
        this.unpreventSpinner('default')
        this.searchAction()
      }
    },
    deleteFilters() {
      if (this.search.frontSpinner) {
        this.preventSpinner('default')
      }
      this.search.removeFilters()
    },
  },
}
</script>

<style scoped>
.search-available-fields,
.search-values {
  min-width: 50%;
}

.search-available-fields .multiple-select,
.search-values .multiple-select,
.search-values .ab-input {
  min-width: 20rem;
  @apply max-w-xs w-auto mt-0;
}
.search-values .ab-datepicker {
  @apply mt-0;
}
.search-btn {
  @apply w-8 h-8 p-0;
}
.search-btn .ab-icon {
  @apply h-4;
}
.prodige-button {
  @apply bg-purple;
}
.prodige-button:hover {
  @apply bg-purple opacity-75 !important;
}
</style>

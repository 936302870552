<template>
  <section class="prodige-draggable flex justify-between w-full">
    <!-- Source list -->
    <div class="space-y-2">
      <h2>{{ sourceLabel }}</h2>
      <div class="source dragable-list space-y-2">
        <div v-if="sourceSearchable" class="header">
          <label class="relative">
            <ab-icon
              icon="search"
              iconset="dragable"
              class="icon absolute top-0 left-1"
            />
            <input
              v-model="sourceSearch"
              class="input"
              type="text"
              @keydown.enter.prevent="() => {}"
            />
          </label>
        </div>
        <draggable
          class="h-full space-y-3"
          :list="computedSource"
          :group="computedGroup"
          tag="ul"
          @start="drag = true"
          @end="drag = false"
        >
          <li
            v-for="element in computedSource"
            :key="`element-${element[valueKey]}`"
            class="w-full"
          >
            <prodige-draggable-item
              :key="`element-${element[valueKey]}`"
              v-model="element.selected"
            >
              {{ element[labelKey] }}
            </prodige-draggable-item>
          </li>
        </draggable>
      </div>
    </div>

    <div class="actions flex flex-col justify-center items-center mx-4">
      <div class="flex items-center justify-between">
        <button type="button" @click="moveAllToSource">
          <ab-icon iconset="dragable" icon="move_l_all" />
        </button>
        <button type="button" @click="moveSelectedToSource">
          <ab-icon iconset="dragable" icon="move_l" />
        </button>
        <button type="button" @click="moveSelectedToDestination">
          <ab-icon iconset="dragable" icon="move_r" />
        </button>
        <button type="button" @click="moveAllToDestination">
          <ab-icon iconset="dragable" icon="move_r_all" />
        </button>
      </div>
    </div>

    <!-- Destination list -->
    <div class="space-y-2">
      <h2>{{ destinationLabel }}</h2>
      <div class="destination dragable-list space-y-2">
        <div v-if="destinationSearchable" class="header">
          <label class="relative">
            <ab-icon
              icon="search"
              iconset="dragable"
              class="icon absolute top-0 left-1"
            />
            <input
              v-model="destinationSearch"
              class="input"
              type="text"
              @keydown.enter.prevent="() => {}"
            />
          </label>
        </div>
        <draggable
          v-model="computedDestination"
          class="h-full space-y-3"
          :group="computedGroup"
          tag="ul"
          @input="$emit('is-dirty', true)"
          @start="drag = true"
          @end="drag = false"
        >
          <li
            v-for="element in computedDestination"
            :key="`element-${element[valueKey]}`"
          >
            <prodige-draggable-item
              :key="`element-${element[valueKey]}`"
              v-model="element.selected"
              :class="{ hidden: element.hidden }"
            >
              {{ element[labelKey] }}
            </prodige-draggable-item>
          </li>
        </draggable>
      </div>
    </div>

    <ab-iconset iconset="dragable">
      <g id="drag">
        <path
          d="M15.351 3.072L12.472.197A.678.678 0 0011.989 0a.684.684 0 00-.492.197L8.677 3.013a.676.676 0 000 .956l.364.363c.255.255.7.255.955 0l1.079-1.08v3.97c0 .012.007.022.01.033.028.346.319.62.673.62h.514a.68.68 0 00.68-.676V3.311l1.08 1.08a.677.677 0 00.956 0l.363-.362a.676.676 0 000-.957zM15.35 19.972l-.363-.364a.692.692 0 00-.955 0l-1.08 1.08v-3.872a.693.693 0 00-.68-.691h-.514a.684.684 0 00-.674.627c-.002.012-.009.03-.009.041v3.955l-1.079-1.08a.694.694 0 00-.956 0l-.364.363a.677.677 0 00.001.957l2.82 2.815a.666.666 0 00.472.197h.025c.182 0 .351-.07.478-.197l2.879-2.875a.676.676 0 000-.956zM7.191 11.062H3.328L4.41 9.988a.664.664 0 00.198-.476c0-.18-.07-.349-.198-.476l-.364-.362a.678.678 0 00-.957 0L.211 11.55c-.131.13-.2.305-.197.484a.67.67 0 00.197.491L3.03 15.34a.675.675 0 00.957 0l.364-.363a.67.67 0 00.197-.478.674.674 0 00-.197-.48l-1.082-1.082h3.943a.275.275 0 00.042-.005.682.682 0 00.63-.669v-.514a.7.7 0 00-.693-.687zM23.817 11.55l-2.879-2.876a.678.678 0 00-.957 0l-.363.364a.67.67 0 000 .95l1.081 1.074h-3.883a.683.683 0 00-.672.687v.514c0 .354.271.64.618.67.011.002.02.005.032.005h3.964l-1.082 1.081a.674.674 0 00-.197.48c0 .18.07.35.197.478l.364.363a.675.675 0 00.957 0l2.82-2.816c.13-.13.2-.304.197-.484a.673.673 0 00-.197-.49zM12.014 10.124A1.88 1.88 0 0010.136 12a1.88 1.88 0 003.756 0 1.88 1.88 0 00-1.878-1.876z"
        />
      </g>
      <g id="search">
        <path d="M14.531 8.906a.469.469 0 100 .938.469.469 0 000-.938z" />
        <path
          d="M23.45 20.8l-6.256-6.258a9.291 9.291 0 001.556-5.167C18.75 4.205 14.544 0 9.375 0 4.205 0 0 4.206 0 9.375c0 5.17 4.206 9.375 9.375 9.375a9.291 9.291 0 005.168-1.556l1.729 1.73 4.527 4.527c.355.354.825.549 1.326.549.5 0 .971-.195 1.326-.55.354-.353.549-.824.549-1.325s-.195-.972-.55-1.326zm-9.128-4.594a8.366 8.366 0 01-4.947 1.607c-4.652 0-8.438-3.786-8.438-8.438C.938 4.723 4.723.937 9.376.937c4.652 0 8.438 3.786 8.438 8.438 0 1.79-.556 3.5-1.607 4.947a8.48 8.48 0 01-1.884 1.884zm.983.425c.486-.397.93-.84 1.326-1.326l1.33 1.33c-.405.478-.848.921-1.326 1.326l-1.33-1.33zm7.483 6.157a.931.931 0 01-.663.274.931.931 0 01-.663-.274L17.3 18.625c.475-.407.918-.85 1.325-1.325l4.163 4.162a.93.93 0 01.274.663.93.93 0 01-.274.663z"
        /><path
          d="M9.375 1.875c-4.136 0-7.5 3.364-7.5 7.5s3.364 7.5 7.5 7.5 7.5-3.364 7.5-7.5-3.364-7.5-7.5-7.5zm0 14.063a6.57 6.57 0 01-6.563-6.563 6.57 6.57 0 016.563-6.563 6.57 6.57 0 016.563 6.563 6.57 6.57 0 01-6.563 6.563z"
        /><path
          d="M14.628 7.363a5.626 5.626 0 00-2.032-2.598A5.585 5.585 0 009.375 3.75a.469.469 0 000 .938 4.72 4.72 0 014.378 3.011.469.469 0 00.875-.336z"
        />
      </g>
      <g id="move_l_all">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.7071 6.29289C12.0976 6.68342 12.0976 7.31658 11.7071 7.70711L7.41421 12L11.7071 16.2929C12.0976 16.6834 12.0976 17.3166 11.7071 17.7071C11.3166 18.0976 10.6834 18.0976 10.2929 17.7071L5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L10.2929 6.29289C10.6834 5.90237 11.3166 5.90237 11.7071 6.29289Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.7071 6.29289C19.0976 6.68342 19.0976 7.31658 18.7071 7.70711L14.4142 12L18.7071 16.2929C19.0976 16.6834 19.0976 17.3166 18.7071 17.7071C18.3166 18.0976 17.6834 18.0976 17.2929 17.7071L12.2929 12.7071C11.9024 12.3166 11.9024 11.6834 12.2929 11.2929L17.2929 6.29289C17.6834 5.90237 18.3166 5.90237 18.7071 6.29289Z"
        />
      </g>
      <g id="move_l">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
        />
      </g>
      <g id="move_r_all">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289L18.7071 11.2929C19.0976 11.6834 19.0976 12.3166 18.7071 12.7071L13.7071 17.7071C13.3166 18.0976 12.6834 18.0976 12.2929 17.7071C11.9024 17.3166 11.9024 16.6834 12.2929 16.2929L16.5858 12L12.2929 7.70711C11.9024 7.31658 11.9024 6.68342 12.2929 6.29289Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.29289 6.29289C5.68342 5.90237 6.31658 5.90237 6.70711 6.29289L11.7071 11.2929C12.0976 11.6834 12.0976 12.3166 11.7071 12.7071L6.70711 17.7071C6.31658 18.0976 5.68342 18.0976 5.29289 17.7071C4.90237 17.3166 4.90237 16.6834 5.29289 16.2929L9.58579 12L5.29289 7.70711C4.90237 7.31658 4.90237 6.68342 5.29289 6.29289Z"
        />
      </g>
      <g id="move_r">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
        />
      </g>
      <g id="drag">
        <path
          d="M8.94446 1.79199L7.26723 0.114749C7.1912 0.0387178 7.08978 -0.000208315 6.98496 1.93231e-05C6.8782 -0.00100505 6.77451 0.0387178 6.69847 0.114749L5.05584 1.75739C4.90196 1.91127 4.90196 2.16144 5.05584 2.31521L5.26766 2.52703C5.41619 2.67556 5.67547 2.67556 5.82412 2.52703L6.45297 1.89681V4.21269C6.45297 4.21975 6.45707 4.22555 6.45832 4.23215C6.47539 4.43395 6.64464 4.59364 6.85099 4.59364H7.15034C7.36784 4.59364 7.54665 4.417 7.54665 4.19949V3.94089C7.54665 3.93975 7.54665 3.93896 7.54665 3.93804V1.93119L8.17573 2.56152C8.32961 2.7154 8.57922 2.7154 8.73299 2.56152L8.94446 2.34993C9.09834 2.19593 9.09834 1.94564 8.94446 1.79199Z"
        />
        <path
          d="M8.94439 11.6504L8.73269 11.4383C8.58415 11.2895 8.32476 11.2895 8.176 11.4383L7.54692 12.0686V10.0706C7.54692 10.0695 7.54692 10.0688 7.54692 10.0678V9.80917C7.54692 9.59155 7.368 9.40625 7.15071 9.40625H6.85114C6.64502 9.40625 6.47577 9.57003 6.45858 9.77206C6.45733 9.77878 6.45323 9.78902 6.45323 9.79597V12.1032L5.82438 11.473C5.67573 11.3243 5.41588 11.3243 5.26723 11.473L5.05553 11.6848C4.90187 11.8386 4.9021 12.0888 5.05598 12.2427L6.69874 13.8852C6.77283 13.9596 6.87174 14 6.97384 14H6.98864C7.09437 14 7.19306 13.9597 7.26727 13.8852L8.94439 12.2081C9.09828 12.0544 9.09828 11.8042 8.94439 11.6504Z"
        />
        <path
          d="M4.19008 6.45399H4.15639H3.93023C3.92943 6.45399 3.92864 6.45399 3.92773 6.45399H1.93931L2.56964 5.82707C2.64396 5.75286 2.68482 5.65543 2.68482 5.54992C2.68482 5.4443 2.64396 5.34607 2.56964 5.27197L2.35782 5.06061C2.20394 4.90673 1.95365 4.90696 1.79999 5.06084L0.122754 6.73797C0.0467232 6.81388 0.00608976 6.91575 0.0081385 7.02024C0.00597594 7.12894 0.0467232 7.23069 0.122754 7.30672L1.76539 8.94948C1.84233 9.02642 1.94329 9.06489 2.04436 9.06489C2.14543 9.06489 2.24639 9.02642 2.32333 8.94948L2.53504 8.73777C2.60936 8.66356 2.65033 8.56442 2.65033 8.45891C2.65033 8.35329 2.60947 8.25313 2.53504 8.17903L1.90493 7.54779H4.20203C4.20897 7.54779 4.22035 7.54608 4.22696 7.54483C4.42887 7.52776 4.59379 7.36079 4.59379 7.15455V6.85475C4.59379 6.63746 4.40759 6.45399 4.19008 6.45399Z"
        />
        <path
          d="M13.8772 6.73774L12.1999 5.06073C12.0462 4.90684 11.7959 4.90684 11.6421 5.06073L11.4305 5.27254C11.3562 5.34664 11.3152 5.44578 11.3152 5.55129C11.3152 5.65691 11.3561 5.75252 11.4305 5.82673L12.0607 6.45365H10.0596C10.0585 6.45365 10.0577 6.45365 10.0568 6.45365H9.79836C9.58085 6.45365 9.40625 6.63701 9.40625 6.8544V7.1542C9.40625 7.36044 9.56457 7.52719 9.7666 7.54438C9.77331 7.54574 9.77821 7.54745 9.78504 7.54745H12.0952L11.4648 8.17858C11.3906 8.25267 11.3496 8.35261 11.3496 8.45789C11.3496 8.56363 11.3905 8.66299 11.4648 8.7372L11.6767 8.94902C11.7535 9.02596 11.8545 9.06455 11.9556 9.06455C12.0566 9.06455 12.1575 9.02608 12.2344 8.94913L13.8773 7.30638C13.9531 7.23058 13.9937 7.12871 13.9919 7.02411C13.9936 6.91541 13.953 6.81354 13.8772 6.73774Z"
        />
        <path
          d="M7.00026 5.90625C6.39691 5.90625 5.90601 6.39715 5.90601 7.00063C5.90601 7.60398 6.39691 8.095 7.00026 8.095C7.60362 8.095 8.09463 7.60398 8.09463 7.00063C8.09463 6.39715 7.60362 5.90625 7.00026 5.90625Z"
        />
      </g>
    </ab-iconset>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import Fuse from 'fuse.js'
import { AbIcon, AbIconset } from '@ab/material-components'
import ProdigeDraggableItem from './prodige-draggable-item.vue'

export default {
  name: 'ProdigeDraggable',
  components: {
    AbIcon,
    AbIconset,
    draggable,
    ProdigeDraggableItem,
  },
  props: {
    name: String,
    source: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: [Array],
      required: true,
      default: () => [],
    },
    group: {
      type: String,
      default: 'prodige-dragable',
    },
    labelKey: {
      type: String,
      required: true,
    },
    valueKey: {
      type: [String, Number],
      default: 'id',
    },
    searchKey: {
      type: String,
      required: true,
    },
    sourceLabel: String,
    destinationLabel: String,
    sourceSearchable: {
      type: Boolean,
      default: true,
    },
    destinationSearchable: {
      type: Boolean,
      default: true,
    },
    // If true all items will be on the left, if false on the right
    autoFillSource: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      firstMount: true,
      innerSource: this.source.map((a) => Object.assign({}, a)),
      innerDestination: this.value.map((a) => Object.assign({}, a)),
      sourceFuseInstance: null,
      destinationFuseInstance: null,
      sourceSearch: '',
      destinationSearch: '',
    }
  },
  computed: {
    computedGroup() {
      return `${this.group}-${this._uid}`
    },
    computedSource: {
      get() {
        if (!this.sourceSearch || !this.sourceFuseInstance) {
          return this.innerSource
            ? this.innerSource.filter(this.filterSourceList)
            : []
        }
        const result = this.sourceFuseInstance
          .search(this.sourceSearch)
          .map((result) => result.item)
          .filter(this.filterSourceList)
        return result
      },
      set(value) {
        this.innerSource = value
      },
    },
    computedDestination: {
      get() {
        if (!this.destinationSearch || !this.destinationFuseInstance) {
          return this.innerDestination
            ? this.innerDestination.map((el) => {
                // Use the hidden property to filter destination list
                const item = el
                delete item.hidden
                return item
              })
            : []
        }
        const result = this.destinationFuseInstance
          .search(this.destinationSearch)
          .map((result) => result.item)
        return this.innerDestination.map((el) => {
          const item = el
          // If current item isn't in the result list,
          // We add the hidden property to hide from the result list
          if (
            !result.map((i) => i[this.valueKey]).includes(item[this.valueKey])
          ) {
            item.hidden = true
          } else {
            delete item.hidden
          }
          return item
        })
      },
      set(value) {
        this.innerDestination = value
        this.$emit('input', this.innerDestination)
      },
    },
  },
  watch: {
    source() {
      this.innerSource = this.source.map((a) => Object.assign({}, a))
    },
    value() {
      this.innerDestination = this.value.map((a) => Object.assign({}, a))
      // If the value is changed from outside the component, it may change innerDestination value
      // But inner source is less likely to be modified.
      // So if value change reset innerSource with source value, except that we filter value contained in innerDestination
      // By using the filterSourceList method
      this.innerSource = this.source.filter(this.filterSourceList)
    },
    innerSource() {
      this.initSourceFuseInstance(this.innerSource)
      this.initDestinationFuseInstance(this.innerDestination)
      if (this.autoFillSource === true && this.firstMount) {
        this.moveAllToDestination()
        this.firstMount = false
      }
    },
    innerDestination() {
      this.initSourceFuseInstance(this.innerSource)
      this.initDestinationFuseInstance(this.innerDestination)
    },
  },
  mounted() {
    this.initSourceFuseInstance(this.innerSource)
    this.initDestinationFuseInstance(this.innerDestination)
    if (this.autoFillSource === true) {
      this.moveAllToDestination()
    }
  },
  methods: {
    updateInnerSource(value) {
      return (this.innerSource = value)
    },
    initSourceFuseInstance(data) {
      this.sourceFuseInstance = new Fuse(data, {
        keys: [this.searchKey],
        threshold: '0.3',
      })
    },
    initDestinationFuseInstance(data) {
      this.destinationFuseInstance = new Fuse(data, {
        keys: [this.searchKey],
        threshold: '0.3',
      })
    },
    filterSourceList(data) {
      return !this.innerDestination
        .map((item) => item[this.valueKey])
        .includes(data[this.valueKey])
    },
    filterDestinationList(data) {
      return !this.innerSource
        .map((item) => item[this.valueKey])
        .includes(data[this.valueKey])
    },
    arrayDedup(item, index, self) {
      return (
        index ===
        self.findIndex((t) => t[this.valueKey] === item[this.valueKey])
      )
    },
    moveAllToSource() {
      // Merge the two arrays
      this.innerSource = [...this.innerSource, ...this.innerDestination]
      // And remove duplicate
      this.innerSource = this.innerSource.filter(this.arrayDedup)
      // Clean the desctination array
      this.computedDestination = []
      // Reset selected items
      this.resetSourceSelection()
      this.$emit('is-dirty', true)
    },
    moveSelectedToSource() {
      const selected = this.computedDestination.filter((item) => item.selected)
      // Merge the two arrays
      this.innerSource = [...this.innerSource, ...selected]
      // And remove duplicate
      this.innerSource = this.innerSource.filter(this.arrayDedup)
      this.computedDestination = this.computedDestination.filter(
        (item) => !item.selected
      )
      // Reset selected items
      this.resetSourceSelection()
      this.$emit('is-dirty', true)
    },
    moveAllToDestination() {
      this.computedDestination = [...this.innerSource, ...this.innerDestination]
      this.computedDestination = this.computedDestination.filter(
        this.arrayDedup
      )
      if (this.innerSource.length) {
        this.innerSource = []
        this.$emit('is-dirty', true)
      }
    },
    moveSelectedToDestination() {
      const selected = this.computedSource.filter((item) => item.selected)
      // Merge the two arrays
      this.computedDestination = [...this.computedDestination, ...selected]
      // And remove duplicate
      this.computedDestination = this.computedDestination.filter(
        this.arrayDedup
      )
      this.innerSource = this.innerSource.filter((item) => !item.selected)
      // Reset selected items
      this.resetDestinationSelection()
      this.$emit('is-dirty', true)
    },
    resetSourceSelection() {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.computedSource) {
        item.selected = false
      }
    },
    resetDestinationSelection() {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.computedDestination) {
        item.selected = false
      }
    },
  },
}
</script>

<style scoped>
button:focus {
  @apply shadow-none;
}
h2 {
  @apply font-montserrat font-semibold text-purple text-sm leading-5;
}
.input {
  @apply w-full border border-light-grey rounded-md bg-white pl-8 pr-4 py-1;
}
.input:focus {
  @apply outline-none;
}
.icon {
  @apply w-4 mr-2 !important;
}
.prodige-draggable > * {
  @apply w-1/3;
}

.dragable-list {
  @apply border border-light-grey rounded-md p-2;
}

.dragable-list ul {
  min-height: 230px;
  max-height: 230px;
  overflow-y: auto;
}

.prodige-draggable li {
  @apply rounded-lg;
}
.prodige-draggable li:focus {
  outline: none;
}

.sortable-chosen {
  @apply bg-dark-default text-white;
}
</style>

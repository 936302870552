import action from './action.js'
import dashboard from './dashboard.js'
import label from './label.js'
import litigation from './litigation.js'
import menu from './menu.js'
import profile from './profile.js'
import setting from './setting.js'
import title from './title.js'
import apps from './apps.js'
import userManagement from './userManagement.js'
import notice from './notice.js'
import dashboardMessage from './dashboardMessage.js'
import field from './field.js'
import exportSearchResult from './exportSearchResult.js'

export default {
  fr: {
    label,
    litigation,
    notice,
    menu,
    profile,
    setting,
    action,
    dashboard,
    apps,
    userManagement,
    dashboardMessage,
    exportSearchResult,
    field,
    title: {
      ...title,
      login: 'Connexion',
      myAccount: 'Mon compte',
      updatePassword: 'Changement de mot de passe',
      requestPasswordReset: 'Commençons par trouver votre compte',
      resetPassword: 'Réinitialisation de mot de passe',
      passwordUpdateSuccess: 'Confirmation de modification du mot de passe',
      passwordCreateSuccess: 'Confirmation de création du mot de passe',
      setupAccount: 'Création de votre mot de passe',
    },
    update: {
      updateAvailable: "Une nouvelle version de l'application est disponible",
      refresh: 'Mettre à jour',
    },
    text: {
      login: 'Se connecter',
      close: 'Fermer',
      profile: 'Mon compte',
      logout: 'Se Déconnecter',
      credential: 'Identifiant',
      email: 'Saisissez votre e-mail',
      password: 'Mot de passe',
      forgottenPassword: 'Mot de passe oublié',
      welcome: 'Bienvenue !',
      youHaveBeenLogout: 'vous avez été déconnecté',
      creationdate: 'Date de création ',
      udpateddate: ' dernière modification le ',
      accountUpdatedSuccesfully: 'Modifications enregistrées',
      resetPassword: 'Réinitialiser',
      emailSent: 'Un e-mail vous a été envoyé',
      resetDone: 'Mot de passe réinitialisé avec succès',
      passwordUpdatedSuccesfully: 'Mot de passe modifié avec succès',
      createdPassword: 'Mot de passe créé avec succès',
      passwordResetSuccess:
        'Votre mot de passe a bien été modifié, merci de cliquer sur OK pour retourner à la page de connexion.',
      passwordCreateSuccess:
        'Votre mot de passe a bien été créé, merci de cliquer sur OK pour retourner à la page de connexion.',
      changePassword: 'Modifier le mot de passe',
      exportTable: 'Exporter le tableau',
      from: 'Du',
      to: 'au',
      searchOn: 'Rechercher dans',
      search: 'Recherche',
      correspondTo: 'correspond à',
      includes: 'contient',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      preview: 'Prévisualisation',
      cotationDateNone: 'non',
      cotationDate: 'Date de cotation',
      cotation: 'Cotation',
      cotationType: 'Type de cotation',
      cityToServe: 'Site à livrer',
      cotationNone: 'Inconnue',
      validatorLv1: 'Validateur niveau 1',
      validatorLv2: 'Validateur niveau 2',
      searchTooltip:
        "il est possible de faire une recherche composée avec 2 mots présents dans une chaîne avec le '%' entre 2 mots",
      help: 'Aide',
      impersonated:
        "Vous êtes en train de vous faire passer pour l'utilisateur {user},",
      clickHere: 'cliquez ici',
      impersonatedExit: 'pour quitter.',
    },
    error: {
      unknownError: 'Une erreur est survenue',
      incorrectCredential: 'Identifiants incorrects',
      refreshTokenCreationError: "Erreur lors de l'ouverture de la session",
      invalidEmail: 'Veuillez saisir un email valide',
      errorUpdateMe: 'Erreur lors de la sauvegarde des modifications',
      tokenInvalid: 'Lien de réinitialisation éxpirée ou invalide',
      passwordDoNotMatch: 'Les mots de passes ne correspondent pas',
      passwordNotComplex:
        'Le mot de passe doit faire 8 caractères, contenir une majuscule et un chiffre',
      passwordResetTokenCreationError:
        "Erreur, contacter l'administrateur du site",
      noUserFoundForThisEmail: 'Adresse e-mail incorrect',
      errorUpdatePassword: 'Erreur lors de la modification du mot de passe',
      incorrectOldPassword: 'Mot de passe actuel incorrect',
      userHasNoProfile:
        'Un problème est survenue avec votre profil utilisateur',
      tokenSessionExpired: 'votre session a expiré',
      invalidFields: 'Un ou plusieurs champs sont incorrect',
      loginInvalidFields:
        'Les informations de connexions saisies ne sont pas valides',
      unauthorized: 'Accès refusé',
      userNotFound: 'Une erreur est survenue',
      valueAlreadyInUse: 'Cette valeur est déjà utilisée',
      submitForm: "Erreur lors de l'enregistrement des données",
      anErrorOccured: "Une erreur s'est produite",
      dataTypeNumber: 'La valeur du champ doit être numérique',
      dataTypeAlphaNumeric: 'La valeur du champ doit être alphanumérique',
      requiredField: 'Ce champ est obligatoire',
      minLength: 'La taille minimale du champ est de {n}',
      maxLength:
        'La taille limite de ce champ a été atteinte | Taille limite atteinte | Taille limite atteinte {n}',
      maxLengthTextarea: 'Taille limite atteinte {n}/{z}',
      mandatoryDataMissing: 'Veuillez remplir les champs obligatoires',
      noElementSelected: 'Veuillez sélectionner un élément',
      incorrectFields: 'Les champs sont incorrects',
      missingFields: 'Veuillez remplir les champs obligatoires',
      uniqueConstraintError: 'La valeur du champ doit être unique',
      websiteUrlError: 'Le lien du site est incorrect',
      fileImport: "Erreur lors de l'import du fichier",
      inputFile: {
        invalidFileType: 'Type de fichier invalide',
        maxFileSize: 'La taille du fichier ne doit pas dépasser',
        maxFileSizeExceeded: 'Le fichier est trop lourd',
      },
      noActiveAdherent: "Vous n'avez accès à aucun adhérent actif",
      noResult: 'Aucun résultat',
      minValue: 'La valeur minimale est {n}',
      errorDownload: 'Erreur lors du téléchargement du fichier',
      passwordExpired: 'Votre mot de passe a expiré et doit être changé',
    },
    success: {
      cancelForm: 'Formulaire réinitialisé',
      fileImport: 'Fichier importé avec succès',
      submitForm: 'Enregistrement effectué avec succès',
      resetData: 'Formulaire réinitialisé',
    },
  },
}

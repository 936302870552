<template>
  <router-link
    :to="{ name: menu.routeName }"
    exact
    class="group flex items-center px-2 py-2 text-sm leading-5 font-montserrat font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none transition ease-in-out duration-150"
  >
    <span class="item-text ml-3 flex-grow whitespace-no-wrap truncate"
      ><slot
    /></span>
  </router-link>
</template>

<script>
export default {
  name: 'ProdigeSubnavitem',
  props: {
    icon: String,
    menu: {
      type: Object,
      default() {
        return {
          path: '#',
        }
      },
    },
  },
}
</script>

<style scoped>
.active,
.router-link-active,
.router-link-exact-active {
  @apply text-white bg-dark-default font-semibold;
}
.item-text {
  max-width: 15rem;
}
.active:hover,
.router-link-active:hover,
.router-link-exact-active:hover {
  @apply text-gray-600 bg-gray-200;
}
</style>

import { createRequest, handleError } from './api.js'

const request = createRequest()

export function createProfile({ label, profile_type_id, menus }) {
  return request
    .post(
      '/profile',
      {
        label,
        profile_type_id,
        menus,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function getProfile(id) {
  return request
    .get(`/profile/${id}`, {})
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function getProfiles() {
  return request
    .get('/profile', {})
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function getProfileTypes() {
  return request
    .get('/profile/type', {})
    .then(({ data }) => {
      return data
    })
    .catch(handleError)
}

export function updateProfile(data) {
  return request
    .put(
      `/profile/${data.id}`,
      {
        data,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function removeProfile(id) {
  return request
    .delete(`/profile/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

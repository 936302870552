<template>
  <!-- TODO : Disable -->
  <prodige-table
    class="prodige-datatable w-full"
    :striped="striped"
    :virtual="isVirtual"
  >
    <!-- Virtual table -->
    <template v-if="isVirtual" #vtable>
      <virtualized-list
        :items="paginatedData"
        :item-height="virutalItemHeight"
        outer-container-el="div"
        outer-container-class="table-ctn-3 relative shadow border-b border-gray-200 corner-top-left corner-top-right corner-bottom-left corner-bottom-right"
        block-container-el="table"
        :block-container-class="`${
          striped ? 'striped' : ''
        } corner-top-left corner-top-right sticky top-0`"
        header-container-el="thead"
        header-container-class="border-b border-gray-200 corner-top-left corner-top-right"
        inner-container-el="tbody"
        inner-container-class="relative"
        item-container-el="tr"
        :bench="virutalBench"
      >
        <template v-if="isVirtual" #colgroup>
          <slot name="colgroup" />
        </template>

        <template #header>
          <tr class="bg-white">
            <prodige-th v-if="selectable">
              <ab-checkbox v-model="allSelected" @input="handleSelectAll" />
            </prodige-th>
            <slot name="thead" :sortable="{ sortBy, colSorted, sortDir }" />
          </tr>
        </template>

        <template #default="provided">
          <prodige-td v-if="selectable">
            <ab-checkbox
              v-if="!provided.disabled"
              v-model="provided.selected"
              @input="displaySelectedEvent"
            />
          </prodige-td>
          <slot :item="provided" :idx="idx" />
        </template>
      </virtualized-list>
    </template>

    <!-- Natural table -->
    <!-- Colgroup -->
    <template v-if="!isVirtual" #colgroup>
      <slot name="colgroup" />
    </template>

    <tr
      v-for="(item, idx) in paginatedData"
      :key="`datatable-${item.uid || idx}`"
      :class="[
        { highlight: !!item.highlight },
        item.highlight,
        { disabled: item.disabled },
      ]"
    >
      <prodige-td v-if="selectable">
        <ab-checkbox
          v-if="!item.disabled"
          v-model="item.selected"
          @input="displaySelectedEvent"
        />
      </prodige-td>
      <slot :item="item" :idx="idx" />
    </tr>

    <!-- Header -->
    <template v-if="!isVirtual" #thead>
      <prodige-th v-if="selectable">
        <ab-checkbox v-model="allSelected" @input="handleSelectAll" />
      </prodige-th>
      <slot name="thead" :sortable="{ sortBy, colSorted, sortDir }" />
    </template>

    <!-- Pagination -->
    <template #pagination>
      <paginate
        v-if="paginate"
        v-model="pageNumber"
        :page-count="pageCount"
        prev-class="invisible"
        next-class="invisible"
        prev-text=""
        next-text=""
        :container-class="'prodige-pagination flex w-full mt-6 leading-3 item-center'"
      />
    </template>
  </prodige-table>
</template>

<script>
import { AbCheckbox } from '@ab/material-components'
import Paginate from 'vuejs-paginate'
import ProdigeTable from './prodige-table.vue'
import VirtualizedList from './VirtualScrollList.js'
import ProdigeTh from './prodige-th.vue'
import ProdigeTd from './prodige-td.vue'

export default {
  name: 'ProdigeDatatable',
  components: {
    ProdigeTable,
    AbCheckbox,
    Paginate,
    VirtualizedList,
  },
  props: {
    striped: {
      type: Boolean,
      default: true,
    },
    fixedCols: {
      type: Number,
      default: 0,
    },
    fixedRows: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    virtual: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    colSorted: {
      type: String,
      default: '',
    },
    sortDir: {
      type: String,
      default: '',
    },
    virutalItemHeight: {
      type: Number,
      default: 72,
    },
    virutalBench: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      allSelected: false,
      innerData: this.value.map((i, idx) => {
        if (typeof i === 'object') {
          this.$set(this.value[idx], 'uid', this.uid())
          if (!('selected' in i)) {
            this.$set(this.value[idx], 'selected', false)
          }
        }
        return i
      }),
      pageNumber: 1,
    }
  },
  computed: {
    paginatedData() {
      if (this.colSorted && this.sortDir) {
        const arrToSort = [...this.innerData]
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const sortedData = arrToSort.sort((a, b) => {
          let modifier = 1
          if (this.sortDir === 'desc') modifier = -1

          const aValue = this.colSorted.split('.').reduce((o, i) => o[i], a)
          const bValue = this.colSorted.split('.').reduce((o, i) => o[i], b)
          if (aValue < bValue) return -1 * modifier
          if (aValue > bValue) return 1 * modifier
          return 0

          // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        })
        if (this.paginate) {
          return sortedData.slice(
            (this.pageNumber - 1) * this.pageSize,
            this.pageNumber * this.pageSize
          )
        }
        return sortedData
      }
      if (this.paginate) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return this.innerData.slice(
          (this.pageNumber - 1) * this.pageSize,
          this.pageNumber * this.pageSize
        )
      }
      return this.innerData
    },
    pageCount() {
      return Math.ceil(this.innerData.length / this.pageSize)
    },
    /**
     * Prevent from activate virtual mode if paginate mode is enabled
     * Due to table format issue and the non sense of having pagination for optimised list rendering
     */
    isVirtual() {
      return this.virtual && !this.paginate
    },
  },
  watch: {
    value() {
      this.innerData = this.value.map((i, idx) => {
        if (typeof i === 'object') {
          this.$set(this.value[idx], 'uid', this.uid())
          if (!('selected' in i)) {
            this.$set(this.value[idx], 'selected', false)
          }
        }
        return i
      })
    },
    paginatedData() {
      this.$nextTick(() => {
        if (this.$el) this.lockColumn()
      })
    },
    fixedCols() {
      this.$nextTick(() => {
        if (this.$el) this.lockColumn()
      })
    },
  },
  mounted() {
    this.lockColumn()
  },
  methods: {
    displaySelectedEvent() {
      this.$emit('change', this.innerData)
    },
    sortBy(col) {
      // if s == current sort, reverse
      this.colSorted = col
      if (col === this.colSorted) {
        // Loop through order direction, and change the order direction
        const order = ['asc', 'desc', '']
        const sortIdx = order.indexOf(this.sortDir)
        this.sortDir =
          sortIdx === order.length - 1 ? order[0] : order[sortIdx + 1]

        // If no direction, remove the current sorted colomn
        if (!this.sortDir) this.colSorted = ''
      }
    },
    lockColumn() {
      // Reset fixed cols
      const ths = this.$el.querySelectorAll('tr th.fixed-col')
      if (ths) {
        ths.forEach((th) => th.classList.remove('fixed-col', 'last'))
      }
      const tds = this.$el.querySelectorAll('tr td.fixed-col')
      if (tds) {
        tds.forEach((td) => td.classList.remove('fixed-col', 'last'))
      }

      // Set the size of the columns to lock
      const sizes = [0]
      // Gen an array form the number of cols to lock to loop through
      const cols = this.fixedCols
        ? [...Array(this.fixedCols).keys()].map((i) => i + 1)
        : []
      // Iterate over each cols to lock to find cells on which we can apply lock css
      cols.forEach((i, index, origin) => {
        const isLastCol = index === origin.length - 1

        const th = this.$el.querySelector(`tr th:nth-child(${i})`)
        const tds = this.$el.querySelectorAll(`tr td:nth-child(${i})`)

        if (!tds.length) return

        // Get the size of the first cell, in order to get the col width
        const [{ width }, ...rest] = tds[0].getClientRects()

        // Compute the left css property we'll have to set for the sticky element to stay in place
        const fixedSize = sizes.reduce((a, b) => a + b, 0)

        tds.forEach((_, idx) => {
          const td = tds[idx]

          // Fixed TD
          td.style.left = `${fixedSize}px`
          td.classList.add('fixed-col')
          if (isLastCol) {
            td.classList.add('last')
          }
        })

        // Fixed TH
        th.style.left = `${fixedSize}px`
        th.classList.add('fixed-col')
        // Add last class
        if (isLastCol) {
          th.classList.add('last')
        }

        sizes.push(width)
      })
    },
    handleSelectAll(event) {
      this.innerData.forEach((i) => {
        const item = i
        item.selected = event
      })
      this.displaySelectedEvent()
    },
    /**
     * Generate a new random UI
     */
    uid() {
      return `_${Math.random().toString(36).substr(2, 9)}`
    },
  },
}
</script>

<style>
.prodige-datatable table {
  @apply border-separate;
}
.prodige-datatable .table-ctn-1,
.prodige-datatable .table-ctn-2 {
  width: 100%;
  max-width: 100%;
}
.prodige-datatable .table-ctn-3 {
  max-width: 100%;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
}
/* Scrollbar */
.prodige-datatable .table-ctn-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 30px;
}

.prodige-datatable .table-ctn-3::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 30px;
}

.prodige-datatable .table-ctn-3::-webkit-scrollbar-thumb {
  @apply bg-dark-default;
  border-radius: 30px;
}
/* Scrollbar */

.prodige-datatable tbody th,
.prodige-datatable tbody td {
  white-space: nowrap;
}

/* Set the table header as sticky */
.prodige-datatable tr th::before {
  content: '';
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.39);
}
.prodige-datatable th {
  position: sticky;
  top: 0;
  @apply bg-white z-20;
}

.prodige-datatable tr th.fixed-col,
.prodige-datatable tr td.fixed-col {
  @apply sticky !important;
}

.prodige-datatable tr th.fixed-col.last::after,
.prodige-datatable tr td.fixed-col.last::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  box-shadow: 2px 0px 3px 0px rgba(0, 0, 0, 0.39);
}

.prodige-datatable tr td.fixed-col {
  @apply z-10;
}
.prodige-datatable tr th.fixed-col {
  @apply z-30;
}

.prodige-datatable table tbody tr td {
  @apply bg-white;
}
/* Striped */
.prodige-datatable table.striped tbody tr:nth-child(even) td {
  @apply bg-light-grey;
}

/* Highlight */
.highlight td:first-child {
  border-left: solid 2px;
}
.highlight td:last-child {
  border-right: solid 2px;
}
.highlight > td {
  border-top: solid 2px;
  border-bottom: inset 2px;
  border-color: var(--highligh-color) !important;
}
/* Disabled */
.prodige-datatable tbody tr.disabled:hover {
  @apply cursor-default;
}
.prodige-datatable tbody tr.disabled td * {
  @apply text-dark-default !important;
}
</style>

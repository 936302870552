<template>
  <section class="prodige-multiple-draggable">
    <header class="flex items-center justify-between">
      <div class="w-1/2">
        <multiple-select
          v-model="selectedItem"
          :select-label="selectLabel"
          :placeholder="selectPlaceholder"
          :allow-empty="true"
          :multiple="true"
          :options="selectOptions"
          :label="selectLabelKey"
          :track-by="selectValueKey"
          :error="selectError"
          :limit="1"
          :limit-text="limitText"
          @input="
            selectError = false
            handleAddItemToPanel($event)
            $emit('is-dirty', true)
          "
        />
        <span v-if="selectError" class="error-msg text-red text-xs">{{
          $t('error.noElementSelected')
        }}</span>
      </div>
    </header>
    <section
      class="panel rounded-lg overflow-hidden border border-light-grey mt-4 flex"
    >
      <aside class="w-1/3 h-80 overflow-y-auto shadow bg-gray-50">
        <ul>
          <li
            v-for="panel in panels"
            :key="`tab-${panel.uid}`"
            class="hover:cursor-pointer hover:font-bold text-md py-2 px-3"
            :class="[panel.selected && 'bg-purple text-white']"
            @click="selecItem(panel)"
          >
            {{ panel[selectLabelKey] }}
          </li>
        </ul>
      </aside>
      <main class="ml-2">
        <div
          v-for="panel in panels"
          :key="`panel-${panel.uid}`"
        >
          <div v-if="panel.selected">
            <prodige-draggable
              v-model="panel.values"
              :source="computedSource(panel)"
              :value-key="subEntityValue"
              :label-key="subEntityLabel"
              :search-key="subEntityLabel"
              :source-label="sourceLabel"
              :destination-label="destinationLabel"
              :auto-fill-source="autoFill"
              :auto-fill-selected="autoFillSelected"
              @is-dirty="makeDirty"
            />
          </div>
        </div>
      </main>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { MultipleSelect } from '@ab/material-components'
import ProdigeDraggable from './prodige-draggable.vue'

export default {
  name: 'ProdigeMultipleDraggable',
  components: {
    MultipleSelect,
    ProdigeDraggable
  },
  props: {
    value: Array,
    selectLabel: {
      type: String,
      required: true
    },
    selectPlaceholder: {
      type: String,
      required: true
    },
    selectLabelKey: {
      type: String,
      required: true
    },
    selectValueKey: {
      type: String,
      required: true
    },
    selectOptions: {
      type: Array,
      required: true,
      default: () => []
    },
    subEntity: {
      type: String,
      required: true
    },
    subEntityLabel: {
      type: String,
      required: true
    },
    subEntityValue: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true
    },
    sourceLabel: {
      type: String,
      required: true
    },
    destinationLabel: {
      type: String,
      required: true
    },
    // Select all the items and subitems in multiple select (true = select, false = not select)
    autoFill: {
      type: Boolean,
      required: false,
      default: false
    },
    // Auto fill the selected item
    autoFillSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedItem: null,
      selectError: false,
      panels: []
    }
  },
  computed: {},
  watch: {
    selectOptions() {
      if (this.autoFill) {
        this.selectedItem = this.selectOptions
      }
      if (this.value.length > 0) {
        this.selectedItem = this.value
      }
    },
    selectedItem() {
      this.handleAddItemToPanel()
    },
    value() {
      this.selectedItem = this.value
    }
  },
  methods: {
    limitText(count) {
      return `et ${count} ${count === 1 ? 'autre' : 'autres'}`
    },
    computedSource(panel) {
      return panel &&
        this.selectOptions.find((select) => select.id === panel.id)
        ? this.selectOptions.find((select) => select.id === panel.id)[
            this.subEntity
          ]
        : []
    },
    handleAddItemToPanel() {
      // Check if a value is removed from selectedItem
      this.panels.forEach((panel) => {
        // Check if the return is -1, if it is we can remove it from panels
        const familyIndex = this.selectedItem.findIndex(
          (item) => item.id === panel.id
        )
        if (familyIndex === -1) {
          // Get the id of the removed selectedItem
          this.panels.splice(
            this.panels.map((family) => family.id).indexOf(panel.id),
            1
          )
        }
      })

      this.selectError = false
      if (!this.selectedItem) {
        this.selectError = true
        return
      }
      this.selectedItem.forEach((element) => {
        // Check if the selectedItem is already selected
        if (
          this.panels
            .map((panel) => panel[this.selectValueKey])
            .includes(element[this.selectValueKey])
        ) {
          return
        }
        const newItem = {
          selected: !this.panels.length,
          uid: this.uid(),
          ...element
        }
        if (!newItem.values) {
          newItem.values = element[this.subEntity]
        }
        this.panels.push(newItem)
      })
      // Vfor key need a unique ID
      this.$emit('input', this.panels)
    },
    /**
     * Generate a new random UI
     */
    uid() {
      return `_${Math.random().toString(36).substr(2, 9)}`
    },
    selecItem(panel) {
      // eslint-disable-next-line no-param-reassign
      this.panels.forEach((panel) => (panel.selected = false))
      // eslint-disable-next-line no-param-reassign
      panel.selected = true
    },
    makeDirty(e) {
      this.$emit('is-dirty', e)
    }
  }
}
</script>

<style scoped>
aside li:hover {
  cursor: pointer;
}
</style>

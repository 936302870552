<template>
  <section class="prodige-accordion">
    <dl class="accordion box" role="presentation">
      <slot />
    </dl>
  </section>
</template>

<script>
export default {
  name: 'ProdigeAccordion',
  props: {
    content: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupId: null,
    }
  },
  mounted() {
    this.groupId = this.$el.id
  },
}
</script>

<style scoped>
.accordion {
  padding: 0;
}
.accordion div:not(:last-child) {
  border-bottom: 1px solid rgba(10, 10, 10, 0.1);
}
/* .accordion div:last-child .accordion-item-details {
  border-radius: 5px;
} */
.accordion dd {
  margin-left: 0;
}
</style>

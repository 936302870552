export default {
  text: {
    type: 'Type',
    notice: 'Notification',
    date: 'Date de réception',
    deleteAll: 'Tout supprimer',
    orderValidated: 'Commande validée',
    orderRefused: 'Commande refusée',
    orderNotReadBySupplier: 'Non lu par le fournisseur dans les 90 minutes',
    orderReadBySupplier: 'Lu par le fournisseur',
    orderUnderPreparationExpires: 'Date de livraison dépassée',
    orderDeliveryDateExpired: 'Date de livraison expirée',
    orderNotReadBySupplier90min:
      'Non lu par le fournisseur dans les 90 minutes',
  },
  msg: {
    orderUnderPreparationExpires:
      'Vous avez un panier n°{orderId} en préparation dont la date de livraison demandée est dépassée',
    orderReadBySupplier:
      'Votre panier n°{orderId} du {param1} chez {param2} à été lu par le fournisseur le {param3}',
    orderNotReadBySupplier:
      'Votre panier n°{orderId} du {param1} chez {param2} n’a toujours pas été lu par le fournisseur',
    orderRefused:
      'Votre commande n°{orderId} a été refusée par le niveau {param1}',
    orderValidated:
      'Votre commande n°{orderId} a été validé par le niveau {param1}.',
  },
  modalText: {
    deleteAllElement: 'Voulez-vous supprimer toutes les notifications ?',
  },
  success: {
    noticeDeletion: 'La notification a été supprimé',
  },
  error: {
    noticeDeletion:
      'Une erreur est survenu lors de la suppression de la notification',
  },
  filter: {
    date: 'Date de réception',
    type: 'type',
  },
}

export default {
  text: {
    accessEdit: 'accès en modification',
    accessView: 'accès en visualisation uniquement',
    active: 'actif',
    askBrand: 'Demander la marque',
    askDescription: 'Demander la description du produit',
    askInvoice: 'Demander unité de commande et de facturation',
    askOrigin: "Demander l'origine",
    askProductSupplier: 'Demander le libellé fournisseur du produit',
    askSize: 'Demander le calibre',
    askTurnover: "Demander le chifre d'affaire de ce lot séparément",
    billingUnity: 'Unité de facturation',
    cartUnity: 'Unité de panier',
    catalog: 'Catalogue',
    catalogOrder: 'N° Famille',
    chr: 'chr',
    codecvp: 'Code CVP',
    coef: 'Coefficient',
    company: 'Service',
    department: 'Départment',
    departments: 'départements',
    display: 'Affichage',
    displayOrder: "ordre d'affichage",
    entry: 'Entrée',
    exit: 'Sortie',
    familyLabel: 'libellé des familles',
    familyIncluded: 'Familles incluses',
    familyNotIncluded: 'Familles non incluses',
    frsRef: 'ref frs',
    generalOptions: 'Options générales',
    id: 'id',
    identification: 'identification',
    inseeCode: 'Code INSEE',
    label: 'Libellé',
    lowerValue: 'Valeur inférieure',
    municipalityLabel: 'libellé de commune',
    name: 'Nom',
    newProfile: '(Nouveau profil)',
    noAccess: "Pas d'accès",
    noQuotation: 'Pas de cotation',
    note: 'Note',
    order: 'Ordre',
    percentage: 'Pourcentage',
    price: 'Cours',
    priceDuration: 'Durée validité prix',
    productName: 'Nom du produit',
    profile: 'Profil',
    profileGlobal: 'Back office',
    profileGlobalSubline:
      "Si vous cochez cette case, celle-ci donne accès à tous les menus de l'application",
    profileLabel: 'Libellé du profil',
    profileType: 'Type de compte',
    periodicity: 'Périodicité',
    postalCode: 'code postal',
    private: 'Privé',
    productSupplier: 'Demander le libellé fournisseur du produit',
    public: 'Public',
    purchasingHabitsText:
      "Nombre de semaines pour le calcul des habitudes d'achat",
    quotationLabel: 'libellé cotations marchés',
    quotationType: 'Type de cotation',
    rao: 'Réponse RAO',
    rs: 'rs',
    setting: 'Paramétrage',
    selectSetting: 'Sélectionner le paramétrage',
    sortingOrder: 'Ordre de tri',
    status: 'Statut',
    storageManagementUnity: 'Unité de gestion',
    subfamilyLabel: 'libellé sous-famille',
    supplierLabel: 'Libellé fournisseur',
    supplierName: 'Nom du fournisseur',
    supplierRef: 'Référence fournisseur',
    taxDisplayText: 'Si cette case est cochée, on affiche les cotations en TTC',
    type: 'Type',
    zoneLabel: 'Libellé de la zone',
    logo: 'Logo',
    image: 'Image',
    message: 'Message',
    msgObject: 'Objet du message',
    msgText: 'Texte du message',
    rate: 'Taux',
    upperValue: 'Valeur supérieure ou égale',
    zipCode: 'Code postal',
    mealNumber: 'Repas',
    weeks: 'semaines',
    title: 'Titre',
    newDocument: 'Nouveau document',
    documentsListing: 'Listing documents',
    imageFileInputLabel: 'Image',
    fileInputLabel: 'Libellé du document',
    pageTitle: 'Titre de la page',
    negotiation: 'Unité de Negociation',
    authorization: 'Autorisations'
  },
  modelLabel: {
    adherent_type: "Libellé des types d'adhérents",
    brand: 'Libellé des marques',
    catalog: 'Libellé des catalogues',
    civility: 'Libellé des civilités',
    client_meal: 'Libellé',
    document_type: 'Libellé des types de document',
    establishment_status: 'Libellé des statuts',
    establishment_type: "Libellé des types d'établissement",
    label: 'Libellé des labels',
    litigation_type: 'Libellé des litiges',
    meal: 'Libellé des types de repas',
    origin: 'Libéllé des origines',
    preservation: 'Libellé des modes de conservation',
    role: 'Libellé des qualités',
    storage_process: 'Libellé',
    unity: 'Libellé des unités',
    procedure: 'Libellé des procédures',
    deposit: 'Consigne',
    adherent_status: "Libellé des statuts d'adhérent",
    order_Taxe: 'Libellé des taxes',
  },
  modalText: {
    cannotDeleteSubfamily:
      'Vous ne pouvez pas supprimer cette sous-famille car elle est liée à des produits',
    cannotDeleteFamily:
      'Vous ne pouvez pas supprimer cette famille car elle est liée à des produits',
    deleteSelectedElement: "Voulez-vous supprimer l'élément sélectionné ?",
    deleteProfile: 'Voulez-vous supprimer le profil ?',
    deletion: 'Suppression',
    message: 'Message',
    productCreate: 'Ajouter Produit hors catalogue',
    productEdit: 'Editer Produit hors catalogue',
    productUnityPrice: 'Unités et prix produit',
    supplierCreate: 'Ajouter Fournisseur hors catalogue',
    supplierEdit: 'Editer Fournisseur hors catalogue',
  },
  option: {
    clientMeal: 'Client repas',
    family: 'Sélection des familles',
    generalOptions: 'Options générales',
    mealType: 'Type de repas',
    orderLevel: 'Niveau de commande',
    productNotInCatalog: 'Produits hors catalogue',
    storageProcess: 'Motif opération de stock',
    supplierNotInCatalog: 'Fournisseurs hors catalogue',
    orderTax: 'Taxe, consigne ou forfait',
  },
  information: {
    client_meal:
      'Créez les motifs qui apparaîtront dans vos choix de motif de gestion de client repas',
    family:
      'Sélectionnez ici les familles avec lesquelles vous voulez travailler. Vous pourrez ensuite sélectionner par famille les fournisseurs que vous voulez référencer.',
    meal: 'Vous pouvez ici ajouter des types de repas qui vous permettront de typer vos sorties de stock',
    product:
      'Vous pouvez ici ajouter des produits non présents dans votre catalogue',
    storage_process:
      "Créez les motifs qui apparaîtront dans vos choix de motif de gestion d'entrée et de sortie de produit en stock",
    supplier: 'Vous pouvez ici ajouter des fourniseurs non présents',
    order_Taxe: 'Vous pouvez ici ajouter des taxes, consignes ou forfaits',
  },
  error: {
    deletion: "Erreur lors de la suppression de 'élément",
    submitForm: "Erreur lors de l'enregistrement",
    update: 'Erreur lors de la mise à jour',
    noteRange: 'La note doit être comprise entre 0 et 20',
  },
  success: {
    messageCreation: 'Message créé avec succès',
    messageDeletion: 'Message supprimé',
    messageUpdate: 'Message modifié avec succès',
    profileCreation: 'Profil créé avec succès',
    profileUpdate: 'Profil modifié avec succès',
    deletion: 'Élément supprimé',
    settingCreation: 'Paramètre créé avec succès',
    submitForm: 'Enregistrement effectué avec succès',
    subfamilyCreation: 'La sous-famille a été créée',
    update: 'Les données ont été enregistrées',
    validate: 'Les données ont été  validées',
  },
}

import Vue from 'vue'
import Vuex from 'vuex'

class SingletonAuthEvent {
  static getInstance() {
    if (!SingletonAuthEvent.instance) {
      Vue.use(Vuex)
      SingletonAuthEvent.instance = new Vue({
        store: new Vuex.Store({
          state: {
            permissions: [],
          },
          mutations: {
            setPermissions(state, permissions) {
              state.permissions = permissions
            },
          },
          getters: {
            permissions: (state) => state.permissions,
          },
          actions: {
            setPermissions({ commit }, permissions) {
              commit('setPermissions', permissions)
            },
            getPermissions() {
              return state.permissions
            },
          },
        }),
      })
    }
    return SingletonAuthEvent.instance
  }
}

export default SingletonAuthEvent

import {
  createProfile,
  getProfile,
  getProfiles,
  getProfileTypes,
  updateProfile,
  removeProfile,
} from '../api/profile.js'

export default {
  state: {
    profile: {},
    profiles: [],
    types: [],
    newEntity: null,
  },
  mutations: {
    createProfile(state, profile) {
      state.newEntity = profile
    },
    getProfile(state, profile) {
      state.profile = profile
    },
    getProfiles(state, profiles) {
      state.profiles = profiles
    },
    getProfileTypes(state, types) {
      state.types = types
    },
    updateProfile(state, profile) {
      state.newEntity = profile
    },
    removeProfile(state, profile) {
      state.newEntity = profile
    },
  },
  actions: {
    async createProfile({ commit }, profileData) {
      const data = await createProfile(profileData)
      if (data) {
        commit('createProfile', data)
      }
      return data
    },
    async getProfile({ commit }, profileId) {
      const data = await getProfile(profileId)
      if (data) {
        commit('getProfile', data)
      }
      return data
    },
    async getProfiles({ commit }) {
      const data = await getProfiles()
      if (data) {
        commit('getProfiles', data)
      }
      return data
    },
    async getProfileTypes({ commit }) {
      const data = await getProfileTypes()
      if (data) {
        commit('getProfileTypes', data)
      }
      return data
    },
    async updateProfile({ commit }, profileData) {
      const data = await updateProfile(profileData)
      if (data) {
        commit('updateProfile', data)
      }
      return data
    },
    async removeProfile({ commit }, profileId) {
      const data = await removeProfile(profileId)
      if (data) {
        commit('removeProfile', data)
      }
      return data
    },
  },
}

// Components

// Mask input
import VueTheMask from 'vue-the-mask'
//  Layout
import ProdigeLayout from './src/components/layouts/prodige-layout.vue'
import prodigeSidenav from './src/components/layouts/prodige-sidenav.vue'
import ProdigeTitle from './src/components/layouts/prodige-title.vue'
import ProdigeTag from './src/components/layouts/prodige-tag.vue'
//    Table
import ProdigeTable from './src/components/layouts/table/prodige-table.vue'
import ProdigeDatatable from './src/components/layouts/table/prodige-datatable.vue'
import ProdigeDataTh from './src/components/layouts/table/prodige-data-th.vue'
import ProdigeTh from './src/components/layouts/table/prodige-th.vue'
import ProdigeTd from './src/components/layouts/table/prodige-td.vue'
//    Tabs
import ProdigeTabs from './src/components/layouts/tabs/prodige-tabs.vue'
import ProdigeTab from './src/components/layouts/tabs/prodige-tab.vue'
//    Steps
import ProdigeSteps from './src/components/layouts/steps/prodige-steps.vue'
// import ProdigeSteps from './src/components/layouts/steps/prodige-steps.vue';
//    Loader
import ProdigeLoader from './src/components/layouts/loader/prodige-loader.vue'
//  Cards
import ProdigeCard from './src/components/cards/prodige-card.vue'
import ProdigeDetailHead from './src/components/cards/prodige-detail-head.vue'
import ProdigeScrollingCard from './src/components/cards/prodige-scrolling-card.vue'
//    History
import ProdigeHistory from './src/components/cards/prodige-history.vue'
// Forms/buttons
import ProdigeButton from './src/components/forms/buttons/prodige-button.vue'
import ProdigeAddButton from './src/components/forms/buttons/prodige-add-button.vue'
import ProdigeExportButton from './src/components/forms/buttons/prodige-export-button.vue'
// Forms/fileinput
import ProdigeFileInput from './src/components/forms/inputs/file/prodige-file-input.vue'
import ProdigeInputTypeFile from './src/components/forms/inputs/file/prodige-input-type-file.vue'
import ProdigeTextarea from './src/components/forms/inputs/file/prodige-textarea.vue'
// Forms/draggable
import ProdigeDraggable from './src/components/forms/draggable/prodige-draggable.vue'
import ProdigeMultipleDraggable from './src/components/forms/draggable/prodige-multiple-draggable.vue'
// Forms/search
import ProdigeSearch from './src/components/forms/search/prodige-search.vue'
// Forms/input/radio
import ProdigeRadio from './src/components/forms/inputs/radio/prodige-radio.vue'
import ProdigeFamilyCard from './src/components/forms/inputs/radio/prodige-family-card.vue'
// Icons
import ProdigeCompany from './src/components/icons/prodige-company.vue'
// Accordion
import ProdigeAccordion from './src/components/layouts/accordion/prodige-accordion.vue'
import ProdigeAccordionItem from './src/components/layouts/accordion/prodige-accordion-item.vue'
// Tooltip
import ProdigeTooltip from './src/components/layouts/prodige-tooltip.vue'

// Views
import Login from './src/views/Login.vue'
import RequestPasswordReset from './src/views/Profile/RequestPasswordReset.vue'
import ResetPassword from './src/views/Profile/ResetPassword.vue'

// Routing
import {
  routes,
  isLogged,
  initNProgress,
  getMenu,
  scrollBehavior,
  publicRoutes,
  checkAppAccess,
} from './src/router/index.js'

// Store
import appStore from './src/store/app.js'
import searchStore from './src/store/search.js'
import notificationStore from './src/store/notification.js'
import authenticationStore from './src/store/authentication.js'
import uiStore from './src/store/ui.js'
import menuStore from './src/store/menu.js'
import loaderStore from './src/store/loader.js'
import profileStore from './src/store/profile.js'
import fileStore from './src/store/file.js'
import supplier from './src/store/supplier.js'
import platform from './src/store/platform.js'
import setting from './src/store/setting.js'
import userManagement from './src/store/userManagement.js'
import status from './src/store/status.js'
import history from './src/store/history.js'
import dashboardMessage from './src/store/dashboardMessage.js'
import exportSearchResult from './src/store/exportSearchResult.js'

// i18n
import translations from './src/i18n/translation.js'

// APIs
import * as helpers from './src/api/api.js'
import * as appApi from './src/api/app.js'
import * as authenticationApi from './src/api/authentication.js'
import * as menuApi from './src/api/menu.js'
import * as profileApi from './src/api/profile.js'
import * as searchApi from './src/api/search.js'
import * as userManagementApi from './src/api/userManagement.js'
import * as statusApi from './src/api/status.js'
import * as historyApi from './src/api/history.js'
import * as exportSearchResultApi from './src/api/exportSearchResult.js'

// Mixins
import notificationMixin from './src/mixins/notification.js'
import loaderMixin from './src/mixins/loader.js'
import formMixin from './src/mixins/form.js'
import paginationMixin from './src/mixins/pagination.js'
import sortableMixin from './src/mixins/sortable.js'
import webUrlVerificationMixin from './src/mixins/webUrlVerification.js'
import * as searchMixin from './src/mixins/search.js'
import datepickerMixin from './src/mixins/datepicker.js'
import duplicateMixin from './src/mixins/duplicate.js'
import stepMixin from './src/mixins/step.js'
import constantsMixin from './src/mixins/constants.js'
import phoneVerification from './src/mixins/phoneVerification.js'
import breadscrumbMixin from './src/mixins/breadscrumb.js'
import selectMixin from './src/mixins/select.js'
import selectedAdherentMixin from './src/mixins/selectedAdherent.js'
import rulesMixin from './src/mixins/rules.js'
import authorizationMixin from './src/mixins/authorization.js'

// Assets
import { deepMerge, isObject } from './src/assets/js/helpers.js'

// Bus service for authorization
import SingletonAuthEvent from './src/services/AuthBusService.js'

// VModal
// import VModal from 'vue-js-modal';

const components = {
  ProdigeLayout,
  prodigeSidenav,
  ProdigeCard,
  ProdigeButton,
  ProdigeTable,
  ProdigeDatatable,
  ProdigeDataTh,
  ProdigeTh,
  ProdigeTd,
  ProdigeTabs,
  ProdigeTab,
  ProdigeLoader,
  ProdigeCompany,
  ProdigeTitle,
  ProdigeTag,
  ProdigeAccordion,
  ProdigeAccordionItem,
  ProdigeTooltip,
  ProdigeFileInput,
  ProdigeHistory,
  ProdigeDraggable,
  ProdigeDetailHead,
  ProdigeAddButton,
  ProdigeExportButton,
  ProdigeTextarea,
  ProdigeSearch,
  ProdigeFamilyCard,
  ProdigeRadio,
  ProdigeScrollingCard,
  ProdigeSteps,
  ProdigeMultipleDraggable,
  ProdigeInputTypeFile,
  VueTheMask,
}
const views = {
  Login,
  RequestPasswordReset,
  ResetPassword,
}

const stores = {
  app: appStore,
  search: searchStore,
  notification: notificationStore,
  authentication: authenticationStore,
  ui: uiStore,
  menu: menuStore,
  loader: loaderStore,
  profile: profileStore,
  file: fileStore,
  supplier,
  platform,
  setting,
  userManagement,
  status,
  history,
  exportSearchResult,
  dashboardMessage,
}

const routers = {
  routes,
  guards: {
    isLogged,
    publicRoutes,
  },
  helpers: {
    initNProgress,
    getMenu,
    scrollBehavior,
    checkAppAccess,
  },
}

const apis = {
  helpers,
  services: {
    app: appApi,
    authentication: authenticationApi,
    menu: menuApi,
    profile: profileApi,
    search: searchApi,
    userManagement: userManagementApi,
    status: statusApi,
    history: historyApi,
    exportSearchResult: exportSearchResultApi,
  },
}

const mixins = {
  notification: notificationMixin,
  loader: loaderMixin,
  form: formMixin,
  pagination: paginationMixin,
  sortable: sortableMixin,
  webUrlVerification: webUrlVerificationMixin,
  phoneVerification,
  search: searchMixin,
  datepicker: datepickerMixin,
  duplicate: duplicateMixin,
  step: stepMixin,
  constants: constantsMixin,
  breadscrumb: breadscrumbMixin,
  select: selectMixin,
  selectedAdherent: selectedAdherentMixin,
  rules: rulesMixin,
  authorization: authorizationMixin,
}

const assets = {
  deepMerge,
  isObject,
}

const authorizationEventBus = SingletonAuthEvent.getInstance()

export {
  components,
  views,
  stores,
  routers,
  translations,
  apis,
  mixins,
  assets,
  authorizationEventBus,
}

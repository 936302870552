import { search, searchProducts } from '../api/search.js'

export default {
  state: {
    products: [],
    searchResults: [],
  },
  getters: {
    searchResults: (state) => state.searchResults,
  },
  mutations: {
    search(state, searchResults) {
      state.searchResults = searchResults
    },
    searchProducts(state, products) {
      state.products = products
    },
  },
  actions: {
    async getSearchResult({ commit }, params) {
      const data = await search(params)
      if (data !== false) {
        commit('search', data)
      }
      return data
    },
    async searchProducts({ commit }, params) {
      const data = await searchProducts(params)
      if (data !== false) {
        commit('search', data)
      }
      return data
    },
  },
}

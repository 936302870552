import {
  updateDashboardMessage,
  deleteDashboardMessage,
  createDashboardMessage,
  getDashboardMessage,
  getDashboardMessageTypes,
  getDashboardMessagesByAdherent,
} from '../api/dashboardMessage.js'

export default {
  namespaced: true,
  state: {
    messages: [],
    message: {},
    types: [],
    newEntity: null,
  },

  mutations: {
    getTypes(state, types) {
      state.types = types
    },
    getMessagesByAdherent(state, messages) {
      state.messages = messages
    },
    getMessage(state, message) {
      state.message = message
    },
    createMessage(state, message) {
      state.newEntity = message
    },
    updateMessage(state, message) {
      state.newEntity = message
    },
    deleteMessage(state, message) {
      state.newEntity = message
    },
  },
  actions: {
    async getTypes({ commit }) {
      const data = await getDashboardMessageTypes()
      if (data !== false) {
        commit('getTypes', data)
      }
      return data
    },
    async getMessagesByAdherent({ commit }, params) {
      const data = await getDashboardMessagesByAdherent(params)
      if (data !== false) {
        commit('getMessagesByAdherent', data)
      }
      return data
    },
    async getMessage({ commit }, messageId) {
      const data = await getDashboardMessage(messageId)
      if (data !== false) {
        commit('getMessage', data)
      }
      return data
    },
    async createMessage({ commit }, messageData) {
      const data = await createDashboardMessage(messageData)
      if (data !== false) {
        commit('createMessage', data)
      }
      return data
    },
    async updateMessage({ commit }, messageData) {
      const data = await updateDashboardMessage(messageData)
      if (data !== false) {
        commit('updateMessage', data)
      }
      return data
    },
    async deleteMessage({ commit }, messageId) {
      const data = await deleteDashboardMessage(messageId)
      if (data !== false) {
        commit('deleteMessage', data)
      }
      return data
    },
  },
}

<template>
  <section class="flex relative">
    <!-- input file -->
    <file-pond
      ref="pond"
      :class="preview ? 'w-4/5' : 'w-full'"
      :name="name"
      :label-idle="label"
      :allow-multiple="multiple"
      :allow-remove="true"
      :max-file-size="maxFileSize"
      :label-file-type-not-allowed="$t('error.inputFile.invalidFileType')"
      file-validate-type-label-expected-types=""
      :label-max-file-size-exceeded="$t('error.inputFile.maxFileSizeExceeded')"
      :label-max-file-size="`${$t(
        'error.inputFile.maxFileSize'
      )} ${maxFileSize}`"
      :allow-file-type-validation="true"
      :accepted-file-types="acceptedFileTypes"
      credits="false"
      :check-validity="true"
      @addfile="loadFile"
      @removefile="removeFile"
    />
    <!-- Image preview -->
    <div v-if="preview" class="w-20 ml-4">
      <div
        v-if="file && fileType && preview"
        class="h-full hover-trigger relative object-contain rounded-full overflow-hidden"
      >
        <div v-if="fileType === 'application/pdf'" class="h-full">
          <ab-icon icon="pdf" class="preview-icon" />
        </div>
        <div v-if="fileType === 'application/msword'" class="h-full">
          <ab-icon icon="msword" class="preview-icon" />
        </div>
        <div
          v-if="fileType === 'image/jpeg' || fileType === 'image/png'"
          class="h-full"
        >
          <img
            :src="imagePath"
            :alt="$t('text.preview')"
            @error="getDefaultImage"
          />
        </div>
        <!-- Close button -->
        <div
          class="hover-target absolute inset-0 cursor-pointer flex justify-center items-center bg-black bg-opacity-50 text-white w-full h-full focus:outline-none"
        >
          <div
            v-if="imagePath"
            class="flex justify-center items-center w-6 h-6"
            @click="removeFile"
          >
            <ab-icon icon="close" iconset="file-input" />
          </div>
        </div>
      </div>
    </div>

    <ab-iconset iconset="file-input">
      <g id="close">
        <path
          d="M16.2 12l6.9-6.9c1.2-1.2 1.2-3 0-4.2-1.2-1.2-3-1.2-4.2 0L12 7.8 5.1.9C3.9-.3 2.1-.3.9.9c-1.2 1.2-1.2 3 0 4.2L7.8 12 .9 18.9c-1.2 1.2-1.2 3 0 4.2.6.6 1.2.9 2.1.9.9 0 1.5-.3 2.1-.9l6.9-6.9 6.9 6.9c.6.6 1.2.9 2.1.9.9 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3 0-4.2L16.2 12z"
        />
      </g>
    </ab-iconset>
  </section>
</template>

<script>
import { AbIcon, AbIconset } from '@ab/material-components'
import '../../../../assets/styles/filepond.css'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

const FilePond = vueFilePond(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
)

export default {
  name: 'ProdigeFileInput',
  components: {
    AbIcon,
    AbIconset,
    FilePond,
  },
  props: {
    name: {
      type: String,
      default: 'filepond',
    },
    files: {
      type: [Array, Object, File],
      default: () => [],
    },
    label: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    acceptedFileTypes: Array,
    maxFileSize: [String, Number],
    preview: {
      type: Boolean,
      default: true,
    },
    removeOnAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      fileType: null,
      imagePath: '',
      isDefaultFile: false,
    }
  },
  computed: {
    disabled() {
      // If file is not null, return true, false else
      return !!this.file
    },
  },
  watch: {
    files() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // If parent component sends file array, then fill imagePath
      if (!this.multiple) {
        this.file = this.files
        this.fileType = this.file ? this.file.mime_type : null
        this.imagePath =
          this.file &&
          typeof this.file !== 'undefined' &&
          Object.keys(this.file).length
            ? `${process.env.VUE_APP_ROOT_API}/upload${this.file.path}/${this.file.unique_name}`
            : ''
      } else if (this.files.length && typeof this.files[0] !== 'undefined') {
        this.file = this.files.map((file) => ({ ...file }))
        this.imagePath = `${process.env.VUE_APP_ROOT_API}/upload${this.files[0].path}/${this.files[0].unique_name}`
      } else {
        this.imagePath = ''
      }
    },
    loadFile(e) {
      // If there is an upload error (ex: file too large)
      if (e) {
        this.$emit('addfile', { error: 'true', message: e.main })
        return
      }
      if (!this.$refs.pond.getFile()) return
      if (!this.multiple) {
        this.file = this.$refs.pond.getFile().file
        this.fileType = this.file.type
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          () => {
            this.imagePath = reader.result
          },
          false
        )

        reader.readAsDataURL(this.file)
        this.isDefaultFile = false
        this.$emit('addfile', this.$refs.pond.getFile().file)
      } else {
        this.file = this.$refs.pond.getFiles().map((f) => f.file)

        this.file.map((f) => {
          this.fileType = f.type
          const reader = new FileReader()

          reader.addEventListener(
            'load',
            () => {
              this.imagePath = reader.result
            },
            false
          )

          reader.readAsDataURL(f)
          this.isDefaultFile = false
          return null
        })
        this.$emit('addfile', this.file)
      }
      if (this.removeOnAdd) {
        this.$refs.pond.removeFile()
      }
    },
    removeFile() {
      if (!this.file) return

      this.imagePath = ''
      if (!this.multiple) {
        this.$refs.pond.removeFiles()
        this.$emit('removefile', this.file)
        this.file = null
      } else if (this.files.length && typeof this.files[0] !== 'undefined') {
        this.file = this.$refs.pond.getFiles().map((f) => f.file)
        this.$emit('removefile', this.file)
      }
    },
    getDefaultImage(e) {
      this.isDefaultFile = true
      this.imagePath =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAE0lEQVR42mNs+MOAFzCOKhhJCgDzbxfRtycBOQAAAABJRU5ErkJggg=='
    },
  },
}
</script>

<style scoped>
.hover-trigger .hover-target {
  opacity: 0;
}

.hover-trigger:hover .hover-target {
  opacity: 1;
}

.preview-icon {
  width: 100%;
  height: 100%;
}
</style>

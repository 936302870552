import { createRequest, handleError } from './api.js'

const request = createRequest()

export function deletePlatform(id) {
  return request
    .delete(`/platform/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updatePlatform(platform) {
  return request
    .put(
      `/platform/${platform.id}`,
      {
        ...platform,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function createPlatform(platform) {
  return request
    .post(
      '/platform/',
      {
        ...platform,
      },
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function getPlatform(id) {
  return request
    .get(`/platform/${id}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getPlatformsBySupplierId(supplierId) {
  return request
    .get(`/platform/supplier/${supplierId}`, {
      headers: {
        // 'authorization': store.getters.getToken
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getPlatformsBySupplierAndAdherentId(params) {
  return request
    .get(
      `/platform/supplier/${params.supplierId}/adherent/${params.adherentId}`,
      {
        headers: {
          // 'authorization': store.getters.getToken
        },
      }
    )
    .then(({ data }) => data)
    .catch(handleError)
}

export function getPlatforms() {
  return request
    .get('/platform/')
    .then(({ data }) => data)
    .catch(handleError)
}

<template>
  <section
    class="prodige-textarea"
    :class="{ 'has-label': label, disabled, mandatory: required, error: error }"
    :disabled="disabled"
  >
    <label v-show="label">{{ label }}</label>
    <textarea
      ref="input"
      :value="value"
      class="prodige-textarea border-light-grey rounded-md border border-solid"
      :class="{
        'has-label': label,
        disabled,
        mandatory: required,
        error: error,
      }"
      :disabled="disabled"
      :error="error"
      :name="name"
      :maxResizeColums="maxResizeColums"
      :placeholder="placeholder"
      :style="resizeStyle"
      @input="
        $emit('input', $event.target.value)
        resize($event)
      "
      @focus="
        $emit('focus')
        resize($event)
      "
    />
  </section>
</template>

<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'ProdigeTextarea',
  props: {
    label: {
      type: String,
    },
    textLimit: {
      required: false,
      type: Number,
    },
    autoResize: {
      required: false,
      type: Boolean,
    },
    // Max Height of the textarea
    maxHeight: {
      required: false,
      type: Number,
      default: 400,
    },
    name: String,
    placeholder: String,
    value: {
      required: false,
      type: String,
    },
    disabled: Boolean,
    error: Boolean,
    errorMessage: String,
    required: Boolean,
  },
  data() {
    return {
      init: true,
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue && newValue.length > this.textLimit)
        this.$emit('input', oldValue)
    },
  },
  methods: {
    resize(e) {
      if (this.init) {
        e.target.style.height = 'auto'
        e.target.style.height = `${e.target.scrollHeight}px`
        this.init = false
      }
      if (this.autoResize) {
        if (e.target.scrollHeight <= this.maxHeight) {
          e.target.style.height = 'auto'
          e.target.style.height = `${e.target.scrollHeight}px`
        }
      }
    },
  },
}
</script>

<style scoped>
.textarea.mandatory .textarea__placeholder::after {
  display: inline-block;
  margin-left: 0.1rem;
  line-height: 1rem;
  font-size: 1rem;
  color: var(--prodige-textarea-color, #f44336);
  content: '*';
  vertical-align: middle;
}
/* LABEL */
.prodige-textarea.has-label {
  position: relative;
}
.prodige-textarea.has-label label {
  font-size: 0.7rem;
  color: var(--prodige-textarea-active-color, #4c515d);
  font-weight: light;
  pointer-events: none;
  top: -16px;
  white-space: nowrap;
  z-index: 20;
}
.prodige-textarea.disabled.has-label label {
  color: var(--select-text-color, #d2d6dc);
}

.prodige-textarea.mandatory.has-label label::after {
  display: inline-block;
  margin-left: 0.1rem;
  line-height: 1rem;
  font-size: 1rem;
  color: var(--prodige-textarea-color, #f44336);
  content: '*';
  vertical-align: middle;
}
.prodige-textarea.disabled.mandatory.has-label label::after {
  color: var(--select-text-color, #4c515d);
}

/* Error state */
textarea.error {
  border-color: var(--textarea-error-border-color, #f44336);
}

textarea {
  width: 100%;
  padding: 8px 8px 85px 8px;
  color: var(--textarea-color, #4c515d);
  font-size: 0.875rem;
  box-sizing: border-box;
  outline: none;
}
</style>

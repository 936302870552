<template>
  <label class="prodige-radio inline-block">
    <div class="flex justify-between items-center" :class="`mark-${direction} ${disabled ? 'disabled' : ''}`">
      <input
        class="cursor-pointer"
        type="radio"
        :name="name"
        :checked="isChecked"
        :value="value"
        :disabled="disabled"
        @change="$emit('change', $event.target.value)"
      />
      <div class="checkmark" :class="[{ checked: isChecked }]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
      <span class="label font-poppins-medium text-dark-grey"><slot /></span>
    </div>
  </label>
</template>

<script>
export default {
  name: 'ProdigeRadio',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    checked: [String, Number],
    value: [String, Number],
    direction: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].indexOf(value) !== -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isChecked() {
      // eslint-disable-next-line eqeqeq
      return this.checked == this.value
    },
  },
}
</script>

<style scoped>
/* @see https://codepen.io/hansmaad/pen/zBdzxp */
input[type='radio'] {
  display: none;
}

.prodige-radio {
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkmark {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  @apply bg-light-grey-menu-top text-white;
}

/* Active state */
.checkmark.checked {
  background-color: #b4e7dc;
  color: #3f8a79;
}

input[type='radio']:checked + .checkmark {
  animation: ripple 0.2s linear forwards;
}

/* Ripple effect */
@keyframes ripple {
  0% {
    /* transform: scale(0); */
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    /* transform: scale(1); */
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}
/* Mark position */
.mark-left {
  @apply flex-row;
}
.mark-left .label {
  @apply ml-2;
}
.mark-right {
  @apply flex-row-reverse;
}
.mark-right .label {
  @apply mr-2;
}

</style>

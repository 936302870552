<template>
  <label
    class="prodige-family-card bg-white inline-block rounded-lg p-2 cursor-pointer"
    :class="[{ checked: isChecked }]"
  >
    <header class="flex justify-between items-center">
      <span class="font-poppins-bold text-dark-grey"
        >{{ family.family_order }}. {{ family.family_label }}</span
      >
      <input
        class="cursor-pointer"
        type="radio"
        :name="name"
        :checked="isChecked"
        :value="value"
        @change="$emit('change', $event.target.value)"
      />
      <div class="checkmark">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
      </div>
    </header>
    <main class="values">
      <div class="row">
        <div class="value-group">
          <div class="label">{{ $t('text.cotation') }}</div>
          <div class="value">
            {{ family.number_articles }}/{{ family.number_products }}
          </div>
        </div>
        <div class="value-group">
          <div class="label">{{ $t('text.cityToServe') }}</div>
          <div class="value">{{ family.number_delivered_cities }}</div>
        </div>
      </div>
      <div class="row">
        <div class="value-group">
          <div class="label">{{ $t('text.cotationType') }}</div>
          <div class="value">{{ family.quotation_label }}</div>
        </div>
      </div>
      <div class="row">
        <div class="value-group">
          <div class="label">{{ $t('text.cotationDate') }}</div>
          <div class="value">{{ new Date(family.updatedAt) | date }}</div>
        </div>
        <!-- <div class="value-group">
          <div class="label">Intégrée le</div>
          <div class="value">12/12/2018</div>
        </div> -->
      </div>
    </main>
  </label>
</template>

<script>
export default {
  name: 'ProdigeFamilyCard',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: 'prodige-family-card',
    },
    checked: [String, Number],
    value: [String, Number],
    family: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isChecked() {
      // eslint-disable-next-line eqeqeq
      return this.checked == this.value
    },
  },
  methods: {},
}
</script>

<style scoped>
/* @see https://codepen.io/hansmaad/pen/zBdzxp */
input[type='radio'] {
  display: none;
}

.prodige-family-card {
  border: solid 1px #ffffff;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.checkmark {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
  background-color: var(--prodige-mark-background, #bdbdbd);
  color: var(--prodige-mark-color, #ffffff);
}

/* Active state */

.prodige-family-card.checked {
  border-color: #279989;
  box-shadow: 0px 4px 4px #b4e7dc;
}
.prodige-family-card.checked .checkmark {
  background-color: var(--prodige-checked-mark-background, #b4e7dc);
  color: var(--prodige-checked-mark-color, #3f8a79);
}

input[type='radio']:checked + .checkmark {
  animation: ripple 0.2s linear forwards;
}

/* Ripple effect */
@keyframes ripple {
  0% {
    /* transform: scale(0); */
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    /* transform: scale(1); */
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}

.row .value-group:nth-child(even) {
  @apply text-right ml-2;
}

.row {
  @apply flex items-center justify-between mt-2;
}

.label {
  @apply font-poppins-medium leading-3 text-xs italic text-middle-dark-grey;
}
.value {
  @apply font-poppins-bold text-sm italic text-dark-default;
}
</style>

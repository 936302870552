<template>
  <div class="prodige-layout h-screen flex overflow-hidden bg-gray-100">
    <div
      id="main-container"
      class="flex flex-col w-0 flex-1 overflow-y-auto"
      :class="[spinner.loading && 'hide']"
    >
      <!-- Nav bar -->
      <div
        class="sticky top-0 z-50 flex-shrink-0 flex h-16 bg-white border-b-8 border-purple"
      >
        <div
          class="relative z-50 w-64 max-w-6xl bg-purple transition-with duration-75"
          :class="{ shrink: !sideNavOpened }"
        >
          <router-link
            to="/"
            class="h-24 px-4 pt-2 pb-6 cursor-pointer"
            :src="Logo"
            alt="Application logo"
            tag="img"
          />
        </div>
        <div class="relative z-40 flex-1 px-4 flex justify-between">
          <div class="flex-1 flex justify-center">
            <!-- Application nav -->
            <prodige-appnav />
          </div>

          <!-- User current adherent view -->
          <!--
            Only display the selector if :
            - We are on front-office
            - The current logged user has the adherent profile
            - The current logged user has access to at least 2 active adherents
           -->
          <div
            v-if="isCurrentAppFrontOffice && isCurrentUserAdherent"
            class="w-60"
          >
            <multiple-select
              v-model="selectedAdherent"
              class="right adherent-selector"
              v-bind="
                displayGroup && userAdherents.some((item) => item.options)
                  ? groupProps
                  : null
              "
              :placeholder="$t('label.adherentPlaceholder')"
              :allow-empty="false"
              :multiple="false"
              :options="userAdherents"
              label="name"
              track-by="id"
              @input="selectCurrentAdherent"
            >
              <template #option="{ props: { option } }">
                <div v-if="option.$groupLabel" class="p-4 bg-orange">
                  {{ option.$groupLabel }}
                </div>
                <div v-else>
                  <div
                    class="p-4"
                    :class="
                      !option.isGroup
                        ? 'pl-6'
                        : 'group pl-4 bg-gray-100 font-bold'
                    "
                  >
                    <span v-if="!option.isGroup" class="text-2xl">↳</span
                    ><span class="ml-2">{{ option.name }}</span>
                  </div>
                </div>
              </template>
            </multiple-select>
          </div>

          <div class="ml-4 flex items-center z-10 md:ml-6">
            <!-- Profile dropdown -->
            <div ref="userMenu" class="ml-3 relative">
              <div>
                <button
                  id="user-menu"
                  class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow"
                  aria-label="User menu"
                  aria-haspopup="true"
                  @click="toggleUserMenu"
                >
                  <img
                    ref="avatar"
                    class="h-8 w-8 rounded-full"
                    :src="userAvatar"
                    :alt="`${user.first_name} ${user.last_name}`"
                    @error="handleAvatarError"
                  />
                  <span class="inline-block ml-2"
                    >{{ user.first_name }} {{ user.last_name }}</span
                  >
                  <ab-icon
                    class="transition-transform duration-300"
                    :class="[displayUserMenu && 'transform rotate-180']"
                    icon="expand-more"
                    iconset="prodige-layout"
                  />
                </button>
              </div>
              <!--
                Profile dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div
                  v-show="displayUserMenu"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                >
                  <div
                    class="py-1 rounded-md bg-white shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <router-link
                      :to="{ name: 'Profile' }"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                      role="menuitem"
                      @click.native="toggleUserMenu()"
                    >
                      {{ $t('text.profile') }}
                    </router-link>
                    <a
                      :href="noticeLink"
                      target="_blank"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                      role="menuitem"
                    >
                      {{ $t('text.help') }}
                    </a>
                    <button
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                      role="menuitem"
                      @click="logout"
                    >
                      {{ $t('text.logout') }}
                    </button>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <!-- Display impersonated user -->
          <div
            v-if="isImpersonated"
            class="absolute top-16 right-0 py-2 px-3 shadow-md min-w-max-content w-1/3 rounded-bl-lg text-sm text-red bg-white z-20"
          >
            {{
              $t('text.impersonated', {
                user: `${user.first_name} ${user.last_name}`,
              })
            }}
            <button class="underline cursor-pointer" @click="logout">
              {{ $t('text.clickHere') }}
            </button>
            {{ $t('text.impersonatedExit') }}
          </div>
        </div>
      </div>

      <!-- Main content -->
      <section class="flex flex-1 relative z-0 focus:outline-none">
        <!-- Static sidebar for desktop -->
        <prodige-sidenav />

        <!-- Main content-->
        <!-- If the main spinner if loading, force overflow hidden -->
        <main
          class="flex-1 relative z-0 focus:outline-none px-4 py-2 overflow-x-hidden"
          tabindex="0"
        >
          <!-- Main application loader -->
          <prodige-loader
            class="main-loader left-64"
            :class="[!sideNavOpened && 'expend']"
            class-name="fixed inset-0 h-full min-h-screen"
          />
          <!-- Main view -->
          <router-view
            class="pt-2 pb-6 px-10 md:py-6"
            :class="{ 'mt-8 md:mt-6': isImpersonated }"
          />
        </main>
      </section>
    </div>

    <ab-iconset iconset="prodige-layout">
      <g id="expand-more">
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </g>
    </ab-iconset>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AbIcon, AbIconset, MultipleSelect } from '@ab/material-components'
import ProdigeSidenav from './prodige-sidenav.vue'
import ProdigeAppnav from './prodige-appnav.vue'
import AppLogo from '../../mixins/appLogo.js'

export default {
  name: 'ProdigeLayout',
  components: {
    AbIcon,
    AbIconset,
    MultipleSelect,
    ProdigeSidenav,
    ProdigeAppnav,
  },
  mixins: [AppLogo],
  data() {
    return {
      displayUserMenu: false,
      groupProps: {
        'group-select': false,
        'group-label': 'label',
        'group-values': 'options',
      },
      selectedAdherent: null,
      resignerStatus: 2,
    }
  },
  computed: {
    ...mapState({
      spinner: (state) => state.loader.spinner.default,
      user: (state) => state.authentication.user,
      userAvatar() {
        if (!this.user.Avatar) return ''
        return `${process.env.VUE_APP_ROOT_API}/upload${this.user.Avatar.path}/${this.user.Avatar.unique_name}`
      },
      noticeLink() {
        return `${process.env.VUE_APP_ROOT_API}/upload/notice/notice_utilisation_eValae.pdf`
      },
      sideNavOpened: (state) => state.ui.sideNavOpened,
      adherent: (state) =>
        state.adherent ? state.adherent.selectedAdherent : {},
    }),
    Logo() {
      return this.sideNavOpened ? this.appLogo.WhiteLogo : this.appLogo.MiniLogo
    },
    displayGroup() {
      // Display groups only if user is associated to 2 groups or more, display list of adherents else
      return (
        this.user &&
        this.user.GroupAdherent &&
        this.user.GroupAdherent.length >= 1
      )
    },
    // userAdherends computed used as options for multiselect
    userAdherents() {
      if (this.user && this.user.id) {
        // Filter and keep active adherents only
        // FIXME: Find a better way to check status than by ID
        const activeAdherents = this.user.Adherent.filter(
          (adherent) =>
            adherent.adherent_proclubStatus_id !== this.resignerStatus
        )

        if (this.displayGroup) {
          // Sort by group with associated adherents
          const adherentsArray = []
          const associatedAdherents = []
          this.user.GroupAdherent.map((group) => {
            // Push group adherent
            associatedAdherents.push({ ...group, isGroup: true })
            // Push adherents belonging to this group
            associatedAdherents.push(
              activeAdherents.filter(
                (adherent) => adherent.groupAdherent_id === group.id
              )
            )
            return group
          })

          adherentsArray.push({
            label: this.$t('label.associatedAdherents'),
            options: associatedAdherents.flat(),
          })

          // Find all adherents that were not associated with group yet
          const includedAdherentsId = adherentsArray
            .map((adh) => adh.options)
            .flat()
          const otherAdherents = this.user.Adherent.filter(
            (adherent) => !includedAdherentsId.includes(adherent)
          )

          // Push these adherents in array as 'non attached adherents'
          if (otherAdherents && otherAdherents.length) {
            adherentsArray.push({
              label: this.$t('label.unassociated'),
              options: otherAdherents,
            })
          }

          return adherentsArray
        }
        // If there is no group, we simple return all available adherents
        return activeAdherents
      }
      return []
    },
    isImpersonated() {
      return !!this.user.isImpersonated
    },
  },
  async mounted() {
    document.addEventListener('click', this.closeUserMenu)
    if (this.adherent && this.adherent.id) {
      this.selectedAdherent = this.deepCopy(this.adherent)
    } else {
      // Select default adherent
      if (
        this.isCurrentAppFrontOffice &&
        this.userAdherents.length &&
        this.userAdherents[0].options &&
        this.userAdherents[0].options.length
      ) {
        this.selectCurrentAdherent(this.userAdherents[0].options[0])
        // eslint-disable-next-line prefer-destructuring
        this.selectedAdherent = this.userAdherents[0].options[0]
      } else if (
        this.isCurrentAppFrontOffice &&
        this.userAdherents.length &&
        !this.userAdherents[0].options
      ) {
        this.selectCurrentAdherent(this.userAdherents[0])
        // eslint-disable-next-line prefer-destructuring
        this.selectedAdherent = this.userAdherents[0]
      }

      // If the user has no active adherent, he cannot access the application
      // So we need to log him out
      if (
        this.isCurrentAppFrontOffice &&
        this.isCurrentUserAdherent &&
        this.userAdherents.length === 0
      ) {
        this.$store.dispatch('addMessage', {
          key: 'error.noActiveAdherent',
          params: { display: 'error.noActiveAdherent', clean: true },
        })
        this.logout()
      } else if (
        this.isCurrentAppFrontOffice &&
        this.isCurrentUserAdherent &&
        this.userAdherents.length === 1
      ) {
        // If the use has access to only one adherent,
        // We directly define this adherent as the selected adherent
        if (
          this.userAdherents[0].options &&
          this.userAdherents[0].options.length
        ) {
          this.selectCurrentAdherent(this.userAdherents[0].options[0])
        } else {
          this.selectCurrentAdherent(this.userAdherents[0])
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeUserMenu)
  },
  methods: {
    closeUserMenu(e) {
      if (!this.$refs.userMenu.contains(e.target)) {
        this.displayUserMenu = false
      }
    },
    toggleUserMenu() {
      this.displayUserMenu = !this.displayUserMenu
    },
    logout() {
      this.$store.dispatch('logout')
      if (process.env.NODE_ENV === 'production') {
        window.location.href = '/login'
        return window.location.href
      }
      return this.$router.push({ name: 'login' })
    },
    handleAvatarError() {
      const img = new Image()
      img.src = `${process.env.VUE_APP_ROOT_API}/upload/user/default-user.png`
      img.onload = () => {
        this.$refs.avatar.src = `${process.env.VUE_APP_ROOT_API}/upload/user/default-user.png`
      }
      img.onerror = () => {
        this.$refs.avatar.src =
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      }
    },
    selectCurrentAdherent(adherent) {
      this.$store.commit('setSelectedAdherent', adherent)
    },
  },
}
</script>

<style scoped>
.hide {
  @apply h-full overflow-hidden !important;
}
.expend {
  @apply left-16 !important;
}
.shrink {
  width: 4rem !important;
}
</style>
<style>
#app .adherent-selector .multiselect__option {
  padding: 0;
}
#app
  .adherent-selector
  .multiselect__option.multiselect__option--selected
  .group {
  @apply text-turquoise !important;
}
</style>

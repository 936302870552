<template>
  <section>
    <prodige-card>
      <!-- CATALOG SECTION -->
      <div class="flex w-full">
        <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
          $t('profile.label.catalog')
        }}</span>
        <div class="w-8/12 flex flex-wrap justify-between">
          <div class="w-47 relative">
            <div v-if="supplierData.Catalog" class="flex flex-col">
              <tr
                v-for="(catalog, idx) in supplierData.Catalog"
                :key="`catalog-${idx}`"
              >
                <div
                  class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2 w-full"
                >
                  <p>{{ catalog.label }}</p>
                </div>
              </tr>
            </div>
            <div v-else>
              <div
                class="text-xs text-gray-900 font-semi-bold bg-gray-200 border rounded-md p-2 mt-2"
              >
                <p>{{ $t('profile.noCatalog') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FAMILY SECTION -->
      <div class="flex w-full mt-12">
        <span class="label w-4/12 block text-md text-dark-grey uppercase">{{
          $t('profile.label.family')
        }}</span>
        <div class="w-8/12 flex flex-wrap justify-between">
          <div class="w-47 relative">
            <div
              v-if="supplierData.Family && supplierData.Family.length > 0"
              class="flex flex-col"
            >
              <tr
                v-for="(family, idx) in supplierData.Family"
                :key="`family-${idx}`"
              >
                <div
                  class="text-xs text-gray-900 bg-gray-200 rounded-md p-2 mt-2"
                >
                  <p>{{ family.label }}</p>
                </div>
              </tr>
            </div>
            <div v-else>
              <div
                class="text-xs text-gray-900 font-semi-bold bg-gray-200 border rounded-md p-2 mt-2"
              >
                <p>{{ $t('profile.noFamily') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </prodige-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { AbIconset } from '@ab/material-components'
import { required } from 'vuelidate/lib/validators'
import ProdigeCard from '../../components/cards/prodige-card.vue'

export default {
  name: 'ProfileCatalog',
  components: {
    ProdigeCard,
  },
  props: {
    supplier: {
      type: Object,
    },
  },
  data() {
    return {
      supplierData: {},
    }
  },
  validations: {
    supplierData: {},
  },
  computed: {},
  watch: {
    supplier() {
      this.supplierData = { ...this.supplier }
    },
  },
  methods: {},
}
</script>

import NProgress from 'nprogress'

import Login from '../views/Login.vue'
import RequestPasswordReset from '../views/Profile/RequestPasswordReset.vue'
import ResetPassword from '../views/Profile/ResetPassword.vue'
import Profile from '../views/Profile.vue'
import AppOutlet from '../views/AppOutlet.vue'
import SecurityOutlet from '../views/Profile/SecurityOutlet.vue'
import PasswordUpdateSuccess from '../views/Profile/PasswordUpdateSuccess.vue'
import PasswordUpdate from '../views/Profile/PasswordUpdate.vue'

import { checkAppAccess } from '../assets/js/helpers.js'

export { checkAppAccess }

export const routes = {
  outlet: {
    path: '/',
    component: AppOutlet,
  },
  login: {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'title.login',
    },
  },
  security: {
    path: '/security',
    name: 'security',
    component: SecurityOutlet,
    children: [
      {
        path: 'request-password-reset',
        name: 'RequestPasswordReset',
        component: RequestPasswordReset,
        meta: {
          title: 'title.requestPasswordReset',
        },
      },
      {
        path: 'setupAccount',
        name: 'SetupAccount',
        component: ResetPassword,
        meta: {
          title: 'title.setupAccount',
          isCreate: true,
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          title: 'title.resetPassword',
        },
      },
      {
        path: 'setupAccountSuccess',
        name: 'PasswordCreateSuccess',
        component: PasswordUpdateSuccess,
        meta: {
          title: 'title.passwordUpdateSuccess',
          isCreate: true,
        },
      },
      {
        path: 'password-success',
        name: 'PasswordUpdateSuccess',
        component: PasswordUpdateSuccess,
        meta: {
          title: 'title.passwordUpdateSuccess',
        },
      },
    ],
  },
  profile: {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'title.myAccount',
      icon: 'access',
    },
  },
  passwordUpdate: {
    path: '/passwordupdate',
    name: 'PasswordUpdate',
    component: PasswordUpdate,
    meta: {
      title: 'title.updatePassword',
      icon: 'access',
    },
  },
  userManagement: {
    name: 'UserManagementTab',
    path: '/usermanagement',
    redirect: '/usermanagement/',
    component: () =>
      import(
        /* webpackChunkName: "UserManagementOutlet" */ '../views/UserManagement/UserManagementOutlet.vue'
      ),
    children: [
      {
        name: 'UserList',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "UserManagementList" */ '../views/UserManagement/UserManagementList.vue'
          ),
        meta: {
          icon: 'access',
          title: 'title.usermanagement',
        },
      },
      {
        name: 'UserCreate',
        path: 'create/',
        component: () =>
          import(
            /* webpackChunkName: "UserManagementEdit" */ '../views/UserManagement/UserManagementEdit.vue'
          ),
        meta: {
          icon: 'access',
          isCreate: true,
          title: 'title.usermanagement',
        },
      },
      {
        name: 'UserEdit',
        path: ':id/',
        component: () =>
          import(
            /* webpackChunkName: "UserManagementEdit" */ '../views/UserManagement/UserManagementEdit.vue'
          ),
        meta: {
          icon: 'access',
          title: 'title.usermanagement',
        },
      },
    ],
  },
}

export const publicRoutes = [
  'Login',
  'RequestPasswordReset',
  'ResetPassword',
  'PasswordUpdateSuccess',
  'SetupAccount',
  'PublicNotice',
  'PublicNoticeHome',
  'PublicNoticeView',
  'DocumentConsultation',
]

export function isLogged(to, from, next) {
  const token = window.localStorage.getItem('token')
  // If there is no token in session and auth is required,
  // we redirect to login page
  const authorized = publicRoutes
  if (!token)
    return authorized.includes(to.name) ? next() : next({ name: 'Login' })
  return true
}

export async function getMenu(store, route, name = 'back-office') {
  // On exclus toutes les routes qui ne disposent pas du menu, pour ne pas faire la requete depuis celles-ci
  const dontNeedMenu = publicRoutes
  if (!dontNeedMenu.includes(route.name) && !store.getters.getMenu.length) {
    await store.dispatch('getMenu', name)
  }
}

export function initNProgress() {
  NProgress.configure({
    easing: 'ease',
    speed: 1000,
    showSpinner: false,
  })

  return NProgress
}

export function scrollBehavior(to, from, savedPosition) {
  const container = document.querySelector('#main-container')
  if (savedPosition) {
    const { x, y } = savedPosition
    if (container) {
      container.scrollTo({
        top: y,
        left: x,
        behavior: 'auto',
      })
    }
    return savedPosition
  }
  if (container) {
    container.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }
  return { x: 0, y: 0 }
}

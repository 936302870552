import { createRequest, handleError } from './api.js'

const request = createRequest()

export function updateUser(user) {
  return request
    .put(`/users/update/${user.id}`, user)
    .then(({ data }) => data)
    .catch(handleError)
}

export function createUser(user) {
  return request
    .post('/users/create/', user)
    .then(({ data }) => data)
    .catch(handleError)
}

export function getUsers(offset, limit) {
  return request
    .get('/users/', {
      params: {
        offset,
        limit,
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getUserData(id) {
  return request
    .get(`/users/${id}`)
    .then(({ data }) => data)
    .catch(handleError)
}

export function getUserStatus() {
  return request
    .get('/users/status/')
    .then(({ data }) => data)
    .catch(handleError)
}

export function getProjectManagers() {
  return request
    .get('/users/projectmanager')
    .then(({ data }) => data)
    .catch(handleError)
}

export function getUserPerimeter() {
  return request
    .get('/users/perimeter')
    .then(({ data }) => data)
    .catch(handleError)
}

export function getValidatorUsers({ adherents, userId }) {
  return request
    .post('/users/validators', { adherents, userId })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getUserFamilies() {
  return request
    .get('/users/family')
    .then(({ data }) => data)
    .catch(handleError)
}

export function getUserMenu() {
  return request
    .get(`/users/userMenu`)
    .then(({ data }) => data)
    .catch(handleError)
}


export default {
  modelName: {
    Adherent: 'Adhérent',
    Article: 'Article',
    Catalog: 'Catalogue',
    Company: 'Service',
    Company_type: 'Type de service',
    Family: 'Famille',
    Litigation: 'Litige',
    Litigation_status: 'Statut de litige',
    Litigation_type: 'Type de litige',
    Order: 'Commande',
    Platform: 'Plateforme',
    Profile: 'Profil',
    Status: 'Statut',
    Supplier: 'Fournisseur',
    Supplier_not_in_catalog: 'Fournisseur hors catalogue',
    User: 'Utilisateur',
    Inventory_Lines: "Lines d'inventaire",
    Inventory: 'Inventaire',
    Product: 'Produit',
    Stocks_Transaction: 'Transaction',
    Subfamily: 'Sous Famille',
    Product_not_in_catalog: 'Produit hors catalogue',
    GroupAdherent: 'Groupe adhérent',
    Establishment_type: 'Type établissement',
    Adherent_proclubStatus: 'Statut proclub',
    Address: 'Adresse',
    StarRating: "Nombre d'étoiles",
    RFARate: 'Taux RFA',
    Manufacturer: 'Industriel',
    Brand: 'Marque',
    Caliber: 'Calibre',
    Label: 'Label',
    Origin: 'Origine',
    Tag: 'Tags internes',
    Service: 'Service',
    Tender: "Appel d'offre",
    TenderType: "Type appel d'offre",
    PriceUpdate: 'Mise à jour du prix',
    PriceUpdate_Log: 'Journal de mise à jour du prix',
    PriceUpdate_Response: 'Réponse à la mise à jour des prix',
    ResponseTender: "Réponse d'appel d'offre",
    Revenue: "Chiffre d'affaire",
    Statistic: 'Statistique',
    Product_not_in_catalog_Subfamily_Family: 'Famille Produit HC',
    Product_Subfamily_Family: 'Famille Produit',
    Product_not_in_catalog_Subfamily: 'Sous Famille Produit HC',
    Product_Subfamily: 'Sous Famille Produit',
    Inventory_Adherent: 'Adherent',
    Product_Stocks_Transaction_Lines_Stocks_Transaction: 'Transaction produit',
    Product_not_in_catalog_Stocks_Transaction_Lines_Stocks_Transaction:
      'Transaction produit HC',
    GroupAdherentsCatalogs_Catalog: 'Catalogue',
    GroupAdherentsCatalogs_RFARate: 'Taux RFA',
    Company_Company_type: "Type d'établissement",
    Contact_administrative_Company: 'Contact administratif',
    Manufacturer_Brand_Brand: 'Marque du fabricant',
    Product_Tag_Tag: 'Tag',
    Subfamily_Family: 'Famille',
    Adherent_Company: 'Service',
    Adherent_Company_Company_type: "Type d'établissement",
  },
  field: {
    id: 'ID',
    name: 'Nom',
    label: 'Libellé',
    title: 'Titre',
    active: 'Actif',
    email: 'Email',
    phone: 'Téléphone',
    fax_number: 'Fax',
    creation_date: 'Date de création',
    address: 'adresse',
    zip_code: 'code postal',
    city: 'ville',
    department: 'département',
    country: 'pays',
    office_phone: 'téléphone bureau',
    tva_intra_number: 'n° de TVA intracommunautaire',
    storage_management: 'gestion stock',
    coef_storage_management: 'coefficient de gestion',
    date: 'date',
    market_openning_date: 'début du marché',
    market_closing_date: 'fin du marché',
    consultation_openning_date: 'début de consultation',
    consultation_closing_date: 'fin de consultation',
    message: 'message',
    criteres: 'critères',
    mail_object: 'objet du mail',
    contact_name: 'nom du contact',
    contact_mail: 'mail du contact',
    contact_tel1: 'téléphone principal',
    contact_tel2: 'téléphone portable',
    mail_message: 'Message du mail',
    opening_date: 'date de début',
    return_date: 'date de retour',
    appliance_date: "date d'application",
    return_email: 'Email de retour',
    type: 'type',
    date_sended: "date d'envoi",
    status: 'statut',
    version: 'version',
    period_start: 'date de début',
    period_end: 'date de fin',
    response_date_limit: 'date limite de réponse',
    message_object: 'objet du message',
    message_text: 'texte du message',
    // Litigation
    sender_name: "Nom de l'émetteur",
    sender_phone: "Téléphone de l'émetteur",
    supplier_name: 'Nom du fournisseur',
    supplier_phone: 'Téléphone du fournisseur',
    client_number: 'N° de client',
    litigation_date: 'Date du litige',
    credit_note: "Demande d'avoir",
    amount: 'Montant demandé',
    description: 'Description',
    alert: 'Alerte',
    // Platform
    responsible: 'Responsable',
    bank: 'Banque',
    bank_account_owner: 'Titulaire du compte',
    iban: 'IBAN',
    bic: 'Code B.I.C',
    // Supplier
    ape_code: 'Code APE',
    tva_number: 'N° de TVA',
    website: 'Site web',
    not_in_catalog: 'Fournisseur hors catalogue',
    date_inactive: 'Date de fin',
    // Adherent
    siret: 'SIRET',
    intracommunity_VAT: 'TVA intercommunautaire',
    corporate_name: 'Raison sociale',
    is_access_module_allowed: 'Accès au module des stocks',
    is_amo: 'AMO',
    is_catalog_specific: 'Catalogue spécifique',
    room_number: 'Nombre de chambres',
    restaurant_number: 'Restaurant',
    restaurant_seats_number: 'Nombre de places restaurant',
    bar_number: 'Bar',
    bar_seats_number: 'Nombre de places bar',
    seminar_room_number: 'Salle séminaires',
    seminar_room_seats_number: 'Nombre salles de séminaire',
    pool_number: 'Nombre de piscine',
    daily_meal_number: 'Nombre de repas/jour',
    monthly_meal_number: 'Nombre de repas/mois',
    bed_number: 'Nombre de lits',
    finess: 'Finess',
    annual_food_budget: 'Budget alimentaire annuel',
    membership_amount: 'Montant adhésion',
    fees: 'Montant redevance',
    subscription_date: "Date d'adhésion",
    resignation_date: 'Date de fin',
    amo_date: 'Date de passage en AMO',
    membership_condition: 'Information complémentaire',
    region: 'Région',
    adherent_code: 'Code adhérent',
    // Product - Article
    caliber: 'Calibre',
    quotation: 'Cotation',
    brand: 'Marque',
    origin: 'Origine',
    manufacturer: 'Industriel',
    rate: 'Taux',
    preservation: 'Conservation',
    negotiation: 'Négociation',
    coef_negotiation: 'Coefficient de négociation',
    order_management: 'Unité de commande',
    coef_order_management: 'Coefficient unité de commande',
    date_active: 'Date active',
    price_ht: 'Prix HT',
    coef: 'Coefficient',
    ref_supplier: 'Référence fournisseur',
    billing: 'Facturation',
    coef_billing: 'Coefficient de facturation',
    // Catalog
    deactivation_date: 'Date de désactivation',
    // Family
    code: 'Code',
    order: 'Ordre',
    // Order
    create_order_date: 'Date création de commande',
    ask_delivery_date: 'Date demande de livraison',
    real_delivery_date: 'Date livraison',
    supplier_delivery_date: 'Date livraison fournisseur',
    confirm_order_date: 'Date confirmation commande',
    sending_order_date: 'Date envoi commande',
    ack_order_date: 'Date réception commande',
    fresh_temperature: 'Température frais',
    freeze_temperature: 'Température surgelé',
    ref_client: 'Référence client',
    receiver_name: 'Nom réceptionnaire',
    delivery_form: 'N° de pièce',
    comment: 'Commentaire',
    order_total_ht: 'Total commande HT',
    order_total_ttc: 'Total commande TTC',
    delivery_total_ht: 'Total livraison HT',
    delivery_total_ttc: 'Total livraison TTC',
    lines_nb: 'Nombre de lignes',
    expired: 'Expiré',
    overdue: 'Retard',
    // User
    first_name: 'Prénom',
    last_name: 'Nom',
    phone_number: 'N° de téléphone',
    last_login: 'Dernière connexion',
    tax_display: 'Affichage des taxes',
    create_product_hc: 'Création produit HC',
    create_supplier_hc: 'Création fournisseur HC',
    purchasing_habits_week: "Habitude d'achat",
    start_date_replacement: 'Date début de congés',
    end_date_replacement: 'Date fin de congés',
    validator_one: 'Validateur 1',
    validator_two: 'Validateur 2',
    replacement_validator: 'Validateur en cas de congés',

    // stock
    quantity_theorical: 'Quantité théorique',
    price_unit_ht_theorical: 'Prix unitaire HT théorique',
    quantity_real: 'Quantité réelle',
    price_unit_ht_real: 'Prix unitaire HT reel',
    inventory_date_created: 'Date création',
    inventory_date_updated: 'Date mise à jour',
    inventory_date_validation: 'Date de validation',
    inventory_date_deleted: 'Date de suppression',
  },
}

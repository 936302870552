import { getHistoryByEntityId, getHistory } from '../api/history.js'

export default {
  state: {
    history: {},
  },
  mutations: {
    getHistoryByEntityId(state, history) {
      state.history = history
    },
    getHistory(state, history) {
      state.history = history
    },
  },
  actions: {
    async getHistoryByEntityId({ commit }, params) {
      const data = await getHistoryByEntityId(params)
      if (data) {
        commit('getHistoryByEntityId', data)
      }
      return data
    },
    async getHistory({ commit }, id) {
      const data = await getHistory(id)
      if (data) {
        commit('getHistory', data)
      }
      return data
    },
  },
}

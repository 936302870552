export default {
  text: {
    filename: 'Nom de votre export',
    FieldsNotIncluded: 'Champs inclus',
    fieldsIncluded: 'Champs non inclus',
    columns: '',
    title: '',
  },
  title: {
    modal: 'Exporter le tableau au format excel',
    btn: 'Exporter',
  },
  error: {
    formVerificationError: 'Un ou plusieurs champs sont incorrects',
  },
}

/* eslint-disable no-restricted-syntax */
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Import  translations
import { translations as sharedTranslation } from '@apps/shared-components'

Vue.use(VueI18n)
window.i18n = new VueI18n({
  locale: 'fr',
  messages: sharedTranslation,
})

export default window.i18n

import Vue from 'vue'
import VueRouter from 'vue-router'
import { routers, apis } from '@apps/shared-components'
import { isLogged, publicRoutes } from './guard.js'
import store from '../store/index.js'
import i18n from '../i18n/plugin.js'
const { login, security } = routers.routes
const { initNProgress, scrollBehavior, checkAppAccess } = routers.helpers
const { registerFlashBag, registerSessionRefresh, registerAccessErrorEvent } = apis.helpers

const base = i18n.t('apps.text.unilogin')
document.title = base

Vue.use(VueRouter)

const NProgress = initNProgress()

const routes = [login, security]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

const appNames = {
  BACK_OFFICE: 'back-office',
  FRONT_OFFICE: 'front-office',
  TENDERS: 'tenders',
}

router.beforeEach(async (to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    NProgress.start()
    store.commit('showSpinner')
  }

  const base = i18n.t('apps.text.unilogin')
  document.title = `${base} ${to.meta.title ? `- ${i18n.t(to.meta.title)}` : ''}`

  if (window.location.pathname.split('/').some((string) => string === 'logout')) {
    store.dispatch('logout')
    if (to.name !== 'Login') {
      return next('/login')
    }
    return next()
  }

  // If the user is logged successfully
  if (isLogged(to, from, next)) {
    const data = await store.dispatch('getMe')
    if (data === false) {
      store.dispatch('logout')
      return next('/login')
    }
    // Get all available apps
    await store.dispatch('getApps')
    // We check the user access rights for all available apps
    const accesses = checkAppAccess(store.state.app.apps, store.getters.user.Profile)

    const backOfficeAccess = accesses.find((app) => app.name === appNames.BACK_OFFICE && app.access)
    const frontOfficeAccess = accesses.find(
      (app) => app.name === appNames.FRONT_OFFICE && app.access
    )
    const tendersAccess = accesses.find((app) => app.name === appNames.TENDERS && app.access)

    const _isDev = process.env.NODE_ENV !== 'production'

    // If the user has the right to access back office,
    // We redirect the user to the back office app
    if (backOfficeAccess) {
      window.location.href = _isDev ? 'http://localhost:8081/back-office/' : backOfficeAccess.url
      return window.location.href
    }
    // If the user has the right to access tenders,
    // We redirect the user to the tenders app
    if (tendersAccess) {
      window.location.href = _isDev ? 'http://localhost:8083/tenders/' : tendersAccess.url
      return window.location.href
    }

    // If the user has the right to access front office,
    // We redirect the user to the front office app
    if (frontOfficeAccess) {
      window.location.href = _isDev ? 'http://localhost:8084/front-office/' : frontOfficeAccess.url
      return window.location.href
    }

    // If the user has no access
    // We log out the user and redirect him to the login page
    store.dispatch('logout')
    return next('/')
  }
  if (process.env.NODE_ENV !== 'production') {
    if (window.location.pathname.split('/').some((string) => string === 'logout')) {
      store.dispatch('logout')
      return next('/login')
    }
  }
  return next()
})

router.afterEach((to, from, next) => {
  NProgress.done()
})

// Register the global flash bag listener
// Automaticaly display error for HTTP calls
registerFlashBag(store)

// If the loader if taking to much time, we fall back to the previous page
// document.addEventListener('loading-failed', ({ detail }) => {
//   console.error('[BackOffice] The loader takes longer than expected !');
//   router.go(-1);
//   store.commit('hideSpinner', detail);
//   store.dispatch('addMessage', { key: 'error.anErrorOccured', params: { display: 'error.anErrorOccured', error: true } });
// });

// Refresh token
registerSessionRefresh(store, router)

// Pass the router to the Event
registerAccessErrorEvent(router)

export default router

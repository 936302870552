import { createRequest, handleError } from './api.js'

const request = createRequest()

export function login({ email, password }) {
  return request
    .post('/auth/login', {
      email,
      password,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function resetPassword({ email }) {
  return request
    .post('/auth/security/request-password-reset', {
      email,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function verifyResetPasswordToken(token) {
  return request
    .get('/auth/security/verify-token', {
      params: {
        token,
      },
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function setResetPassword({ password, confirmPassword, token }) {
  return request
    .post('/auth/security/reset-password', {
      password,
      confirmPassword,
      token,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function getMe() {
  const token = localStorage.getItem('token')
  if (!token) return false
  return request
    .get('/users/me')
    .then(({ data }) => data)
    .catch(handleError)
}

export function updateMe(user) {
  return request
    .put('/users/updateMe/', {
      user,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function updatePassword(user) {
  return request
    .put('/users/update-password/', {
      user,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function impersonateUser(userToImpersonate) {
  return request
    .post('/auth/impersonate', {
      id: userToImpersonate,
    })
    .then(({ data }) => data)
    .catch(handleError)
}

export function haveUserAccessTo(route) {
  return request.get(`/auth/have-access-to/${route}`).then(({ data }) => data)
}

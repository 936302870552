import { createSnackbar, destroyAllSnackbars } from '@snackbar/core'
import '@snackbar/core/dist/snackbar.css'

export default {
  state: {
    messagesBag: {},
    cooldown: null,
  },
  getters: {
    messagesBag: (state) => state.messagesBag,
  },
  mutations: {
    addMessage(state, { key, params }) {
      if (!state.messagesBag[key]) {
        state.messagesBag[key] = params
      }
    },
    popMessage(state, key) {
      delete state.messagesBag[key]
    },
    clearMessages(state) {
      state.messagesBag = {}
    },
    setCooldown(state, cooldown) {
      state.cooldown = cooldown
    },
    setOpened(state, key) {
      state.messagesBag[key].opened = true
    },
  },
  actions: {
    addMessage({ state, commit, dispatch }, { key, params }) {
      clearTimeout(state.cooldown)
      commit('addMessage', { key, params })
      const cooldown = setTimeout(
        () => {
          dispatch('displayToast', params.clean || false)
        },
        params.delayed ? params.delayed || 100 : 0
      )
      commit('setCooldown', cooldown)
    },
    updateAvailable() {
      createSnackbar(window.i18n.t('update.updateAvailable'), {
        actions: [
          {
            text: window.i18n.t('update.refresh'),
            style: {
              height: '100%',
              fontSize: '0.9rem',
              border: 'solid 1px #1C1C1C',
            },
            callback() {
              window.location.reload()
            },
          },
        ],
        theme: {
          backgroundColor: '#464E7E',
          actionColor: '#ffffff',
        },
        position: 'left',
        timeout: 0,
      })
    },
    displayToast({ state, commit }, clean = false) {
      if (clean) destroyAllSnackbars()
      Object.keys(state.messagesBag)
        .filter((key) => state.messagesBag[key].opened !== true)
        .forEach((key) => {
          const timer = 5000
          const timeout = setTimeout(() => {
            commit('popMessage', key)
          }, timer)
          const message = state.messagesBag[key]
          commit('setOpened', key)
          createSnackbar(window.i18n.t(message.display), {
            position: 'right',
            timeout: timer,
            theme: {
              backgroundColor: message.error ? '#E00202' : '#464E7E',
              actionColor: '#ffffff',
            },
            actions: [
              {
                text: window.i18n.t('text.close'),
                callback(button, snackbar) {
                  commit('popMessage', key)
                  clearTimeout(timeout)
                  snackbar.destroy()
                },
              },
            ],
          })
        })
    },
  },
}
